
@import '../tools';

/*
  Nav pagination version 02
---------------------------------------
*/

.nav-pagination02 {
    overflow: hidden;
    padding: em(56) em(28) em(28);
    margin-top: em(40);
    margin-right: em(-15);
    margin-bottom: em(48);
    margin-left: em(-15);
    background-color: $mbiebuilding_white_1;
}

.nav-pagination02 ul {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nav-pagination02 ul li {
    display: none;
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nav-pagination02 ul li.previous {
    display: block;
    padding-right: 0;
}

.nav-pagination02 ul li.next {
    display: block;
    padding-left: 0;
    text-align: right;
}

.nav-pagination02 ul li a {
    text-decoration: none;
}

.nav-pagination02 ul li a:hover,
.nav-pagination02 ul li a:focus {
    text-decoration: none;
}

.nav-pagination02 ul li a .description {
    @extend .gustan-light;

    display: none;
    padding-bottom: em(30);
    font-size: em(16);
    color: $mbiebuilding_gray_5;
    text-decoration: none;
}

.nav-pagination02 ul li a:hover .description,
.nav-pagination02 ul li a:focus .description {
    text-decoration: none;
}

.nav-pagination02 ul li a .faux-link {
    @extend .gustan-bold;

    display: block;
    clear: left;
    width: 100%;
    padding: em(15, 20) 0 0;
    font-size: em(20);
    color: $mbiebuilding_gray_5;
    text-decoration: none;
}

.nav-pagination02 ul li a:hover .faux-link,
.nav-pagination02 ul li a:focus .faux-link {
    color: $mbiebuilding_blue_1;
    text-decoration: none;
}

.nav-pagination02 ul li a .feaux-link .icon {
    font-size: em(17);
    color: $mbiebuilding_blue_7;
}

.nav-pagination02 ul li a:hover .icon,
.nav-pagination02 ul li a:focus .icon {
    color: $mbiebuilding_blue_1;
}

.nav-pagination02 ul li.previous a .faux-link {
    border-top: 2px solid $mbiebuilding_blue_2;
    text-align: left;
}

.nav-pagination02 ul li.previous a .faux-link .icon {
    margin-right: em(7, 17);
}

.nav-pagination02 ul li.next a .faux-link {
    border-top: 2px solid $mbiebuilding_blue_3;
    text-align: right;
}

.nav-pagination02 ul li.next a .faux-link .icon {
    margin-left: em(7, 17);
}

@media print, screen and (min-width: $mobile_min) {
    .nav-pagination02 {
        margin-right: em(-30);
        margin-left: em(-30);
    }
}

@media only screen and (min-width: $tablet_min) {
    .nav-pagination02 {
        margin-top: em(80);
        margin-right: 0;
        margin-left: 0;
    }

    .nav-pagination02 ul li.previous {
        padding-right: 5.5%;
    }

    .nav-pagination02 ul li.next {
        padding-left: 5.5%;
    }

    .nav-pagination02 ul li a .description {
        display: block;
        min-height: em(132);
    }
}

@media only screen and (min-width: 900px) {
    .nav-pagination02 ul li a .description {
        min-height: em(110);
    }
}

@media only screen and (min-width: 1000px) {
    .nav-pagination02 ul li a .description {
        min-height: em(88);
    }
}

@media only screen and (min-width: $desktop_min) {
    .nav-pagination02 ul li a .description {
        min-height: em(66);
    }
}
