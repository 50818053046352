.link-block {
    @extend .gustan-light;

    display: inline-block;
    padding: rem(11) rem(15);
    border: rem(1) solid $mbiebuilding_blue_2;
    background-color: $mbiebuilding_white_1;
    font-size: rem(18);
    color: $mbiebuilding_blue_2;
    transition: background-color .1s ease-in-out, color .1s ease-in-out, border-color .1s ease-in-out;

    &:hover,
    &:focus {
        border-color: $mbiebuilding_blue_1;
        background-color: $mbiebuilding_blue_1;
        color: $mbiebuilding_white_1;
        text-decoration: none;
    }

    @media print {
        color: $mbiebuilding_blue_1;
    }
}
