.tag-list {
    @include cluster-l(0);

    ul {
        margin: 0;
    }

    li {
        margin-bottom: 0;
        list-style: none;

        &::before {
            display: none;
        }
    }

    .tag {
        margin-right: rem(10);
    }

    @media print {
        padding-top: rem(4);
    }
}
