
@import '../tools';


/*
  Code button in header for specific pages
---------------------------------------
*/

.h1-wrap.with-page-options .code-button,
[class*='building-code-'] .aside-left .code-button {
    display: none;
}

@media only screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .building-code-section .h1-wrap.with-page-options .code-button,
    .building-code-clause .h1-wrap.with-page-options .code-button {
        position: absolute;
        top: 7px;
        left: 0;
        display: block;
    }

    .building-code-section .h1-wrap,
    .building-code-clause .h1-wrap {
        padding: em(11) em(125) 0 em(85);
    }

    .building-code-section .h1-wrap h1,
    .building-code-clause .h1-wrap h1 {
        margin: em(8, 42) 0 em(21, 42);
    }
}

@media only screen and (min-width: $tablet_min) {
    .all-asvm .h1-wrap.with-page-options .code-button {
        position: absolute;
        top: 34px;
        left: 0;
        display: block;
    }

    .all-asvm .h1-wrap.with-page-options {
        padding-bottom: rem(22);
    }

    .all-asvm .h1-wrap {
        padding: em(7) 0 0 em(95);
        margin: 0;
    }

    .all-asvm .h1-wrap h1 {
        padding: 0;
        margin: em(8, 36) 0 em(21, 36);
    }

    .building-code-section .h1-wrap h1,
    .building-code-clause .h1-wrap h1 {
        margin-bottom: em(24, 52);
    }

    .building-code-section .aside-left,
    .building-code-clause .aside-left {
        position: relative;
    }

    .building-code-section .aside-left .code-button,
    .building-code-clause .aside-left .code-button {
        position: absolute;
        top: 4px;
        left: 24px;
        display: block;
    }

    /* use px */

    .building-code-section .nav-secondary,
    .building-code-section .nav-tertiary,
    .building-code-clause .nav-secondary,
    .building-code-clause .nav-tertiary {
        margin-top: em(88);
    }
}


/*
  BUILDING-CODE-X - border-top aside-left (same border as .h1-wrap:before)
---------------------------------------
*/

[class*='building-code-'] .nav-secondary,
[class*='building-code-'] .nav-tertiary {
    position: relative;
}

@media only screen and (min-width: $tablet_min) {
    [class*='building-code-'] .nav-secondary::before,
    [class*='building-code-'] .aside-left .nav-tertiary::before {
        @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        content: '';
        height: em(4);
    }

    .building-code-a .nav-secondary::before,
    .building-code-a .aside-left .nav-tertiary::before {
        background: $building_code_a;
    }

    .building-code-b .nav-secondary::before,
    .building-code-b .aside-left .nav-tertiary::before {
        background: $building_code_b;
    }

    .building-code-c .nav-secondary::before,
    .building-code-c .aside-left .nav-tertiary::before {
        background: $building_code_c;
    }

    .building-code-d .nav-secondary::before,
    .building-code-d .aside-left .nav-tertiary::before {
        background: $building_code_d;
    }

    .building-code-e .nav-secondary::before,
    .building-code-e .aside-left .nav-tertiary::before {
        background: $building_code_e;
    }

    .building-code-f .nav-secondary::before,
    .building-code-f .aside-left .nav-tertiary::before {
        background: $building_code_f;
    }

    .building-code-g .nav-secondary::before,
    .building-code-g .aside-left .nav-tertiary::before {
        background: $building_code_g;
    }

    .building-code-h .nav-secondary::before,
    .building-code-h .aside-left .nav-tertiary::before {
        background: $building_code_h;
    }
}

/*
  BUILDING-CODE-X - tweaks to intro-text
---------------------------------------
*/

[class*='building-code-'] .intro-text {
    font-size: em(18);
    line-height: lh(24, 18);
}

// Reset for guidance-reading pages

[class*='building-code-'].guidance-reading .intro-text {
    margin: em(27, 18) 0 em(40, 18);
    font-size: em(18);
    line-height: lh(24, 18);

    p {
        margin: 0;
        font-size: inherit;
        line-height: inherit;
    }
}

.page-maincontent [class*='building-code-'] .intro-text p {
    margin-bottom: em(20, 20);
}

@media print, screen and (min-width: $mobile_min) {
    [class*='building-code-'] .intro-text {
        font-size: em(20);
        line-height: lh(27, 20);
    }

    // Reset for guidance-reading pages

    [class*='building-code-'].guidance-reading .intro-text {
        margin: em(27, 18) 0 em(40, 18);
        font-size: em(18);
        line-height: lh(24, 18);

        p {
            margin: 0;
            font-size: inherit;
            line-height: inherit;
        }
    }
}

// Reset for guidance-reading pages
@media only screen and (min-width: $tablet_min) {
    [class*='building-code-'].guidance-reading .intro-text {
        margin: em(34, 25) 0 em(29, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }
}

/*
  BUILDING-CODE-X - H2 variation (with border-bottom and slightly differet white spacing)
---------------------------------------
*/

[class*='building-code-'] h2.border-bottom {
    padding-bottom: em(18, 36);
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin-bottom: em(25, 36);
}

@media print, screen and (min-width: $mobile_min) {
    [class*='building-code-'] h2.border-bottom {
        padding-bottom: em(21, 30);
    }
}

/*
---------------------------------------
  BUILDING-CODE-CLAUSE (page specific)
---------------------------------------
*/

/*
  BUILDING-CODE-SECTION - In this section
---------------------------------------
*/

.building-code-section .in-this-section {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.building-code-section .in-this-section li {
    padding-bottom: em(9);
    margin-top: em(82);
}

.building-code-section .in-this-section a {
    text-decoration: none;
}

.building-code-section .in-this-section a:hover,
.building-code-section .in-this-section a:focus {
    text-decoration: none;
}

.building-code-section .in-this-section .code-button {
    display: block;
    float: left;
    margin-top: em(-49);
}

.building-code-section .code-button .code-icon {
    font-size: em(44);
}

.building-code-section .code-button.short .code-icon {
    width: em(81, 37);
    height: em(88, 37);
    font-size: em(37);
    line-height: em(76, 37);
}

.building-code-section .in-this-section a:hover .code-button .code-icon,
.building-code-section .in-this-section a:focus .code-button .code-icon {
    background-color: $mbiebuilding_blue_1;
    color: $mbiebuilding_blue_1;
}

.building-code-section .in-this-section .title {
    @extend .gustan-bold;

    position: relative;
    padding: 0 0 em(11, 20) em(17, 20);
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin: em(20, 20) 0 em(17, 20) em(96, 20);
    font-size: em(20);
    line-height: lh(26, 20);
    color: $mbiebuilding_blue_1;
    text-decoration: none;
}

.building-code-section .in-this-section .title .icon {
    position: absolute;
    top: em(-4, 25);
    left: 0;
    font-size: em(25, 20);
}

.building-code-section .in-this-section a:hover .title,
.building-code-section .in-this-section a:focus .title {
    text-decoration: underline;
}

.building-code-section .in-this-section a .description {
    clear: left;
}

.building-code-section .in-this-section a .description p {
    font-size: em(15);
    line-height: lh(21, 15);
    color: $mbiebuilding_body;
}

.building-code-section .in-this-section a:hover .description p,
.building-code-section .in-this-section a:focus .description p {
    color: $mbiebuilding_body;
    text-decoration: none;
}

@media print, screen and (min-width: $mobile_min) {
    .building-code-section .in-this-section li {
        position: relative;
        padding-left: em(96);
        margin-top: 0;
    }

    .building-code-section .in-this-section .code-button {
        position: absolute;
        top: em(4);
        left: 0;
        margin-top: 0;
    }

    .building-code-section .in-this-section .title {
        padding-left: em(25, 20);
        margin: em(20, 20) 0 em(17, 20) 0;
    }

    .building-code-section .in-this-section a .description {
        clear: none;
    }

    .building-code-section .h1-wrap .code-button .code-icon {
        font-size: em(37);
    }

    .building-code-section .code-button.short .code-icon {
        width: em(81, 37);
        height: em(88, 37);
        font-size: em(37);
        line-height: em(76, 37);
    }

    .building-code-clause .code-button .code-icon {
        font-size: em(37);
    }

    .building-code-clause .code-button.short .code-icon {
        width: em(67, 30);
        height: em(74, 30);
        font-size: em(30);
        line-height: em(69, 30);
    }
}

@media only screen and (min-width: $tablet_min) {
    .building-code-section .in-this-section li {
        padding-left: em(99);
        margin-bottom: em(36);
    }

    .building-code-section .in-this-section .title {
        padding: 0 0 em(9, 20) em(23, 20);
        margin: em(20, 20) 0 em(12, 20);
    }

    .building-code-section .code-button .code-icon,
    .building-code-clause .code-button .code-icon {
        font-size: em(44);
    }

    .building-code-section .code-button.short .code-icon,
    .building-code-clause .code-button.short .code-icon {
        width: em(80, 32);
        height: em(87, 32);
        font-size: em(32);
        line-height: em(75, 32);
    }
}

/*
---------------------------------------
  BUILDING-CODE-CLAUSE (page specific)
---------------------------------------
*/


/*
  BUILDING-CODE-CLAUSE - aside-left
---------------------------------------
*/

.building-code-clause .aside-left {
    display: none;
}

.building-code-clause .tablet-col ol,
.building-code-clause .tablet-col ul {
    margin-left: 1em;
}

@media only screen and (min-width: $tablet_min) {
    .building-code-clause .aside-left {
        display: block;
        max-width: em(295);
    }
}


@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .building-code-clause .tablet-col {
        float: left;
        width: 50%;
        margin-bottom: em(20);
    }

    .building-code-clause .intro-text {
        margin-top: em(50, 20);
    }
}


/*
  BUILDING-CODE-CLAUSE - nav-tertiary
---------------------------------------
*/

.building-code-clause .nav-tertiary {
    display: block;
}

.building-code-clause .aside-left .nav-tertiary {
    display: none;
}

.building-code-clause .nav-tertiary .nav-tertiary-heading {
    padding-bottom: em(12);
    margin-bottom: em(8);
}

.building-code-clause .nav-tertiary li {
    float: none;
    width: auto;
}

.building-code-clause .nav-title-wrapper {
    @extend .gustan-bold;

    padding: em(22, 20) 0 em(11, 20) 0;
    font-size: em(20);
    line-height: lh(27, 20);
    color: $mbiebuilding_gray_5;
}

@media only screen and (min-width: $tablet_min) {
    .building-code-clause .nav-tertiary {
        display: none;
    }

    .building-code-clause .aside-left .nav-tertiary {
        display: block;
    }

    .building-code-clause .nav-tertiary ul,
    .building-code-clause .nav-tertiary .nav-tertiary-heading {
        margin-left: em(25);
    }

    .building-code-clause .nav-tertiary li {
        font-size: em(15);
    }

    .building-code-clause .nav-title-wrapper {
        margin-left: em(25, 20);
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .building-code-clause .nav-tertiary {
        float: left;
        width: 39%;
        margin: em(54) 0 0 10.75%;
    }

    .building-code-clause .nav-title-wrapper {
        display: none;
    }
}

/*
  BUILDING-CODE-CLAUSE - tweaks to reusable block-news-item-section
---------------------------------------
*/

@media only screen and (min-width: $tablet_min) {
    .building-code-clause .block-news-item-section {
        margin-top: em(67);
    }
}

/*
  BUILDING-CODE-CLAUSE - block-versions
---------------------------------------
*/

.building-code-clause .block-versions h3 {
    @extend .gustan-black;

    padding-bottom: em(18, 20);
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin: em(34, 20) 0 em(22, 20);
    font-size: em(20);
    line-height: lh(29, 20);

    @media print, screen and (min-width: $mobile_min) {
        padding-bottom: em(21, 30);
    }
}

.block-versions .button {
    @include box-sizing(border-box);

    width: 100%;
}

/*
---------------------------------------
  BLOCK-DOCUMENT-LINKS (reusable block)
---------------------------------------
*/

.block-document-links {
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin-bottom: em(30);

    @media print, screen and (min-width: $mobile_min) {
        margin-bottom: em(25);
    }
}

.block-document-links li.large ul {
    margin: 0 0 0 em(67);
}

.block-document-links li {
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin: 0 0 em(15) 0;
}

.block-document-links li:last-child,
.block-document-links li.last {
    border-bottom: none;
}

.block-document-links li::before {
    display: none;
}

.block-document-links li a {
    position: relative;
    display: block;
    padding: em(0) 0 em(15) em(22);
    color: $mbiebuilding_gray_4;
    text-decoration: none;
}

.block-document-links li.large > a {
    position: relative;
    display: block;
    padding: em(4) 0 em(11) em(67);
    color: $mbiebuilding_gray_4;
    text-decoration: none;
}

.block-document-links li li a {
    display: block;
    padding: 0 0 0 em(20);
    color: $mbiebuilding_gray_4;
    text-decoration: none;
}

.block-document-links li a:hover,
.block-document-links li a:focus {
    text-decoration: none;
}

.block-document-links li.large > a .icon-pdf {
    position: absolute;
    top: em(2, 35);
    left: 0;
    font-size: em(35);
}

.block-document-links li a .icon-pdf {
    position: absolute;
    top: em(4, 6);
    left: 0;
    font-size: em(6);
}

.block-document-links li a .title-text {
    @extend .gustan-bold;

    display: block;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: em(15);
    line-height: lh(22, 15);
    color: $mbiebuilding_blue_1;
}

.block-document-links li a:hover .title-text,
.block-document-links li a:focus .title-text {
    text-decoration: underline;
}

.block-document-links li.large > a .meta {
    display: block;
    margin-bottom: em(1, 14);
    font-size: em(14);
}

.block-document-links li .meta {
    display: block;
    margin-bottom: em(5, 14);
    font-size: em(14);
}

.block-document-links li li .meta {
    margin-bottom: em(15, 14);
}

.block-document-links li a:hover .meta,
.block-document-links li a:focus .meta {
    text-decoration: none;
}

.block-document-links li ul {
    padding-top: em(15);
    border-top: 1px solid $mbiebuilding_gray_2;
    margin: 0 0 0 em(22);
}

.block-document-links li a:hover .date,
.block-document-links li a:focus .date {
    text-decoration: none;
}

.page-asvmversionpage .block-document-links {
    padding-top: 2.5em;
}

/*
---------------------------------------
  BLOCK-CAMPAIGN-WIDGET (reusable block)
---------------------------------------
*/

.block-campaign-widget {
    padding: 0 em(12) em(12) em(12);
    border: 1px solid $mbiebuilding_blue_1;
    margin: 0 0 em(77) 0;
    background-color: $mbiebuilding_white_1;
}

.block-campaign-widget h2.border-bottom {
    padding-bottom: em(13, 36);
    margin-top: em(23, 36);
    margin-bottom: em(19, 36);
}

.block-campaign-widget .block-campaign-widget-item {
    overflow: hidden;
}

.block-campaign-widget .block-campaign-widget-item a {
    text-decoration: none;
}

.block-campaign-widget .block-campaign-widget-item a:hover,
.block-campaign-widget .block-campaign-widget-item a:focus {
    text-decoration: none;
}

.block-campaign-widget .block-campaign-widget-item a .image {
    width: 100%;
    padding: 0;
    margin: 0 0 em(30) 0;
}

.block-campaign-widget .block-campaign-widget-item a .description {
    padding: em(3) em(13) 0;
    margin: 0;
}

.block-campaign-widget .block-campaign-widget-item a .description p {
    margin-bottom: em(14, 14);
    font-size: em(14);
    line-height: 1.43;
    color: $mbiebuilding_gray_4;
    text-decoration: none;
}

.block-campaign-widget .block-campaign-widget-item a:hover .description p,
.block-campaign-widget .block-campaign-widget-item a:focus .description p {
    color: $mbiebuilding_gray_4;
    text-decoration: none;
}

.block-campaign-widget .block-campaign-widget-item a:hover .description .faux-link,
.block-campaign-widget .block-campaign-widget-item a:focus .description .faux-link {
    text-decoration: underline;
}

.block-campaign-widget .block-campaign-widget-item a .description .faux-link {
    position: relative;
    display: inline-block;
    padding-left: em(17, 14);
    color: $mbiebuilding_blue_1;
    text-decoration: none;
}

.block-campaign-widget .block-campaign-widget-item a .description .faux-link::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '\e804';
    font-size: 1.1875em;
    color: #2b92d0;
}

@media print, screen and (min-width: $mobile_min) {
    .block-campaign-widget {
        padding: 0 em(41) em(23) em(41);
    }

    .block-campaign-widget .block-campaign-widget-item a .image {
        float: left;
        width: em(240);
        margin: 0;
    }

    .block-campaign-widget .block-campaign-widget-item a .description {
        padding: em(3) 0 0 em(255);
        margin: 0;
    }
}

