// ############################################
// Calc Grid Mixins
// ############################################

// Create a row - @include grid-row();
// Create an item - @include span-column(1 / 4); optionally @include span-column(1 / 4, 40px);
// Can also specify the justification if for example you want to center the column on a page.
// eg @include grid-row('center').
$g-gutter: 40px;

@mixin grid-row($justify:'space-between', $flex-direction: 'row') {
    display: flex;
    flex-direction: #{$flex-direction};
    flex-wrap: wrap;

    @if $justify == 'space-between' {
        -ms-flex-pack: justify;
    }

    @if $justify =='space-around' {
        -ms-flex-pack: distribute;
    }

    @if $justify == 'flex-start' {
        -ms-flex-pack: start;
    }

    @if $justify == 'flex-end' {
        -ms-flex-pack: end;
    }

    @if $justify == 'center' {
        -ms-flex-pack: center;
    }

    justify-content: #{$justify};
}

@mixin grid-column($justify:'space-between', $flex-direction: 'row') {
    @include grid-row($justify, $flex-direction);

    align-items: stretch;
    flex-wrap: nowrap;
}

@mixin span-column($frac, $gutter:false, $flex-start:false, $nth: 2) {
    $current-gutter: $g-gutter;

    @if ($gutter) {
        $current-gutter: $gutter;
    }

    flex-basis: calc(100% * #{$frac} - #{$current-gutter} * (1 - #{$frac}));
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    max-width: calc(100% * #{$frac} - #{$current-gutter} * (1 - #{$frac}));
    //margin-bottom: $g-gutter;

    @if ($flex-start) {
        margin-right: $current-gutter;

        &:nth-child(odd),
        &:nth-child(even) {
            margin-right: $current-gutter;
        }

        &:nth-child(#{$nth}n) {
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}


// TODO create these if required - possibly can be done using order
// @mixin push() {

// }

// @mixin pull() {

// }
