/**
 * Create a intrinsically responsive layout using flexbox.
 * Used for laying two items out horizontally, which "breaks" at a point defined at the container level.
 *
 * @param {unit} $sidebarWidth - unit of measurement for the size of the sidebar - if null it will use
 * the intrinsic width of the element
 * @param {unit} $space - unit of measurement for the gap between items (can be zero for no gap)
 * @param {left|right} $sidebarPosition - defines whether the sidebar item is to the left or right
 * @param {percentage} $contentMinWidth - minimum percentage for the 'main' column that the layout will break at
 */
@mixin sidebar-l(
    $sidebarWidth: null,
    $space: rem(20),
    $sidebarPosition: left,
    $contentMinWidth: 50%
) {
    > * {
        @if ($space != 0) {
            margin: calc(#{$space} / 2 * -1);
        }

        display: flex;
        flex-wrap: wrap;
    }

    /* stylelint-disable-next-line selector-max-universal */
    > * > * {
        @if ($sidebarWidth) {
            flex-basis: $sidebarWidth;
        }

        @if ($space != 0) {
            margin: calc(#{$space} / 2);
        }

        flex-grow: 1;
    }

    /* stylelint-disable-next-line order/order */
    @if ($sidebarPosition == right) {
        > * > :first-child {
            @if ($space != 0) {
                min-width: calc(#{$contentMinWidth} - #{$space});
            }

            flex-basis: 0;
            flex-grow: 999;
        }
    } @else {
        > * > :last-child {
            @if ($space != 0) {
                min-width: calc(#{$contentMinWidth} - #{$space});
            }

            flex-basis: 0;
            flex-grow: 999;
        }
    }
}
