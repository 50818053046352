
@import '../tools';

/*
 ---------------------------------------
 BLOCK - alert (version 02)
 ---------------------------------------
*/

.block-alert02 {
    position: relative;
    overflow: hidden;
    padding: em(12, 16) em(14, 16) em(1, 16) em(49, 16);
    background-color: $mbiebuilding_blue_6;

    @media print, screen and (min-width: $mobile_min) {
        padding: em(14, 16) em(14, 16) em(9, 16) em(74, 16);
    }
}

.block-alert02 .icon {
    position: absolute;
    top: 50%;
    left: em(10, 27);
    margin-top: em(-27, 27);
    font-size: em(27);
    font-weight: bold;
    color: $mbiebuilding_orange_2;

    @media print, screen and (min-width: $mobile_min) {
        left: em(20, 39);
        margin-top: em(-27, 39);
        font-size: em(39);
    }
}

.block-alert02 .description {
    margin-top: em(6);
    font-size: em(14);
    line-height: 1.43;
    color: $mbiebuilding_gray_4;
}

.block-alert02 .description p,
.block-alert02 .description li {
    font-size: inherit !important;
    line-height: inherit !important;
}
