.block-video {
    $self: &;

    padding: rem(20) rem(25) rem(25);
    margin-top: rem(20);
    background-color: $mbiebuilding_white_1;

    &__title {
        margin-top: 0;
        margin-bottom: rem(20);
        font-size: rem(25);
        color: $mbiebuilding_blue_2;
    }

    &__wrapper {
        margin: 0;
    }

    &__content {
        margin-top: rem(20);
    }

    &__caption {
        @extend .fira-sans-light;

        color: $mbiebuilding_gray_5;
    }

    &__details {
        border-top: rem(1) solid $mbiebuilding_gray_7;
        margin-top: rem(20);
        margin-bottom: 0;
    }

    &__date {
        @extend .fira-sans-light;

        font-size: rem(12.5);
        color: $mbiebuilding_gray_4;
    }

    .video-meta-data-and-transcript {
        #{$self}__metadata {
            padding-top: rem(17);
            border-top: 0;

            p {
                margin-bottom: 0;
            }
        }

        #{$self}__transcript {
            padding-bottom: 0;
            border-bottom: 0;
            margin: 0;
        }
    }

    &__button {
        @extend .fira-sans-regular;

        padding: rem(12) rem(16);
        margin-top: rem(12);
        font-size: rem(12.5);

        .icon {
            margin-right: 0;
            margin-left: rem(5);
        }
    }

    &__transcript-inner {
        margin-top: rem(25);
    }

    &__transcript-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: rem(24);
        border-bottom: rem(2) solid $mbiebuilding_gray_7;
        margin-top: 0;
        margin-bottom: rem(28);
    }

    &__transcript-print {
        @extend .fira-sans-regular;

        font-size: rem(13);
        color: $mbiebuilding_gray_7;
        text-align: center;

        &:hover,
        &:focus {
            color: $mbiebuilding_blue_1;
        }

        .icon {
            display: block;
            font-size: rem(19);
        }
    }

    @media (min-width: $tablet_min) {
        padding: rem(40) rem(60) rem(25);
        margin-top: rem(40);

        &__title {
            margin-bottom: rem(40);
            font-size: rem(36);
        }

        &__content {
            margin-top: rem(30);
        }

        &__details-inner {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: rem(22);
        }

        .video-meta-data-and-transcript {
            #{$self}__metadata {
                padding-top: 0;
            }
        }

        &__caption {
            padding-right: rem(170);

            p {
                font-size: rem(18);
                line-height: lh(24, 18);
            }
        }

        &__button {
            margin-top: 0;
        }

        &__transcript-title {
            padding-bottom: rem(29);
            margin-bottom: rem(33);
        }

        &__transcript-content {
            padding-right: rem(170);
        }
    }

    @media print {
        padding: rem(20) 0;
        border-top: 1px solid $mbiebuilding_gray_2;
        margin: 0;

        &__title {
            margin-bottom: 0;
        }

        &__content {
            margin-top: 0;
        }

        &__wrapper {
            height: auto;
            padding: 0;
            padding: rem(10);
            border: 1px solid $mbiebuilding_gray_2;
            margin: rem(20) 0;

            &::after {
                content: attr(data-video-title) ': ' attr(data-video-url) '';
            }
        }

        &__wrapper iframe,
        &__transcript-print {
            display: none;
        }

        &__caption p {
            margin: 0;
        }

        &__details {
            border: 0;
            margin: 0;
        }

        &__details-inner {
            padding-top: rem(10);
        }

        &__transcript-inner {
            margin-top: rem(20);
        }

        &__transcript-title {
            padding-bottom: rem(10);
            border: 0;
            margin-bottom: rem(10);
            font-size: em(25);
        }

        .video-meta-data-and-transcript .transcript {
            height: auto;
        }
    }
}
