.landing-intro {
    .intro-text {
        margin-bottom: rem(31);
    }

    button {
        margin-top: rem(13);
    }

    .homepage & {
        border-top: 0;
        margin-top: rem(35);

        .intro-text {
            margin: rem(18) 0 rem(17);
        }
    }

    @media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
        .layout-col-8 {
            float: left;
            width: 47%;
            margin-right: 5%;
        }

        .layout-col-4 {
            float: left;
            width: 48%;
        }
    }

    @media only screen and (min-width: $tablet_min) {
        .button {
            margin-top: rem(24);
            font-size: rem(16);
        }

        .layout-col-8 {
            @include box-sizing(border-box);

            padding-right: rem(80);
            margin-right: 3.4%;
        }

        .layout-col-4 {
            margin-right: 0;
        }

        .intro-text {
            margin: rem(33) 0 rem(29);

            p {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }

    @media only screen and (min-width: $desktop_min) {
        .homepage & {
            .intro-text {
                margin-top: 0;
                margin-bottom: 0;
            }

            .layout-col-6 {
                @include box-sizing(border-box);

                width: 50%;
                padding-right: rem(40);
                margin-right: 0;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

