
/* -------------------------------------
   COLOURS
   -------------------------------------
*/

/* White */
$mbiebuilding_white_1: #fff; /* White */

/* Gray */
$mbiebuilding_body: #404040; /* Dark gray */
$mbiebuilding_gray_1: #f9f9f7; /* Very light gray */
$mbiebuilding_gray_2: #cdcdcc; /* Light gray */
$mbiebuilding_gray_3: #727272; /* Mid gray */
$mbiebuilding_gray_4: #666; /* Another mid gray */
$mbiebuilding_gray_5: #4d4d4d; /* Dark gray */
$mbiebuilding_gray_6: #272727; /* Very Dark gray */
$mbiebuilding_gray_7: #757575; /* Another light gray */

/* Blue */
$mbiebuilding_blue_1: #005da6; /* Primary blue */
$mbiebuilding_blue_2: #012f64; /* Darker blue */
$mbiebuilding_blue_3: #2b92d0; /* Light blue */
$mbiebuilding_blue_4: #00bce7; /* Cyan blue */
$mbiebuilding_blue_5: #0d72c1; /* Light blue (variation) */
$mbiebuilding_blue_6: #e2f1f6; /* Gray-ish blue */
$mbiebuilding_blue_7: #3bcced; /* light blue (variation) */
$mbiebuilding_blue_8: #0080ff; /* light blue (variation) */


/* Yellow */
$mbiebuilding_yellow_1: #fff21f; /* Yellow */

/* Red */
$mbiebuilding_red_1: #ad4525; /* Burnt red */
$mbiebuilding_red_2: #c51f2d; /* Faded red */

/* Orange */
$mbiebuilding_orange_1: #f6891f; /* Bright orange */
$mbiebuilding_orange_2: #f26b04;

/* Darker orange

/* Building code colours */
$building_code_a: $mbiebuilding_blue_2;
$building_code_b: $mbiebuilding_red_1;
$building_code_c: #e32526;
$building_code_d: $mbiebuilding_orange_1;
$building_code_e: #006473;
$building_code_f: #604099;
$building_code_g: #00bce8;
$building_code_h: #97c93c;
$building_code_sb: $mbiebuilding_blue_1;
