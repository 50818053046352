@use 'sass:math';

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.block-news {
    $self: &;

    .news-item {
        padding: 0;

        &__image-wrap {
            order: unset;
        }
    }

    &__button-wrap {
        margin-top: rem(35);
        text-align: center;
    }

    &__button {
        display: block;
        width: 100%;
        border-color: $mbiebuilding_blue_2;
        color: $mbiebuilding_blue_2;

        &:hover,
        &:focus {
            border-color: $mbiebuilding_blue_1;
        }
    }

    &[data-state='show-more'] {
        .news-item {
            animation-duration: .5s;
            animation-name: slide-up;
            animation-timing-function: ease-in-out;

            &:nth-child(-n+3) {
                animation: none;
            }
        }
    }

    @media screen and (min-width: $mobile_min) {
        &__container {
            @include grid-row();
            @include grid-l(300px, rem(20), 'space-between');

            grid-column-gap: rem(20);
            grid-row-gap: rem(20);

            /* autoprefixer: off */
            grid-auto-rows: rem(5);
        }

        .news-item {
            @include span-column(math.div(1, 2), rem(20));

            margin-top: 0;

            &:nth-child(n + 4) {
                margin-top: rem(20);
            }

            @supports (display: grid) {
                &:nth-child(n + 4) {
                    margin-top: 0;
                }
            }
        }
    }

    @media screen and (min-width: $tablet_min) {
        .news-item {
            // Element must be block (not flex) to enable calculating the height properly
            display: block;
            padding: 0;

            &__content {
                padding: rem(25) rem(30) rem(10);
            }

            &__description {
                padding-right: 0;
            }

            .tag-list {
                padding: rem(11) rem(30) rem(24);
            }

            &--has-image {
                .news-item__inner {
                    padding-right: 0;
                }
            }
        }

        &__button-wrap {
            margin-top: rem(60);
        }

        &__button {
            display: inline-block;
            width: auto;
        }
    }

    @media screen and (min-width: $desktop_min) {
        &__container {
            grid-column-gap: rem(33);
            grid-row-gap: rem(30);
        }

        .news-item {
            @include span-column(math.div(1, 3), rem(30));

            &:nth-child(n + 4) {
                margin-top: rem(30);
            }

            @supports (display: grid) {
                &:nth-child(n + 4) {
                    margin-top: 0;
                }
            }
        }
    }

    @media print {
        margin-top: rem(40);

        .news-item {
            padding-bottom: rem(30);

            &__link {
                float: left;
                width: 100%;

                &::after {
                    float: left;
                    width: 100%;
                    margin-top: rem(10);
                }

                > *:nth-child(2) {
                    width: 60%;
                }
            }

            &__image-wrap {
                float: left;
                width: 35%;
                margin-right: 4.5%;
            }

            &__content {
                @include box-sizing();

                float: left;
                width: 100%;
                padding: 0;
            }

            .tag-list {
                @include box-sizing();

                float: left;
                padding-left: 0;
            }
        }

        &__button {
            display: inline-block;
        }
    }
}
