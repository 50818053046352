
@import '../tools';

/*
 TIP-BOX
---------------------------------------
*/

.page-maincontent .tip-box {
    position: relative;
    display: block;
    box-sizing: border-box;
    max-width: none;
    padding: em(20, 16) em(19, 16) em(9, 16) em(51, 16);
    margin: em(15, 16) 0;
    background-color: $mbiebuilding_white_1;
}

.page-maincontent .tip-box .icon {
    position: absolute;
    top: em(17, 30);
    left: em(11, 30);
    font-size: em(30);
    color: tint($mbiebuilding_blue_8, 50%);
}

.page-maincontent .tip-box p {
    @extend .fira-sans-regular;

    font-size: em(14);
    line-height: 1.43;
    color: $mbiebuilding_gray_4;
}

@media only screen and (min-width: $tablet_min) {
    .page-maincontent .tip-box {
        display: inline-block;
        padding: em(28, 16) em(19, 16) em(9, 16) em(72, 16);
    }

    .page-maincontent .tip-box.left {
        max-width: 50%;
        margin: em(15, 16) em(15, 16) em(15, 16) 0;
    }

    .page-maincontent .tip-box.right {
        max-width: 50%;
        margin: em(15, 16) 0 em(15, 16) em(15, 16);
    }

    .page-maincontent .tip-box .icon {
        top: em(17, 41);
        left: em(20, 41);
        font-size: em(41);
    }

    .page-maincontent .tip-box p {
        font-size: em(14);
        line-height: 1.43;
    }
}
