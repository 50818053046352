
@import '../tools';

/* block-publication-promo */

.block-publication-promo-content {
    position: relative;
    padding: em(20) em(15) em(10);
    background-color: white;
}

.content-wrapper .block-publication-promo-content {
    margin-top: 2em;
}

.content-wrapper .block-publication-promo-content::before {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: '';
    height: em(3);
}

.block-publication-promo-content .title {
    @extend .gustan-bold;

    position: relative;
    padding-bottom: em(15, 20);
    padding-left: em(60, 20);
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin: 0 0 em(10, 20);
    font-size: em(20);
    line-height: lh(24, 20);
}

.block-publication-promo-content .title a {
    color: $mbiebuilding_blue_1;
    text-decoration: none;
}

.block-publication-promo-content .title a:focus,
.block-publication-promo-content .title a:hover {
    text-decoration: none;
}

.block-publication-promo-content .title [class^='icon-'] {
    position: absolute;
    top: em(4, 47);
    left: em(2, 47);
    overflow: hidden;
    font-size: em(47, 20); /* reset */
}

.block-publication-promo-content .title .icon-pdf {
    top: em(4, 25);
    left: em(2, 25);
    font-size: em(25, 20);
}

.block-publication-promo-content .title a:focus .title-text,
.block-publication-promo-content .title a:hover .title-text {
    text-decoration: underline;
}

.block-publication-promo-content .title a .meta {
    @extend .fira-sans-regular;

    display: block;
    margin-top: em(9, 14);
    font-size: em(14, 20);
    line-height: 1.43;
    color: $mbiebuilding_gray_3;
}

.block-publication-promo-content .title a:focus .meta,
.block-publication-promo-content .title a:hover .meta {
    color: $mbiebuilding_gray_3;
    text-decoration: none;
}

.block-publication-promo-content .description p {
    font-size: em(14);
    line-height: 1.43;
    color: $mbiebuilding_gray_4;
}

@media print, screen and (min-width: $mobile_min) {
    .block-publication-promo-content {
        padding: em(38) em(25) em(16) em(28);
    }

    /* Floated version inside content-wrapper */

    .content-wrapper .block-publication-promo-content {
        float: right;
        width: 45%;
        max-width: em(313);
        margin-left: 5%;
    }

    .block-publication-promo-content .title {
        padding-bottom: em(24, 20);
        margin: 0 0 em(27, 20);
    }
}
