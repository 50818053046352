@use 'sass:math';
@import '../tools';


/*
  guidance-reading specific styling
*/

#content .guidance-reading .intro-text p,
#content .guidance-reading .intro-text ul,
#content .guidance-reading .intro-text ol {
    margin-bottom: 1em;
}

#content .guidance-reading .intro-text p:last-child,
#content .guidance-reading .intro-text ul:last-child,
#content .guidance-reading .intro-text ol:last-child {
    margin-bottom: 0;
}

.guidance-reading h2,
.guidance-reading .h2 {
    font-size: em(25);
    line-height: lh(33, 25);
}

.guidance-reading h2.border-bottom-gray,
.guidance-reading .h2.border-bottom-gray,
.guidance-video h2.border-bottom-gray,
.guidance-video .h2.border-bottom-gray {
    padding-bottom: em(11, 25);
    border-bottom: 2px solid $mbiebuilding_gray_7;
    margin-bottom: em(27, 25);
}

/*
  guidance-video specific styling
---------------------------------------
*/

.guidance-video .aside-right h2 {
    margin-top: em(52, 36);
}

/*
 Grid override
*/

.guidance-video .main-left.layout-col-8 {
    //width:((100%-$margin_right)/($total_cols/8) + $margin-right);
    //margin-right: (((100%-($margin_right*11))/$total_cols));
    width: 100%;
    margin-right: 0;

    @media only screen and (min-width: $tablet_min) {
        width: 68.17%;
        margin-right: 8.362%;
    }
}

.guidance-video .aside-right.layout-col-3 {
    //width:((100%-$margin_right)/($total_cols/3));
    width: 100%;
    margin-right: 0;

    @media only screen and (min-width: $tablet_min) {
        width: 23.468%;
    }
}

/*
  RELATED VIDEOS (in aside-right)
---------------------------------------
*/

.guidance-video .aside-right .block-related-videos .title-wrapper {
    padding-bottom: em(8, 16);
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin-bottom: em(20, 16);
}

.guidance-video .aside-right .block-related-videos .item {
    position: relative;
    min-height: em(100);
    overflow: hidden;
    padding-bottom: em(9, 16);
    border-bottom: 1px solid $mbiebuilding_gray_3;
    margin: 0 0 em(30, 16);
}

.guidance-video .aside-right .block-related-videos .item:last-child,
.guidance-video .aside-right .block-related-videos .item.last {
    border: none;
}

.guidance-video .aside-right .block-related-videos .item .liner .title {
    @extend .gustan-light;

    padding-bottom: em(14, 25);
    margin-top: 0;
    margin-bottom: 0;
    font-size: em(25);
    line-height: lh(31, 25);
}

.guidance-video .aside-right .block-related-videos .item .liner {
    float: none;

    @media print, screen and (min-width: $mobile_min) {
        float: left;
        width: 50%;
    }

    @media only screen and (min-width: $tablet_min) {
        float: none;
        width: 100%;
    }
}

.guidance-video .aside-right .block-related-videos .item .liner .meta {
    @extend .gustan-black;

    padding-bottom: em(18, 12);
    border-bottom: 1px solid $mbiebuilding_gray_3;
    margin-top: 0;
    margin-bottom: em(16, 12);
    font-size: em(12);
    line-height: lh(20, 12);
    text-transform: uppercase;
}

.guidance-video .aside-right .block-related-videos .item .description {
    color: $mbiebuilding_gray_4;
}

.guidance-video .aside-right .block-related-videos .item .description p {
    font-size: em(14);
    line-height: 1.43;
}

.guidance-video .aside-right .block-related-videos .item .image {
    position: relative;
    float: none;
    width: 100%;
    margin-bottom: em(22, 16);

    @media print, screen and (min-width: $mobile_min) {
        @include box-sizing(border-box);

        float: left;
        width: 50%;
        padding-right: math.div((100%-($margin_right*11)), $total_cols);
    }

    @media only screen and (min-width: $tablet_min) {
        float: none;
        width: 100%;
    }
}

.guidance-video .aside-right .block-related-videos .item .image img {
    width: 100%;
    height: auto;
    max-width: none;
}

.video-wrapper {
    position: relative;
    height: 0;
    padding-top: em(25, 16);
    padding-bottom: 56.25%; /* 16:9 */
    margin: em(60, 16) auto em(36, 16);
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-frame {
    border: none;
}

.guidance-video .video-caption {
    padding-bottom: em(5, 16);
}

.guidance-video .video-caption p {
    @extend .fira-sans-regular;

    font-size: em(16);
    line-height: lh(22, 16);
    color: $mbiebuilding_gray_5;
}

.video-meta-data-and-transcript .meta-data {
    padding-top: em(20, 16);
    border-top: 1px solid $mbiebuilding_gray_3;
}

.video-meta-data-and-transcript .meta-data p {
    @extend .fira-sans-light;

    margin-bottom: em(10, 12.5);
    font-size: em(12.5);
    line-height: lh(17.86, 12.5);
    color: $mbiebuilding_gray_4;
}

.video-meta-data-and-transcript .meta-data .button {
    @extend .fira-sans-regular;

    float: right;
    margin-top: em(17, 12.5);
    font-size: em(12.5);
    line-height: lh(17.86, 12.5);
}

.video-meta-data-and-transcript .transcript {
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
}

.video-meta-data-and-transcript.active .transcript {
    height: auto;
    padding-bottom: em(45, 16);
    border-bottom: 1px solid $mbiebuilding_gray_3;
    margin-top: em(16);
    margin-bottom: em(66, 16);
}

.video-meta-data-and-transcript .transcript h2 {
    position: relative;
    padding-right: em(40, 30);
    padding-bottom: em(24, 30);
    margin-top: 0;
    margin-bottom: em(28, 30);
}

.video-meta-data-and-transcript .transcript h2 .print-this-page {
    @extend .fira-sans-regular;

    position: absolute;
    top: em(15, 30);
    right: em(5, 30);
    font-size: 13px; /* use px */
    color: $mbiebuilding_gray_7;
    text-align: center;
}

.video-meta-data-and-transcript .transcript h2 .print-this-page:focus,
.video-meta-data-and-transcript .transcript h2 .print-this-page:hover {
    color: $mbiebuilding_blue_1;
}

.video-meta-data-and-transcript .transcript h2 .print-this-page .icon {
    display: block;
    font-size: 19px; /* use px */
}

.video-meta-data-and-transcript .transcript p {
    @extend .fira-sans-regular;

    margin-bottom: em(10, 15);
    font-size: em(15);
    line-height: lh(21, 15);
    color: $mbiebuilding_gray_4;
}

@media only screen and (min-width: $tablet_min) {
    .video-meta-data-and-transcript .transcript h2 .print-this-page {
        top: em(15, 36);
        right: em(5, 36);
    }
}
