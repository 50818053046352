
@import 'colours';

/* temporary - used to determine which colours to use */

.temp-c {
    height: 53px;
    padding: 10px;
    padding-top: 40px;
    margin-bottom: 1em;
    text-align: center;
}

.mbiebuilding_white_1 {
    background: $mbiebuilding_white_1;
    color: #000;
}

.mbiebuilding_body {
    background: $mbiebuilding_body;
    color: #fff;
}

.mbiebuilding_gray_1 {
    background: $mbiebuilding_gray_1;
}

.mbiebuilding_gray_2 {
    background: $mbiebuilding_gray_2;
}

.mbiebuilding_gray_3 {
    background: $mbiebuilding_gray_3;
    color: #fff;
}

.mbiebuilding_gray_4 {
    background: $mbiebuilding_gray_4;
    color: #fff;
}

.mbiebuilding_gray_5 {
    background: $mbiebuilding_gray_5;
    color: #fff;
}

.mbiebuilding_gray_6 {
    background: $mbiebuilding_gray_6;
    color: #fff;
}

.mbiebuilding_gray_7 {
    background: $mbiebuilding_gray_7;
    color: #fff;
}

.mbiebuilding_blue_1 {
    background: $mbiebuilding_blue_1;
    color: #fff;
}

.mbiebuilding_blue_2 {
    background: $mbiebuilding_blue_2;
    color: #fff;
}

.mbiebuilding_blue_3 {
    background: $mbiebuilding_blue_3;
    color: #fff;
}

.mbiebuilding_blue_4 {
    background: $mbiebuilding_blue_4;
}

.mbiebuilding_blue_5 {
    background: $mbiebuilding_blue_5;
    color: #fff;
}

.mbiebuilding_blue_6 {
    background: $mbiebuilding_blue_6;
}

.mbiebuilding_blue_7 {
    background: $mbiebuilding_blue_7;
}

.mbiebuilding_blue_8 {
    background: $mbiebuilding_blue_8;
    color: #fff;
}

.mbiebuilding_yellow_1 {
    background: $mbiebuilding_yellow_1;
}

.mbiebuilding_red_1 {
    background: $mbiebuilding_red_1;
    color: #fff;
}

.mbiebuilding_red_2 {
    background: $mbiebuilding_red_2;
    color: #fff;
}

.mbiebuilding_orange_1 {
    background: $mbiebuilding_orange_1;
    color: #fff;
}

.mbiebuilding_orange_2 {
    background: $mbiebuilding_orange_2;
    color: #fff;
}

.building_code_a {
    background: $building_code_a;
    color: #fff;
}

.building_code_b {
    background: $building_code_b;
    color: #fff;
}

.building_code_c {
    background: $building_code_c;
    color: #fff;
}

.building_code_d {
    background: $building_code_d;
    color: #fff;
}

.building_code_e {
    background: $building_code_e;
    color: #fff;
}

.building_code_f {
    background: $building_code_f;
    color: #fff;
}

.building_code_g {
    background: $building_code_g;
    color: #fff;
}

.building_code_h {
    background: $building_code_h;
    color: #fff;
}
