@use 'sass:math';

.block-building-code {
    &__intro {
        margin-bottom: rem(16);
    }

    &__description {
        @extend .gustan-book;

        font-size: rem(14);
    }

    .block-code-nav-bar {
        padding-bottom: 0;
        margin: rem(20) 0 0 0;

        .code-section {
            flex-basis: rem(100);

            &:nth-last-of-type(2) {
                margin-right: 0;
            }
        }

        .code-button {
            .code-icon {
                font-size: rem(55);
            }
        }
    }

    &__details {
        margin-top: rem(12);
    }

    &__details-text {
        @extend .gustan-book;

        font-size: rem(14);
    }

    &__details-inner {
        p {
            margin-top: rem(12);
            margin-bottom: 0;
            line-height: lh(18, 14);
        }
    }

    &__details-link {
        @extend .gustan-bold;

        display: flex;
        align-items: center;
        line-height: lh(18);
        color: $mbiebuilding_gray_5;

        .icon {
            margin-right: rem(8);
            font-size: 1.25em;
            line-height: lh(18, 20);
            color: $mbiebuilding_blue_1;
        }

        &:hover,
        &:focus {
            color: $mbiebuilding_blue_1;
        }
    }

    &__button {
        display: block;
        padding-right: rem(14);
        padding-left: rem(14);
        margin-top: rem(30);
        letter-spacing: -#{rem(.05)};
    }

    @media (min-width: $tablet_min) {
        &__content {
            @include grid-row();
        }

        &__intro {
            @include span-column(math.div(2, 5), rem(40));

            margin: 0;
        }

        &__description-wrap {
            @include span-column(math.div(3, 5), rem(40));

            display: flex;
            justify-content: center;

            p {
                margin-bottom: 0;
                font-size: rem(14);
            }
        }

        &__description {
            max-width: rem(460);
        }

        .block-code-nav-bar {
            margin-top: rem(35);
            margin-bottom: 0;
        }

        &__details {
            margin-top: rem(23);
            text-align: center;
        }

        &__details-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: rem(900);
            margin-right: auto;
            margin-left: auto;
            text-align: left;

            p {
                margin-top: 0;
                font-size: rem(14);
            }
        }

        &__details-link {
            align-self: flex-start;
            flex-shrink: 0;
            margin-right: rem(35);
        }

        &__button {
            display: inline-block;
            padding-right: rem(20);
            padding-left: rem(20);
            margin-top: rem(40);
        }
    }

    @media (min-width: 1090px) {
        .block-code-nav-bar {
            margin-bottom: rem(20);

            @supports (display: grid) {
                grid-row-gap: 0;
            }
        }
    }

    @media (min-width: 1220px) {
        .block-code-nav-bar {
            padding-bottom: 0;

            @supports (display: grid) {
                /* stylelint-disable max-line-length */
                grid-template-areas:
                    '. a b c d e f g h .'
                    'contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1';
                /* stylelint-enable max-line-length */
                grid-template-columns: 1fr 100px 100px 100px 100px 100px 100px 100px 100px 1fr;
                grid-column-gap: 50px;
            }
        }
    }

    @media print {
        &__details-link {
            color: $mbiebuilding_blue_1;
        }
    }
}
