/* stylelint-disable value-keyword-case */
$font-path: '../fonts/';
$font-family-medium: 'Gustan Medium', Arial, sans-serif;
$font-family-light: 'Gustan Light', Arial, sans-serif;
$font-family-book: 'Gustan Book', Arial, sans-serif;
$font-family-bold: 'Gustan Bold', Arial, sans-serif;
$font-family-black: 'Gustan Black', Arial, sans-serif;
$font-family-san-light: 'Fira Sans Light', Arial, sans-serif;
$font-family-san-regular: 'Fira Sans Regular', Arial, sans-serif;
$font-family-san-medium: 'Fira Sans Medium', Arial, sans-serif;
/* stylelint-enable value-keyword-case */

/* stylelint-disable */
$font-mbie-building-icon: 'mbie-building-icons';
/* stylelint-enable */

$img-path: '../images/';


/* -------------------------------------
   COLUMNS/GRID (based on 12 equal cols)
   -------------------------------------
*/
$margin_right: 2.584%;
$total_cols: 12;


/* -------------------------------------
   BREAKPOINTS
   -------------------------------------
*/
$mobile_min: 600px;
$mobile_max: 599px;
$tablet_min: 800px;
$tablet_max: 799px;
$desktop_min: 1180px;
$desktop_max: 1179px;

/* -------------------------------------
   Icons names. These are not the complete list. These icons reused in another selector.
   -------------------------------------
*/
$icon-house2: '\e837';
$icon-word: '\e820';
$icon-excel: '\e821';
$icon-document: '\e807';
$icon-tool: '\e80b';
$icon-document2: '\e828';
$icon-external: '\e81a';
$icon-close: '\e830';
