.secondary-tiles {
    $self: &;

    margin-top: rem(20);

    &__item {
        @include box-sizing();

        display: block;
        padding: rem(25) rem(30);
        border-top: rem(4) solid $mbiebuilding_blue_4;
        margin-top: rem(20);
        background-color: $mbiebuilding_white_1;

        &:first-child {
            margin-top: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;

            #{$self}__title {
                border-color: $mbiebuilding_blue_4;
            }
        }
    }

    &__title {
        display: inline;
        border-bottom: rem(1) solid transparent;
        margin-top: 0;
        margin-bottom: 0;
        font-size: rem(25);
        color: $mbiebuilding_blue_2;
        transition: border-color .1s ease-in-out;
    }

    &__description {
        @extend .fira-sans-light;

        color: $mbiebuilding_gray_5;
    }

    p {
        margin-top: rem(15);
        margin-bottom: 0;
    }

    &__icon-wrap {
        display: inline-block;
    }

    &__icon {
        display: inline-block;
        vertical-align: bottom;
        margin-left: rem(10);

        &::before {
            position: relative;
            bottom: 0;
            display: inline-block;
            content: '';
            width: .4em;
            height: .7em;
            background: url($img-path + 'icons/icon-chevron-right-blue.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    @media (min-width: $mobile_min) {
        @include cluster-l(rem(30));

        margin-top: rem(55);

        &__container {
            @include grid-l(300px, rem(20), 'unset', 'inherit', auto-fill);
        }

        &__item {
            max-width: 44%;

            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @supports (display: grid) {
            margin-top: rem(40);

            &__container,
            &__item {
                margin: 0;
            }
        }
    }

    @media (min-width: $tablet_min) {
        &__item {
            max-width: 46%;
        }
    }

    @media (min-width: $desktop_min) {
        &__item {
            max-width: 30.5%;

            &:nth-child(3) {
                margin-top: 0;
            }
        }

        &__title {
            font-size: rem(28);
            line-height: lh(34, 28);
        }

        p {
            margin-top: rem(20);
            font-size: rem(16);
        }

        @supports (display: grid) {
            &__container {
                grid-column-gap: rem(30);
                grid-row-gap: rem(30);
            }
        }
    }

    @media print {
        &__container {
            display: block;
        }

        &__item {
            padding-left: 0;
            border-top: rem(1) solid black;
            page-break-inside: avoid;
        }

        &__title {
            color: $mbiebuilding_blue_1;
        }
    }
}
