.accordion {
    color: #444;
    margin-bottom: 0;
    cursor: pointer;
    padding: 16px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-title:hover {
  text-decoration: underline;
}

.accordion-icon {
  width: 20px;
  height: 20px;
}

.accordion-icon-plus {
  transition: all 0.4s;
}

.panel {
    opacity: 0;
    height: 0;
    overflow: hidden;
    width: 100%;
    transition: all 0.4s;
}
