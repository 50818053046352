
@import '../tools';

/*
---------------------------------------
 PAGE OPTIONS
---------------------------------------
*/

/*
 PAGE OPTIONS - page options in the page (page options in header toggle with these)
---------------------------------------
*/

.page-options {
    font-size: rem(16);
    text-align: center;

    .page-maincontent & {
        margin: rem(16) 0 0;
    }

    li {
        display: inline-block;
        margin: 0 rem(13);

        &::before {
            display: none;
        }

        .link {
            display: inline-block;
            color: $mbiebuilding_gray_5;

            &:hover,
            &:focus {
                text-decoration: none;

                .icon {
                    color: $mbiebuilding_blue_1;
                    text-decoration: none;
                }

                .text {
                    text-decoration: underline;
                }
            }

            .icon {
                display: block;
                font-size: em(24);
                text-align: center;
            }

            .text {
                @extend .fira-sans-regular;

                display: block;
                clear: left;
                margin: rem(6) 0 0 0;
                font-size: rem(14);
                line-height: 1;
                text-align: center;
            }
        }
    }

    @media only screen and (min-width: $mobile_min) {
        .page-maincontent & {
            display: none;
        }
    }
}


/*
 PAGE OPTIONS - page options in header  (page options in the page toggle with these)
---------------------------------------
*/

.h1-wrap.with-page-options {
    display: flex;
    justify-content: space-between;
    overflow: visible;
    padding-bottom: rem(28);

    h1 {
        flex-grow: 1;
        padding-right: rem(20);
        margin-bottom: 0;
    }

    .page-options {
        display: none;
        align-self: flex-end;
        flex-shrink: 0;

        li {
            margin: 0 0 0 rem(7);

            .link {
                .icon {
                    font-size: rem(20);
                }

                .text {
                    margin: 0;
                    font-size: rem(12);
                }
            }
        }
    }

    @media print, screen and (min-width: $mobile_min) {
        .page-options {
            display: block;
        }
    }

    @media print, screen and (min-width: $tablet_min) {
        padding-bottom: rem(34);
    }

    @media print, screen and (min-width: $desktop_min) {
        h1 {
            padding-right: rem(50);
        }
    }
}

.hero {
    .h1-wrap.with-page-options {
        @media print, screen and (max-width: $mobile_max) {
            padding-bottom: rem(20);

            h1 {
                padding-right: rem(5);
            }
        }
    }
}
