
@import '../tools';

/*
  .block-news-item-section
---------------------------------------
*/

.block-news-item-section .news-section-intro {
    margin-bottom: em(26);
}

.block-news-item-section .news-section-intro p {
    margin: 0;
    color: $mbiebuilding_gray_4;
}

@media print, screen and (min-width: $tablet_min) {
    .block-news-item-section .news-section-intro {
        margin-bottom: em(36);
    }

    .block-news-item-section .news-section-intro p {
        margin: 0;
        font-size: em(14);
    }
}


/*
  .code-clause-view-all
---------------------------------------
*/

.code-clause-view-all {
    @extend .gustan-bold;

    padding: 0 em(9) 0 0;
    margin: 0 0 em(59);
    text-align: right;
}

.code-clause-view-all .icon-angle-right::before {
    margin-right: .5em;
    font-size: em(18);
    color: $mbiebuilding_blue_3;
}

@media print, screen and (min-width: $mobile_min) {
    .code-clause-view-all {
        padding: em(12, 15) em(9, 15) 0;
        border-top: 1px solid $mbiebuilding_gray_2;
        margin: em(-18, 15) 0 em(70, 15);
        font-size: em(15);
    }

    .code-clause-view-all.alt {
        margin-top: em(18, 15);
    }
}

@media print, screen and (min-width: $tablet_min) {
    .code-clause-view-all.right-col {
        padding-right: em(9, 15);
        border-top: 0;
        margin-top: em(34, 20);
    }
}

/*
  .block-news-item
---------------------------------------
*/

.block-news-item {
    position: relative;
    min-height: em(100);
    overflow: hidden;
    padding-bottom: em(9, 16);
    margin-bottom: em(30, 16);
}

.block-news-item a {
    text-decoration: none;
}

.block-news-item a:hover,
.block-news-item a:focus {
    text-decoration: none;
}

.block-news-item a .image {
    position: relative;
    float: none;
    width: 100%;
    margin-bottom: em(15, 16);
}

.block-news-item a .image img {
    width: 100%;
    height: auto;
    max-width: none;
}

/* Icon version faux image */

.block-news-item .image .icon {
    @include transition(all, .2s, ease-in-out);

    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    width: 100%;
    font-size: 600%;
    line-height: 1.75;
    color: tint($mbiebuilding_gray_2, 50%);
    text-align: center;
    //-webkit-transform: translateY(-50%);
    //-moz-transform: translateY(-50%);
    //-ms-transform: translateY(-50%);
    //-o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.block-news-item a:hover .image .icon,
.block-news-item a:focus .image .icon {
    color: $mbiebuilding_blue_3;
}

@media print, screen and (min-width: $mobile_min) and (max-width: 899px) {
    .block-news-item .image .icon {
        font-size: 400%;
    }
}

.block-news-item .icon.video {
    height: 100%;
    background: url($img-path + 'icons/icon-video.svg') no-repeat center center;
    background-size: auto 50%;
    color: #fff;
}

.block-news-item a:hover .icon.video {
    background-size: auto 60%;
}

.block-news-item .icon.publication::before {
    content: $icon-document;
}

.block-news-item .icon.product::before {
    content: $icon-tool;
}

.block-news-item .icon.guidance::before {
    content: $icon-document2;
}

.block-news-item .icon.article::before {
    content: $icon-document2;
}

.block-news-item .icon.asvm::before {
    content: $icon-document;
}

.block-news-item .icon.determination::before {
    content: $icon-document;
}

.block-news-item .icon.subsection::before {
    content: $icon-document2;
}

.block-news-item .icon.website::before {
    content: $icon-external;
}

.block-news-item a .liner .title {
    @extend .gustan-light;

    padding-bottom: em(14, 25);
    margin-top: 0;
    margin-bottom: 0;
    font-size: em(25);
    line-height: lh(29, 25);
    color: $mbiebuilding_blue_1;
    text-decoration: none;
}

.block-news-item a:hover .title,
.block-news-item a:focus .title {
    text-decoration: underline;
}

.block-news-item a .liner .date {
    @extend .gustan-black;

    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: em(20, 12);
    font-size: em(12);
    line-height: lh(20, 12);
    color: $mbiebuilding_body;
    text-decoration: none;
    text-transform: uppercase;
}

.block-news-item a:hover .date,
.block-news-item a:focus .date {
    color: $mbiebuilding_body;
    text-decoration: none;
}

.block-news-item a .description {
    padding-top: em(19, 16);
    border-top: 1px solid $mbiebuilding_gray_3;
}

.block-news-item a .description p {
    font-size: em(14);
    line-height: 1.43;
    color: $mbiebuilding_gray_4;
    text-decoration: none;
}

.block-news-item a:hover .description p,
.block-news-item a:focus .description p {
    color: $mbiebuilding_gray_4;
    text-decoration: none;
}

.block-news-item a .description .faux-link {
    color: $mbiebuilding_blue_1;
    text-decoration: none;
}

.block-news-item a:hover .description .faux-link,
.block-news-item a:focus .description .faux-link {
    color: $mbiebuilding_blue_1;
    text-decoration: underline;
}

.block-news-item .faux-link .icon {
    margin-right: .25em;
}

/* News blocks on landing pages (tablet variation) */
@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .block-landing-news-row .block-news-item .image {
        float: left;
        width: 47.75%;
        margin-right: 4.5%;
    }

    .block-landing-news-row .block-news-item.with-image .liner {
        float: left;
        width: 47.75%;
    }
}
