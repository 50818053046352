.article-tags {
    margin-top: rem(40);
    margin-bottom: rem(24);

    h2 {
        @extend .gustan-bold;

        padding-top: rem(13);
        padding-bottom: 0;
        border-top: rem(1) solid $mbiebuilding_gray_3;
        border-bottom: 0;
        margin-bottom: rem(10);
        font-size: rem(14);
    }

    @media (min-width: $tablet_min) {
        margin-top: rem(50);
        margin-bottom: 0;

        h2 {
            margin-top: rem(16);
        }
    }
}
