@charset "UTF-8";
@use 'sass:math';
@import 'tools',
    'tools/normalize',
    'tools/colour-classes',
    'tools/editor-styles';

/*
 FONTS
-------------------------------------
*/


/* Gustan Light */

.gustan-light,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
#mc_embed_signup ul label,
.caption-container,
.site-search .search-menu-results .section,
.intro-text,
.button,
.page-footer li,
.block-referral,
#mobile-menu-container li,
.block-document-intro p,
.button-more-1,
.nav-document-extras p,
.nav-in-this-document p {
    font-family: $font-family-light;
    font-weight: 200;
    font-style: normal;
}

/* Gustan Book */

.gustan-book,
.block-notification .description,
.nav-main li {
    font-family: $font-family-book;
    font-weight: 300;
    font-style: normal;
}

/* Gustan Bold */

.gustan-bold,
form .button-dark-blue,
.search-filter .filter-reset,
th,
.tip-box .title,
.mobile-search span,
.mobile-menu,
.site-search .search-menu-results .title,
.block-notification .title,
.block-notification .description a,
.nav-tertiary li,
.page-footer h3,
.nav-footer.last li,
.nav-secondary li,
.nav-secondary .title,
.link-back-to-top,
#mobile-menu-container .back-link,
.nav-document-extras .title,
.nav-in-this-document .title,
.nav-secondary li strong {
    font-family: $font-family-bold;
    font-weight: 500;
    font-style: normal;
}

/* Gustan Black */

.gustan-black,
.page-footer h2 {
    font-family: $font-family-black;
    font-weight: 700;
    font-style: normal;
}

/* Fira Sans Light */

.fira-sans-light {
    font-family: $font-family-san-light;
    font-weight: 300;
    font-style: normal;
}

/* Fira Sans Regular */

.fira-sans-regular,
body,
.block-document-intro .js-reveal p {
    font-family: $font-family-san-regular;
    font-weight: 400;
    font-style: normal;
}

/* Fira Sans Medium */

.fira-sans-medium,
strong {
    font-family: $font-family-san-medium;
    font-weight: 500;
    font-style: normal;
}

/* Font icons */

[class^='icon-']::before,
[class*=' icon-']::before,
.select2-selection__clear::before,
.fancybox-close::before,
#content a:not([data-no-css-external-icon])[href^='https://']::after,
#content a:not([data-no-css-external-icon])[href^='http://']::after,
.nav-pagination .previous::before,
.nav-pagination .next::before,
.nav-stepped-anchors li::after,
.block-landing-3-row .tip-box a::before,
.enlarge-image a::before,
.block-landing-3-row li a::before,
.mobile-menu::before,
.js-scroll-trigger .icon,
.select2-selection__arrow::before,
.nav-main li a::after,
.nav-breadcrumb li a::before,
.nav-breadcrumb li .inactive::before,
.button-more-1::before,
.nav-breadcrumb li:first-child a::before,
#mobile-menu-container li a::after,
#mobile-menu-container .back-link a::before,
.nav-secondary li a::before,
.nav-tertiary li a::before,
.nav-main li a span::after,
.nav-mega ul.mega-nav-secondary li a::before,
.block-campaign-widget .block-campaign-widget-item a .description .faux-link::before,
.block-news-item a .image .icon::before,
.nav-secondary.collapsible .parent::before {
    display: inline-block;
    font-family: $font-mbie-building-icon;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    text-transform: none;
    speak: none;
    font-variant: normal;
}


/*
 LAYOUT
-------------------------------------
*/

body {
    background-color: $mbiebuilding_gray_1;
    color: $mbiebuilding_body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Shell */

.layout-row {
    clear: both;
    overflow: hidden;
}

.page-wrapper {
    position: relative;
    max-width: em(1150);
    margin: 0 auto;
    text-align: left;
}

.page-maincontent .page-wrapper {
    padding: 0 rem(20);
}

.content-wrapper {
    max-width: em(963);
    margin: 0 auto;
}

.richtext a:not(.button),
.js-table-wrapper a:not(.button) {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
        text-shadow: 0 0 .5px $mbiebuilding_blue_1;
    }
}

.page-maincontent.white {
    background: $mbiebuilding_white_1;
}

.page-maincontent.blue-6 {
    background: $mbiebuilding_blue_6;
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .page-maincontent .page-wrapper {
        padding: 0 em(30);
    }
}

@media only screen and (min-width: $tablet_min) {
    .page-maincontent .page-wrapper {
        padding: 0 em(30);
    }

    /* Columns */

    .layout-col-1,
    .layout-col-2,
    .layout-col-3,
    .layout-col-4,
    .layout-col-5,
    .layout-col-6,
    .layout-col-7,
    .layout-col-8,
    .layout-col-9 {
        float: left;
        margin-right: $margin_right;
    }

    .layout-col-1 {
        width: math.div((100% - ($margin_right*11)), $total_cols);
    }

    .layout-col-2 {
        width: math.div((100% - ($margin_right*5)), math.div($total_cols, 2));
    }

    .layout-col-3 {
        width: math.div((100% - ($margin_right*3)), math.div($total_cols, 3));
    }

    .layout-col-4 {
        width: math.div((100% - ($margin_right*2)), math.div($total_cols, 4));
    }

    .layout-col-5 {
        width: math.div((100% - ($margin_right)), math.div($total_cols, 5));
    }

    .layout-col-6 {
        width: math.div((100% - ($margin_right)), math.div($total_cols, 6));
    }

    .layout-col-7 {
        width: math.div((100% - ($margin_right)), math.div($total_cols, 7));
    }

    .layout-col-8 {
        width: math.div((100% - ($margin_right)), math.div($total_cols, 8));
    }

    .layout-col-9 {
        width: math.div((100% - ($margin_right)), math.div($total_cols, 9));
    }

    .layout-col-10 {
        width: math.div((100% - ($margin_right)), math.div($total_cols, 10));
    }

    .layout-col-11 {
        width: math.div((100% - ($margin_right)), math.div($total_cols, 11));
    }

    .layout-row .layout-col:last-child,
    .layout-row .layout-col.last {
        margin-right: 0;
    }

    .layout-row .layout-col.last {
        margin-right: 0;
    }

    .layout-col.main-right {
        float: right;
        width: 66%;
        margin-right: 0;
    }

    .layout-col.aside-left {
        margin-right: 0;
    }

    /* Offset */

    .layout-offset-1 {
        margin-left: (math.div((100% - ($margin_right*11)), $total_cols) +$margin_right) *1;
    }

    .layout-offset-2 {
        margin-left: (math.div((100% - ($margin_right*11)), $total_cols) +$margin_right) *2;
    }

    .layout-offset-3 {
        margin-left: (math.div((100% - ($margin_right*11)), $total_cols) +$margin_right) *3;
    }

    .layout-offset-4 {
        margin-left: (math.div((100% - ($margin_right*11)), $total_cols) +$margin_right) *4;
    }
}


/* Links */

a {
    color: $mbiebuilding_blue_1;
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    text-decoration: underline;
}

#content a:not([data-no-css-external-icon])[href^='https://'],
#content a:not([data-no-css-external-icon])[href^='http://'] {
    &::after {
        position: relative;
        top: -.2em;
        content: '\e81a';
        margin-right: .5em;
        margin-left: .5em;
        font-size: em(11);
        color: $mbiebuilding_blue_1;
    }

    &.button:hover::after {
        color: #fff;
    }
}

/* reset */

#content .enlarge-image a::after,
.sharing-popup a::after,
#content .block-referral .image a::after {
    display: none !important;
}

/*
 HEADER
---------------------------------------
*/

.page-header {
    @include box-shadow(0, 1px, 10px, 0, rgba(0, 0, 0, .4));

    position: relative;
    background: #fff no-repeat right top;
    background-size: 252px auto;
}

.page-header a {
    text-decoration: none;
}

.page-header .home-logo {
    display: block;
    float: left;
    width: em(124);
    height: em(37);
    margin: em(48) 0 em(36) em(16);
    background: url($img-path + 'logos/mbie-building-logo.png') no-repeat 0 0;
    background-size: contain;
    text-indent: -999em;
}

@media only screen and (min-width: $tablet_min) {
    .page-header .page-wrapper {
        position: static;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 em(30);
    }

    .page-header .home-logo {
        width: em(189);
        height: em(56);
        margin: em(45) 0 em(20) em(4);
    }
}

@media only screen and (min-width: $desktop_min) {
    .page-header .page-wrapper {
        padding: 0 em(20);
    }

    .page-header .home-logo {
        width: em(225);
        height: em(67);
        margin: em(51) 0 em(31) 0;
    }
}


/*
 HEADER - THIRD PARTY
---------------------------------------
*/

.third-party-template .nav-main-link-back {
    display: block;
    clear: both;
    margin: 0 0 em(20) em(14);
    color: $mbiebuilding_body;
}

.third-party-template .nav-main-link-back:hover,
.third-party-template .nav-main-link-back:focus {
    color: $mbiebuilding_blue_1;
}

.third-party-template .nav-main-link-back .icon {
    margin-right: .5em;
    color: $mbiebuilding_blue_3;
}

.third-party-template .home-logo {
    margin-bottom: em(20);
}

@media only screen and (min-width: $desktop_min) {
    .third-party-template .home-logo {
        margin-bottom: em(20);
    }

    .third-party-template .nav-main-link-back {
        margin: 0 0 em(20) 0;
    }
}


/*
 PRIMARY MENU
---------------------------------------
*/

.nav-main a {
    outline: none;
    color: #000;
}

.nav-main li {
    display: block;
    float: left;
    width: 33.3%;
    font-size: em(15);
    line-height: 1.3;
}

.nav-main li a {
    display: none;
    padding: em(10, 15) em(10, 15);
    background: #fff;
    text-align: center;
}

.nav-main .child {
    display: none;
}

@media only screen and (min-width: $tablet_min) {
    .nav-main {
        clear: both;
        max-width: em(1200);
        margin: 0;
        margin: 0 auto;
    }

    .nav-main .home {
        display: none;
    }

    .nav-main li {
        font-size: em(18);
        letter-spacing: .01em;
    }

    .nav-main li a {
        display: block;
    }

    .nav-main li .first-level {
        position: relative;
        padding: em(12, 18) em(23, 18) em(15, 18) em(24, 18);
        text-align: center;
    }

    .nav-main li.section-1 .first-level {
        padding-left: em(31, 18);
    }

    .nav-main li .first-level span::after {
        content: '\e806';
        margin-left: em(5, 18);
        color: rgba($mbiebuilding_blue_1, .5);
    }

    .nav-main .first-level:hover,
    .nav-main .first-level:active,
    .nav-main .first-level:focus,
    .nav-main .active .first-level {
        @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_1);

        color: #fff;
    }

    .nav-main .first-level:hover span::after,
    .nav-main .first-level:active span::after,
    .nav-main .first-level:focus span::after,
    .nav-main .active .first-level span::after {
        color: #fff;
    }

    .nav-main .active .first-level span::after {
        content: '\e805';
    }

    .nav-main .current .first-level::after,
    .nav-main .section .first-level::after {
        @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_1);

        position: absolute;
        right: em(24, 18);
        bottom: 0;
        left: em(23, 18);
        content: '';
        height: 4px;
    }

    .nav-main .current .first-level span::after,
    .nav-main .section .first-level span::after {
        color: $mbiebuilding_blue_1;
    }
}

@media only screen and (min-width: $tablet_min) and (max-width: $desktop_max) {
    .nav-main li {
        width: 20%;
    }

    .nav-main li .first-level {
        min-height: em(47, 18);
        padding-right: em(15, 18);
        padding-left: em(15, 18);
        text-align: left;
    }

    .nav-main li .first-level span {
        white-space: nowrap;
    }

    .nav-main .current .first-level::after,
    .nav-main .section .first-level::after {
        right: em(15, 18);
        left: em(15, 18);
    }
}

@media only screen and (min-width: $desktop_min) {
    .nav-main li {
        width: auto;
    }

    .nav-main li.section-1 .first-level {
        padding-left: em(24, 18);
    }

    .nav-main li .first-level {
        padding-top: em(15, 18);
    }
}

/*
 MEGA NAV MENU
---------------------------------------
*/

.nav-mega {
    display: none;
}

@media only screen and (max-width: $tablet_max) {
    .nav-mega {
        display: none !important;
    }
}

@media only screen and (min-width: $tablet_min) {
    .nav-main .nav-mega {
        position: absolute;
        left: 0;
        z-index: 4;
        width: 100%;
        height: auto;
        background: #fff;
        font-size: em(16, 18);
    }
}


/*
 MOBILE MENU
---------------------------------------
*/

.mobile-menu {
    position: absolute;
    top: em(40);
    right: em(21);
    display: block;
    width: em(42);
    height: em(42);
    background: #fff;
    color: $mbiebuilding_body;
    text-align: center;
}

.mobile-menu::before {
    position: relative;
    content: '\e82e';
    margin: em(7) 0 em(1);
}

.mobile-menu span {
    display: block;
    font-size: em(11);
}

@media only screen and (min-width: $tablet_min) {
    .mobile-menu {
        display: none;
    }
}

/* Mobile menu container */

#mobile-menu-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: none;
    background: rgba($mbiebuilding_blue_1, .97);
}

.mobile-menu-active {
    position: fixed;
    overflow: hidden;
}

.mobile-menu-active #mobile-menu-container {
    display: block;
}

#mobile-menu-container nav,
#mobile-menu-container .active .child {
    height: 100%;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
}

#mobile-menu-container li {
    position: relative;
    font-size: em(20);
    line-height: 1;
}

#mobile-menu-container li a {
    display: block;
    padding: em(16, 20) em(16, 20) em(17, 20);
    border-bottom: 1px solid rgba($mbiebuilding_blue_2, .5);
    outline: none;
    color: #fff;
}

#mobile-menu-container li a:hover,
#mobile-menu-container li a:active,
#mobile-menu-container li a:focus {
    background-color: shade($mbiebuilding_blue_1, 75%);
}

#mobile-menu-container li a::after {
    position: absolute;
    top: em(13, 26);
    right: em(19, 26);
    content: '\e804';
    font-size: em(26, 20);
    color: $mbiebuilding_blue_3;
}

#mobile-menu-container .current a,
#mobile-menu-container .section a {
    background: shade($mbiebuilding_blue_1, 90%);
}

#mobile-menu-container .current a::after,
#mobile-menu-container .section a::after {
    color: #fff;
}

#mobile-menu-container .home a {
    height: em(35, 20);
    background: url($img-path + 'logos/mbie-building-logo-white.png') no-repeat em(16, 20) em(20, 20);
    background-size: em(112, 20) em(33, 20);
    text-indent: -999em;
}

#mobile-menu-container .home a:hover,
#mobile-menu-container .home a:active,
#mobile-menu-container .home a:focus {
    background-color: transparent;
}

/* Mobile menu - Second level */

#mobile-menu-container .child {
    @include gradient-left-right($mbiebuilding_blue_1, $mbiebuilding_blue_4);

    position: fixed;
    top: em(69);
    right: 0;
    bottom: 0;
    left: 200%;
    z-index: 5;
    font-size: em(16, 20);
    visibility: hidden;
    opacity: 0;
}

#mobile-menu-container .active .child {
    left: 0;
    visibility: visible;
    opacity: 1;
}

#mobile-menu-container .child li {
    font-size: em(18);
    line-height: lh(22, 18);
}

#mobile-menu-container .child li a {
    padding: em(10, 18) em(17, 18);
    border-bottom: none;
}

#mobile-menu-container .child li a:hover,
#mobile-menu-container .child li a:active,
#mobile-menu-container .child li a:focus {
    background-color: shade($mbiebuilding_blue_1, 75%);
}

#mobile-menu-container .current .child a,
#mobile-menu-container .section .child a {
    background-color: transparent;
}

#mobile-menu-container .child .current a {
    background-color: rgba(255, 255, 255, .25);
}

#mobile-menu-container .child .back-link {
    margin-bottom: em(9, 20);
    font-size: em(20);
    line-height: 1;
}

#mobile-menu-container .child .back-link a {
    padding: em(17, 20) em(10, 20) em(18, 20) em(53, 20);
    border-bottom: 1px solid rgba($mbiebuilding_blue_2, .5);
}

#mobile-menu-container .child .back-link a::before {
    position: absolute;
    top: em(1, 50);
    left: em(22, 50);
    content: '\e803';
    font-size: em(50, 20);
    color: $mbiebuilding_blue_3;
}

#mobile-menu-container .nav-mega,
#mobile-menu-container .child li a::after {
    display: none;
}

/* Close */

#mobile-menu-container .menu-close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: em(23, 20);
    font-size: em(20);
    color: #fff;
    cursor: pointer;
}


/*
 SITE SEARCH
---------------------------------------
*/

.site-search input.text {
    @include box-sizing(border-box);

    width: 100%;
    padding: em(11, 18) em(50, 18) em(10, 18) em(15, 18);
    border: 1px solid $mbiebuilding_gray_7;
    border-radius: 0;
    background: rgba(255, 255, 255, .9);
    font-size: em(18);
    line-height: 1.4;
}

.site-search .submit button {
    position: absolute;
    top: 22px;
    right: 3px;
    width: em(46, 30);
    height: em(46, 30);
    min-width: inherit;
    overflow: hidden;
    padding: 0;
    border: none;
    background: url($img-path + 'icons/icon-search.svg') no-repeat center center;
    background-size: 45% auto;
    font-size: em(30);
    color: $mbiebuilding_gray_7;
    text-indent: -999em;
    cursor: pointer;

    &:focus {
        background-color: #005da6;
    }
}

.no-svg .site-search .submit button {
    background-image: url($img-path + 'icons/icon-search-ie.png');
}

.mobile-search,
.site-search-container .logo,
.site-search-container .menu-close {
    display: none;
}

.site-search-container a {
    color: #fff;
}

.site-search-container h2 {
    padding: em(13) em(14);
    border-bottom: 1px solid rgba($mbiebuilding_blue_2, .5);
    margin: 0;
    font-size: em(18);
    line-height: 1;
    color: #fff;
}

.site-search-container .search-menu-results {
    margin: 0;
}

.site-search-container .search-menu-results ol {
    list-style: none;
}

.search-menu-results.ui-menu .ui-menu-item {
    padding: 0;
}

.site-search-container .search-menu-results li a,
.search-menu-results.ui-menu .ui-menu-item a {
    display: block;
    padding: em(14) em(20);
    border-bottom: 1px solid rgba($mbiebuilding_blue_2, .5);
    outline: none;
    color: #fff;
}

.site-search-container .search-menu-results li a:hover,
.site-search-container .search-menu-results li a:focus,
.search-menu-results.ui-menu .ui-state-focus a {
    background-color: rgba($mbiebuilding_blue_2, .5);
    text-decoration: none;
}

@media only screen and (max-width: $tablet_max) {
    .mobile-search {
        position: absolute;
        top: em(40);
        right: em(64);
        display: block;
        width: em(42);
        height: em(42);
        overflow: hidden;
        background: #fff url($img-path + 'icons/icon-search.svg') no-repeat center em(6);
        background-size: 45% auto;
        color: $mbiebuilding_body;
        text-align: center;
    }

    .mobile-search span {
        position: absolute;
        right: 0;
        bottom: em(2, 11);
        left: 0;
        font-size: em(11);
        text-align: center;
    }

    /* Mobile search popup */

    .site-search-container {
        z-index: 5;
        display: none;
        background: rgba($mbiebuilding_blue_1, .97);
        color: #fff;
    }

    .mobile-search-active .site-search-container {
        display: block;
    }

    .site-search-container .logo {
        display: block;
        width: em(190);
        margin: em(20) 0 em(20) em(16);
    }

    .site-search-container .liner {
        height: 100%;
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .site-search-container .site-search {
        position: relative;
        margin: em(27) em(16) em(26);
    }

    .site-search-container .search-menu-results .title,
    .search-menu-results .ui-menu-item .title {
        margin-bottom: em(4);
        line-height: lh(18);
    }

    .site-search-container .search-menu-results .section,
    .search-menu-results .ui-menu-item .section {
        font-size: em(14);
        line-height: lh(18, 14);
    }

    .site-search-container .search-menu-results .js-trigger {
        display: block;
        outline: none;
        font-size: em(34);
        text-align: center;
    }

    .site-search-container .active .js-trigger .icon::before {
        content: '\e805';
    }

    .site-search-container .menu-close {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        padding: em(23, 20);
        font-size: em(20);
        color: #fff;
        cursor: pointer;
    }

    .site-search-container.autocomplete-active .search-menu-results {
        display: none;
    }

    .mobile-search-active .ui-autocomplete {
        right: 0 !important;
        left: 0 !important;
        width: 100% !important;
    }
}

@media only screen and (min-width: $tablet_min) {
    .site-search-container {
        width: em(464);
    }

    .site-search .field {
        position: relative;
        width: em(464);
    }

    .site-search-container h2 a {
        display: block;
        padding: em(13, 18) em(15, 18) em(14, 18);
    }

    .site-search-container .search-menu-results {
        position: absolute;
        z-index: 5;
        display: none;
        width: em(464);
        background: rgba($mbiebuilding_blue_1, .97);
        color: #fff;
    }

    .site-search-container.search-active .search-menu-results {
        display: block;
    }

    .site-search-container .search-menu-results .js-reveal {
        display: block !important;
    }

    .site-search-container .search-menu-results .js-trigger {
        display: none;
    }

    .site-search-container .search-menu-results li a,
    .search-menu-results.ui-menu .ui-menu-item a {
        padding: em(14) em(15) em(15);
    }

    .site-search-container .search-menu-results .title,
    .search-menu-results .ui-menu-item .title {
        font-size: em(18);
        line-height: 1;
    }

    .site-search-container .search-menu-results .section,
    .search-menu-results .ui-menu-item .section {
        font-size: em(13);
        line-height: 1;
    }
}


/*
 SECONDARY MENU
---------------------------------------
*/

.nav-secondary {
    padding-bottom: em(44);
    margin: 0 em(-15);
    background: #fff;
}

.nav-secondary .title {
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin: 0;
    font-size: em(20);
    line-height: lh(27, 20);
    color: $mbiebuilding_gray_5;
}

.nav-secondary .title a {
    display: block;
    padding: em(47, 20) em(15, 20) em(22, 20);
    color: $mbiebuilding_gray_5;
}

.nav-secondary a:hover,
.nav-secondary a:active,
.nav-secondary a:focus {
    color: $mbiebuilding_blue_1;
}

.nav-secondary li {
    border-bottom: 1px solid $mbiebuilding_gray_2;
    line-height: lh(23);
}

.nav-secondary li a,
.nav-secondary li strong {
    position: relative;
    display: block;
    padding: em(12) em(17) em(13) em(35);
    background-color: #fff;
    color: $mbiebuilding_gray_3;
    cursor: pointer;
}

.nav-secondary li a:hover,
.nav-secondary li a:active,
.nav-secondary li a:focus,
.nav-secondary li strong:hover {
    background-color: tint($mbiebuilding_blue_3, 10%);
    color: $mbiebuilding_blue_2;
}

.nav-secondary li a::before {
    position: absolute;
    top: em(14);
    left: em(17);
    content: '\e804';
    font-size: em(18);
    color: $mbiebuilding_gray_2;
}

.nav-secondary li a:hover::before,
.nav-secondary li a:active::before,
.nav-secondary li a:focus::before {
    color: $mbiebuilding_blue_4;
}

.nav-secondary .current a {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

    color: #fff;
}

.nav-secondary .current a::before {
    color: #fff;
}

.nav-secondary .current a:hover,
.nav-secondary .current a:active,
.nav-secondary .current a:focus {
    color: #fff;
}

/* Children */

.nav-secondary .child li {
    border: none;
}

.nav-secondary .child li a,
.nav-secondary .child li strong {
    padding: em(8) em(17) em(12) em(55);
    background: $mbiebuilding_gray_1;
    color: $mbiebuilding_gray_3;
}

.nav-secondary .child li a::before {
    top: 10px;
    left: 41px;
    color: $mbiebuilding_gray_2;
}

.nav-secondary .child li a:hover,
.nav-secondary .child li a:active,
.nav-secondary .child li a:focus {
    background: none;
    color: $mbiebuilding_blue_2;
}

.nav-secondary .child .current a {
    background-color: $mbiebuilding_gray_1;
    color: $mbiebuilding_body;
}

.nav-secondary .current .child a {
    background: $mbiebuilding_gray_1;
}


@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .nav-secondary {
        margin: 0 em(-30);
    }
}

@media only screen and (min-width: $tablet_min) {
    .nav-secondary {
        max-width: em(294);
        margin: em(24) 0 0;
    }

    .nav-secondary .title a {
        padding: em(18, 20) em(10, 20) em(18, 20) em(26, 20);
    }

    .nav-secondary li {
        font-size: em(14);
        line-height: 1.43;
    }

    .nav-secondary li a,
    .nav-secondary li strong {
        padding: em(12, 14) em(17, 14) em(12, 14) em(45, 14);
    }

    .nav-secondary li a::before {
        top: em(11, 20);
        left: em(27, 20);
        font-size: em(20, 14);
    }

    .nav-secondary .child li {
        font-size: inherit;
        line-height: inherit;
    }

    .nav-secondary .child li a,
    .nav-secondary .child li strong {
        padding: em(9, 14) em(17, 14) em(14, 14) em(69, 14);
    }

    .nav-secondary .child li a::before {
        top: 8px;
        left: 53px;
    }
}

/* Collapsible version */

.js-enabled .nav-secondary.collapsible .child {
    display: none;
}

.js-enabled .nav-secondary.collapsible .active .child {
    display: block;
}

.js-enabled .nav-secondary.collapsible .active li .child {
    display: none;
}

.js-enabled .nav-secondary.collapsible li .active .child {
    display: block;
}

#content .nav-secondary.collapsible li ul {
    border-top: 2px solid $mbiebuilding_gray_2;
    border-bottom: 1px solid $mbiebuilding_gray_2;
}

#content .nav-secondary.collapsible li li ul {
    border-top: 1px solid $mbiebuilding_gray_2;
    border-bottom: none;
}

#content .nav-secondary.collapsible li {
    border-bottom: 1px solid $mbiebuilding_gray_2;
}

#content .nav-secondary.collapsible .parent {
    position: relative;
}

#content .nav-secondary.collapsible .parent a,
#content .nav-secondary.collapsible .parent strong {
    padding-right: em(45);
    padding-left: em(27);
    color: #000;
}

#content .nav-secondary.collapsible .parent a::before {
    display: none;
}

#content .nav-secondary.collapsible .child li a,
#content .nav-secondary.collapsible .child li strong {
    padding: em(12) em(17) em(13) em(45);
    background: #fff;
    color: $mbiebuilding_gray_3;
}

#content .nav-secondary.collapsible .child li a:hover,
#content .nav-secondary.collapsible .child li a:active,
#content .nav-secondary.collapsible .child li a:focus,
#content .nav-secondary.collapsible .parent a:hover,
#content .nav-secondary.collapsible .child li strong:hover,
#content .nav-secondary.collapsible .parent strong:hover {
    background-color: tint($mbiebuilding_blue_3, 10%);
    color: $mbiebuilding_blue_2;
}

#content .nav-secondary.collapsible .child .child li {
    font-size: em(14);
    font-weight: 400;
}

#content .nav-secondary.collapsible .child .child li a {
    padding-top: em(12, 14);
    padding-bottom: em(12, 14);
    padding-left: em(60, 14);
}

#content .nav-secondary.collapsible .child .child li a::before {
    top: 13px;
    left: 46px;
    display: inline-block;
}

#content .nav-secondary.collapsible .parent .icon-plus {
    position: absolute;
    top: em(8, 23);
    right: em(19, 23);
    z-index: 1;
    padding: 0;
    background-color: transparent;
    font-size: em(23);
    color: $mbiebuilding_gray_4;
    pointer-events: none;
    cursor: pointer;
}

#content .nav-secondary.collapsible .parent .icon-plus.js-link {
    pointer-events: auto;
}

#content .nav-secondary.collapsible .parent .child .icon-plus {
    top: em(7, 23);
}

#content .nav-secondary.collapsible .parent .icon-plus:hover {
    background-color: transparent;
    color: $mbiebuilding_blue_4;
}

#content .nav-secondary.collapsible .active .icon-plus {
    @extend .icon-minus;

    color: $mbiebuilding_blue_4;
}

#content .nav-secondary.collapsible .active .parent .icon-plus {
    @extend .icon-plus;

    color: $mbiebuilding_gray_4;
}

#content .nav-secondary.collapsible .active .active.parent .icon-plus {
    @extend .icon-minus;

    color: $mbiebuilding_blue_4;
}

#content .nav-secondary.collapsible .current,
#content .nav-secondary.collapsible .child li .current {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

    color: #fff !important;
}

@media only screen and (min-width: $tablet_min) {
    #content .nav-secondary.collapsible li {
        font-size: 1em;
    }

    #content .nav-secondary.collapsible .parent .icon-plus,
    #content .nav-secondary.collapsible .parent .child .icon-plus {
        top: em(9, 23);
    }

    #content .nav-secondary.collapsible .child .child li a::before {
        top: 12px;
    }
}


/*
 BREADCRUMB MENU
---------------------------------------
*/

.nav-breadcrumb .page-wrapper {
    position: relative;
    padding: em(23) em(11) em(30) em(46);
}

.nav-breadcrumb li {
    display: inline-block;
    font-size: em(14);
    line-height: lh(25, 14);
}

.nav-breadcrumb li a,
.nav-breadcrumb li .inactive {
    display: inline-block;
    padding: 0 em(5, 14) 0 0;
    color: $mbiebuilding_gray_6;
    text-decoration: none;
}

.nav-breadcrumb li a:hover span,
.nav-breadcrumb li a:active span,
.nav-breadcrumb li a:focus span {
    text-decoration: underline;
}

.nav-breadcrumb li:first-child {
    position: absolute;
    top: em(23, 14);
    left: em(16, 14);
}

.nav-breadcrumb li:first-child a {
    text-decoration: none;
}

.nav-breadcrumb li:first-child a::before {
    content: '\e80a';
    font-size: em(19, 14);
}

.nav-breadcrumb li:first-child span {
    display: none;
}

.nav-breadcrumb li a::before,
.nav-breadcrumb li .inactive::before {
    content: '\e804';
    margin-right: em(8, 12);
    font-size: em(12, 14);
}

.nav-breadcrumb li:last-child a::after,
.nav-breadcrumb li.last a::after {
    display: none;
}

/* Collapsible version */

.nav-breadcrumb .js-expand {
    display: none;
}

@media only screen and (max-width: $mobile_max) {
    .nav-breadcrumb .inactive {
        display: none;
    }

    .nav-breadcrumb .js-expand {
        display: inline-block;
    }

    .nav-breadcrumb .js-expand a {
        @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, .4));

        padding: 0 em(6, 14) 0 em(14, 14);
        margin: 0 em(5, 14) 0 em(12, 14);
        background: #fff;
        line-height: 1.4;
        letter-spacing: .1em;
    }

    .nav-breadcrumb .js-expand a::before {
        margin: 0 em(15, 14) 0 em(-27, 14);
    }
}

@media print, screen and (min-width: $mobile_min) {
    .nav-breadcrumb .page-wrapper {
        padding: em(22) 0 em(28) em(60);
    }

    .nav-breadcrumb li:first-child {
        left: em(29, 14);
    }
}

@media only screen and (min-width: $tablet_min) {
    .nav-breadcrumb .page-wrapper {
        padding: em(22) em(20) em(30);
    }

    .nav-breadcrumb ul {
        margin-left: em(29);
    }

    .nav-breadcrumb li {
        font-size: em(12);
        line-height: lh(25, 12);
    }

    .nav-breadcrumb li a,
    .nav-breadcrumb li .inactive {
        padding-right: em(5, 12);
    }

    .nav-breadcrumb li:first-child {
        top: em(23, 12);
        left: em(20, 12);
    }

    .nav-breadcrumb li:first-child a::before {
        font-size: em(19, 12);
    }
}


/*
 GENERAL CONTENT
---------------------------------------
*/

/* Main content */

h1,
.h1 {
    margin: 0 0 em(29, 32);
    font-size: em(32);
    line-height: 1.4em;
    color: $mbiebuilding_gray_5;
    letter-spacing: -.025em;
}

h2,
.h2 {
    clear: both;
    margin: em(25, 30) 0 em(15, 30);
    font-size: em(30);
    line-height: lh(33, 30);
    color: $mbiebuilding_gray_5;
    letter-spacing: -.025em;
}

h3,
.h3 {
    margin: em(25, 22) 0 em(8, 22);
    font-size: em(22);
    line-height: lh(25, 22);
    color: $mbiebuilding_gray_5;
    letter-spacing: -.025em;
}

h4,
.h4 {
    margin: 0 0 em(4, 18);
    font-size: em(18);
    line-height: lh(22, 18);
}

h5,
.h5,
h6,
.h6 {
    margin: 0 0 em(4);
    line-height: lh(22);
}

.content-wrapper h2,
.content-wrapper .h2 {
    padding-bottom: em(17, 25);
    border-bottom: 3px solid $mbiebuilding_gray_7;
    margin: em(49, 25) 0 em(27, 25);
    font-size: em(25);
    line-height: lh(30, 25);
}

.page-maincontent p {
    margin: 0 0 em(17);
    line-height: lh(22);
}

.content-wrapper ul {
    margin: em(7) 0 em(21) em(48);
    list-style: none;
}

.content-wrapper ol {
    margin: em(7) 0 em(21) em(40);
    list-style: none;
    counter-reset: section;
}

.content-wrapper ol > li::before {
    display: inline-block;
    content: counters(section, '.') '. ';
    padding-right: em(4);
    counter-increment: section;
}

/* Overrides via the type  attribute */

.content-wrapper ol[type=a] {
    list-style-type: lower-alpha;
}

.content-wrapper ol[type=i] {
    list-style-type: lower-roman;
}

.content-wrapper ol[type] li::before {
    display: none;
}

.content-wrapper ol ul li::before {
    padding-right: 0;
}

/* reset */

.content-wrapper li {
    position: relative;
    margin: 0 0 em(10, 15);
    font-size: em(15);
    line-height: lh(21, 15);
}

.content-wrapper li ol,
.content-wrapper li ul {
    margin: em(10, 15) 0 0 em(40);
}

.content-wrapper li li ul {
    margin-left: em(40);
    list-style: none;
}

.content-wrapper li li ol {
    margin-left: em(40);
}

.content-wrapper li li {
    font-size: inherit;
    line-height: inherit;
}

.content-wrapper ul li::before {
    @include border-radius(50%);

    position: absolute;
    top: em(6);
    left: em(-19);
    content: '';
    width: em(8);
    height: em(8);
    background: $mbiebuilding_gray_3;
    font-size: em(16, 15);
}

.content-wrapper ul li li::before,
.content-wrapper ol ul li::before {
    background-color: tint($mbiebuilding_gray_3, 50%);
}

.content-wrapper ol.list-alpha {
    list-style: lower-alpha;
}

.content-wrapper ol.list-alpha li::before {
    display: none;
}

.content-wrapper ol.list-roman {
    list-style: lower-roman;
}

.content-wrapper ol.list-roman li::before {
    display: none;
}

.content-wrapper dl {
    overflow: hidden;
    margin: em(6) 0 em(38);
}

.content-wrapper dt {
    float: left;
    clear: left;
    width: 60%;
    padding: em(13) 0 em(19);
    line-height: lh(23);
}

.content-wrapper dt strong {
    font-size: 1.2em;
}

.content-wrapper dd {
    float: left;
    width: 40%;
    padding: em(13) 0 em(19);
    line-height: lh(23);
    text-align: right;
}

/* Blockquotes
blockquote { font-size:em(24); margin:em(25,24) 0 em(34,24); line-height:lh(31,24); }
blockquote p, blockquote li { font-size:1em; margin:0; padding:0; line-height:inherit; }
blockquote ul, blockquote ol { list-style:none; margin:0; padding:0; }
blockquote cite:before { content:"— "; display:inline; }
*/

/* Heading border */

.h1-wrap,
.h2-wrap {
    position: relative;
    overflow: hidden;
}

.h1-wrap::before,
.h2-wrap::before {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    height: em(4);
}

/* Safari */

/* Intro text */

.intro-text {
    margin: rem(25) 0 rem(30);
    font-size: rem(20);
    line-height: lh(24, 20);
    color: $mbiebuilding_gray_5;
    letter-spacing: -#{rem(.5)};
}

.page-maincontent .intro-text p,
.page-maincontent .intro-text li {
    font-size: inherit;
    line-height: inherit;
}

.page-maincontent .intro-text p:last-of-type,
.page-maincontent .intro-text li {
    margin: 0;
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    h1,
    .h1 {
        margin: 0 0 em(29, 42);
        font-size: em(42);
    }

    h2,
    .h2 {
        margin: em(25, 34) 0 em(15, 34);
        font-size: em(34);
        line-height: lh(39, 34);
    }

    .content-wrapper h2,
    .content-wrapper .h2 {
        padding-bottom: em(17, 34);
        margin: em(49, 34) 0 em(27, 34);
        font-size: em(34);
        line-height: lh(39, 34);
    }
}

@media only screen and (min-width: $tablet_min) {
    h1,
    .h1 {
        margin: 0 0 em(35, 52);
        font-size: em(52);
    }

    h2,
    .h2 {
        margin: em(25, 36) 0 em(15, 36);
        font-size: em(36);
        line-height: 1.12;
    }

    h3,
    .h3 {
        margin: em(25, 25) 0 em(8, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }

    .content-wrapper h2,
    .content-wrapper .h2 {
        padding-bottom: em(17, 36);
        margin: em(49, 36) 0 em(27, 36);
        font-size: em(36);
        line-height: lh(40, 36);
    }

    .page-maincontent p {
        margin: 0 0 em(17, 15);
        font-size: em(15);
        line-height: lh(21, 15);
    }

    .content-wrapper li {
        font-size: em(15);
        line-height: lh(21, 15);
    }

    .intro-text {
        margin: em(34, 25) 0 em(29, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }

    .h1-wrap {
        margin-top: em(15);
    }

    /*  this is overruling some styles on the 'building-code-' pages, Bvl
  .layout-col.main-right .h1-wrap { padding-top:em(14); }
*/
}

#content .posted {
    margin: em(5, 12) 0 0 0;
    font-size: em(12, 16);
    line-height: lh(25, 12);
    color: $mbiebuilding_gray_5;
    text-align: right;
}


/*
 CUSTOM WYSIWYG CHECKLISTS
---------------------------------------
*/

#content .building-checklist,
#content .building-checklist ul {
    list-style: none;
}

#content .building-checklist li {
    line-height: 1.4;
}

#content .building-checklist li::before {
    @include border-radius(2px);

    position: absolute;
    top: em(2);
    left: em(-28);
    content: '' !important;
    width: em(15);
    height: em(15);
    padding: 0 !important;
    border: 2px solid $mbiebuilding_gray_2;
    background: #fff;
}

#content .building-checklist-note {
    padding-left: 10px !important;
    border-left: 5px solid $mbiebuilding_gray_3;
    margin: 1.5em 0 !important;
    color: $mbiebuilding_gray_4;
}


/*
 IMAGES
---------------------------------------
*/

.page-maincontent img {
    display: block;
    height: auto;
    max-width: 100%;
}

/* Inline content images (Silverstripe) */

.captionImage {
    margin: em(24) 0;
}

.captionImage.left,
.captionImage.right,
img.left,
img.right {
    max-width: 100%;
}

.figure img,
.captionImage img,
.intro-image img {
    display: block;
    width: 100%;
}

.content-wrapper .caption-container {
    display: block;
    padding: em(8, 14) 0 em(14, 14) em(2, 14);
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin: 0;
    font-size: em(14);
    line-height: lh(19, 14);
    color: $mbiebuilding_gray_6;
}

.content-wrapper .caption {
    display: block;
    font-size: inherit;
    line-height: inherit;
    color: $mbiebuilding_gray_6;
}

.content-wrapper .credit {
    display: block;
    color: $mbiebuilding_gray_4;
}

/* Enlarge image */

.enlarge-image a {
    position: relative;
    display: block;
    text-decoration: none;
}

.enlarge-image a::before {
    position: absolute;
    right: em(3, 20);
    bottom: em(16, 20);
    content: '\e808';
    font-size: em(20);
    color: $mbiebuilding_gray_7;
}

.enlarge-image a:hover::before,
.enlarge-image a:focus::before {
    color: $mbiebuilding_blue_1;
}

.enlarge-image a:hover img,
.enlarge-image a:focus img {
    opacity: .75;
}

.enlarge-image a .caption-container {
    padding-right: em(30, 14);
    margin-top: em(-45, 14);
}

.enlarge-image a img {
    padding-bottom: em(45);
}

/* Intro image */

.intro-image {
    position: relative;
    margin: em(30) 0 0;
}

.intro-image img {
    display: block;
}

@media print, screen and (min-width: $mobile_min) {
    .figure.align-left,
    .figure.align-right,
    .captionImage.left,
    .captionImage.right {
        max-width: em(362);
    }

    .figure.align-left,
    .captionImage.left,
    img.left {
        float: left;
        width: 50%;
        margin: em(7) em(15) em(10) 0;
    }

    .figure.align-right,
    .captionImage.right,
    img.right {
        float: right;
        width: 50%;
        margin: em(7) 0 em(15) em(20);
    }

    .captionImage.right img.right,
    .captionImage.left img.left {
        float: none;
        width: 100%;
        margin: auto;
    }

    /* reset */

    .intro-image {
        float: right;
        width: 50%;
        margin-bottom: em(30);
        margin-left: em(30);
    }
}

@media only screen and (min-width: $tablet_min) {
    .figure.align-left,
    .captionImage.left,
    img.left {
        margin-top: em(4);
        margin-right: em(30);
    }

    .figure.align-right,
    .captionImage.right,
    img.right {
        margin-top: em(4);
        margin-left: em(30);
    }

    .content-wrapper .caption-container {
        padding: em(9, 12) 0 em(12, 12) em(2, 12);
        font-size: em(12);
        line-height: lh(16, 12);
    }

    .enlarge-image a .caption-container {
        padding-right: em(30, 12);
        margin-top: em(-45, 12);
    }

    .intro-image {
        margin: em(40) 0 em(40) em(40);
    }
}

@media only screen and (min-width: $desktop_min) {
    .intro-image {
        width: em(362);
    }
}

/* Video */

.video iframe,
.captionImage iframe {
    float: left;
    clear: both;
    width: 100%;
}

iframe {
    border: none;
}

/*
 GALLERY MODAL
---------------------------------------
*/

.gallery-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8020;
    display: none;
    background: $mbiebuilding_gray_3;
    color: #fff;
}

.gallery-modal a {
    color: $mbiebuilding_blue_4;
}

.gallery-modal .fancybox {
    display: none !important;
}

/* hide print modal button if present */

/* Image */

.gallery-modal .image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.gallery-modal .image img {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
}

.no-objectfit .gallery-modal .image {
    overflow: hidden;
}

.no-objectfit .gallery-modal .image img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.gallery-modal .image .ajax-spinner {
    height: 100%;
    background-image: url($img-path + 'icons/gallery-spinner.svg');
}

.no-svg .gallery-modal .image .ajax-spinner {
    height: 100%;
    background-image: url($img-path + 'icons/gallery-spinner-ie.png');
}

/* Image details */

.gallery-modal .image-details {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    background: $mbiebuilding_body;
}

.gallery-modal .image-details .title {
    @extend .gustan-light;

    margin: em(16, 22) em(16, 22);
    font-size: em(22);
    line-height: lh(31, 22);
}

.gallery-modal .image-details .caption,
.gallery-modal .image-details .credit {
    @extend .fira-sans-regular;

    margin: em(20, 14) em(16, 14) em(15, 14);
    font-size: em(14);
    line-height: 1.43;
}

.gallery-modal .image-details .caption-container .credit {
    display: none;
}

/* Image footer */

#shell .gallery-modal .page-options {
    display: block !important;
    padding-top: em(12);
    border-top: 1px solid $mbiebuilding_gray_7;
    margin: 0 auto em(9);
    font-size: 90%;
    text-align: left;
}

.gallery-modal .page-options li {
    margin-left: 0 !important;
}

.gallery-modal .page-options li .link {
    color: #fff;
}

.gallery-modal .page-options li .link:hover,
.gallery-modal .page-options li .link:focus,
.gallery-modal .page-options li .link:hover .icon,
.gallery-modal .page-options li .link:focus .icon {
    color: $mbiebuilding_blue_4;
}

.gallery-modal .page-options li .sharing-popup a {
    color: inherit;
}

.gallery-modal .show-image-details {
    position: absolute;
    right: em(17, 20);
    bottom: em(17, 20);
    width: em(29, 20);
    height: em(29, 20);
    overflow: hidden;
    border: 2px solid #fff;
    border-radius: 50%;
    background: $mbiebuilding_gray_3;
    font-size: em(20);
    color: #fff;
    text-align: center;
}

.gallery-modal .show-image-details:hover,
.gallery-modal .show-image-details:focus {
    background: $mbiebuilding_blue_3;
}

/* Close */

.gallery-modal .close-modal {
    position: absolute;
    top: em(17);
    right: em(17);
    z-index: 3;
    width: em(26);
    height: em(26);
    overflow: hidden;
    padding: em(3);
    border-radius: 50%;
    background: #fff;
    font-size: 1em;
    line-height: em(27);
    color: $mbiebuilding_body;
    text-align: center;
    text-decoration: none;
}

.gallery-modal .close-modal:hover,
.gallery-modal .close-modal:focus {
    background: $mbiebuilding_body;
    color: #fff;
}

@media only screen and (max-width: $desktop_max) {
    .gallery-modal .image-details {
        background: rgba($mbiebuilding_body, .8);
    }

    #shell .gallery-modal .page-options .sharing-popup {
        top: em(-68);
        font-size: em(16, 14.4);
    }

    #shell .gallery-modal .page-options .sharing-popup::before {
        @include transform-rotate(180deg);

        top: auto;
        bottom: em(-9);
    }
}

@media print, screen and (min-width: $mobile_min) {
    .gallery-modal .image-details .title {
        margin: em(32, 25) em(16, 25) em(16, 25);
        font-size: em(25);
        line-height: lh(31, 25);
    }

    .gallery-modal .image-details .liner {
        padding-bottom: em(20);
    }
}

@media only screen and (min-width: $desktop_min) {
    .gallery-modal .image {
        width: 69.5%;
    }

    .gallery-modal .image-details {
        top: 0;
        right: 0;
        left: auto;
        width: 30.5%;
    }

    .gallery-modal .image-details .outer-liner {
        display: table;
        height: 100%;
    }

    .gallery-modal .image-details .inner-liner {
        display: table-cell;
        vertical-align: middle;
        padding: 0 em(28);
    }

    .gallery-modal .image-details .liner {
        padding-bottom: 0;
    }

    .gallery-modal .image-details .title {
        padding-bottom: em(27, 25);
        border-bottom: 1px solid $mbiebuilding_gray_7;
        margin: em(16, 25) em(16, 25) em(25, 25) 0;
    }

    .gallery-modal .image-details .caption,
    .gallery-modal .image-details .credit {
        margin-left: 0;
    }

    #shell .gallery-modal .page-options {
        padding-top: em(35);
        margin-top: em(25);
        font-size: 100%;
    }

    .gallery-modal .show-image-details {
        display: none;
    }
}


/*
 GENERIC MODAL (eg. print)
---------------------------------------
*/

.mbie-modal {
    display: none;
    text-align: center;
}

.mbie-modal h2 {
    padding: em(27, 30) 0 em(20, 30);
    margin: 0;
}

.mbie-modal .layout-row {
    position: relative;
    padding: em(12) em(25);
    background: #f1f0ec;
}

.mbie-modal .layout-row::before {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: '';
    height: em(4);
}

.mbie-modal h3 {
    @extend .gustan-book;

    margin: em(25, 22) 0 em(24, 22);
}

.mbie-modal .button {
    margin: em(17) 0 em(21);
}

.mbie-modal .close-modal {
    @extend .gustan-bold;

    display: block;
    padding: em(10);
}

@media print, screen and (min-width: $mobile_min) {
    .mbie-modal h2 {
        padding: em(22, 34) 0 em(25, 34);
        margin: 0;
    }

    .mbie-modal .layout-col-6 {
        @include box-sizing(border-box);

        float: left;
        width: 50%;
        padding: 0 em(20);
        margin-right: 0;
    }

    .mbie-modal .liner.height-adjusted {
        position: relative;
    }

    .mbie-modal h3 {
        margin: em(26, 25) 0 em(27, 25);
        font-size: em(25);
        line-height: 1;
    }

    .mbie-modal .liner.height-adjusted .button {
        position: absolute;
        right: 14%;
        bottom: em(26);
        left: 14%;
        display: block;
        min-width: inherit;
        margin: 0;
    }

    .mbie-modal .close-modal {
        padding: em(10) em(10) em(20);
    }
}

@media only screen and (min-width: $desktop_min) {
    .mbie-modal h2 {
        padding: em(22, 36) 0 em(24, 36);
        margin: 0;
    }

    .mbie-modal .layout-col-6 {
        padding: 0 em(50) em(54);
        margin-right: 0;
    }
}


/*
 TERTIARY NAV
---------------------------------------
*/

.page-articlepage .page-maincontent .nav-tertiary,
.page-guidancereadingpage .page-maincontent .nav-tertiary {
    margin-top: em(40);
}

.page-maincontent .nav-tertiary-heading {
    @extend .gustan-bold;

    padding: 0 0 em(22);
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin: 0 0 em(15);
    font-size: em(16);
    color: $mbiebuilding_body;
}

.page-maincontent .nav-tertiary ul {
    margin: 0 0 em(50);
    list-style: none;
}

.page-maincontent .nav-tertiary li {
    position: relative;
    margin: 0 0 em(10);
    font-size: em(16);
    line-height: lh(22);
}

.page-maincontent .nav-tertiary li::before {
    display: none;
}

.page-maincontent .nav-tertiary li a {
    display: block;
    padding: em(5) 0 em(4) em(48);
}

.page-maincontent .nav-tertiary li a::after {
    display: none !important;
}

.page-maincontent .nav-tertiary .icon {
    position: absolute;
    top: em(5);
    left: em(7);
    color: $mbiebuilding_gray_7;
}

.page-maincontent .nav-tertiary a:hover .icon,
.page-maincontent .nav-tertiary a:focus .icon {
    color: $mbiebuilding_blue_3;
}

@media only screen and (max-width: $mobile_max) {
    .content-wrapper .nav-tertiary {
        margin-top: 1em;
    }
}

@media only screen and (min-width: $mobile_min) {
    .page-maincontent .nav-tertiary ul {
        overflow: hidden;
        margin-bottom: em(30);
    }

    .page-maincontent .nav-tertiary li {
        float: left;
        width: 50%;
    }

    .page-maincontent .nav-tertiary li:nth-child(2n+1) {
        clear: left;
    }

    .page-maincontent .nav-tertiary li.nth-child-2-1 {
        clear: left;
    }
}

@media only screen and (min-width: $tablet_min) {
    .page-maincontent .nav-tertiary ul {
        margin-bottom: em(39);
    }
}


/*
 TERTIARY NAV
---------------------------------------
*/

.nav-stepped-anchors {
    overflow: hidden;
    margin: em(8) 0 0;
}

.nav-stepped-anchors li {
    @extend .gustan-light;

    position: relative;
    margin: em(10, 18) 0 em(47, 18);
    font-size: em(18);
    line-height: em(22, 18);
}

.nav-stepped-anchors li::after {
    position: absolute;
    bottom: em(-17, 14);
    left: 50%;
    content: '\e810';
    margin-left: em(-3, 14);
    font-size: em(14);
    color: $mbiebuilding_blue_3;
}

.nav-stepped-anchors li a {
    display: block;
    overflow: hidden;
    padding: em(16, 18) 24% em(21, 18);
    border: 1px solid $mbiebuilding_blue_1;
    background: #fff;
    text-align: center;
    text-decoration: none;
}

.nav-stepped-anchors li a:hover,
.nav-stepped-anchors li a:active,
.nav-stepped-anchors li a:focus {
    background: $mbiebuilding_blue_1;
    color: #fff;
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .nav-stepped-anchors li {
        float: left;
        width: 47.7%;
        margin-right: 4.4%;
        margin-bottom: em(38, 18);
    }

    .nav-stepped-anchors li:nth-child(2n) {
        margin-right: 0;
    }

    .nav-stepped-anchors li.nth-child-2 {
        margin-right: 0;
    }
}

@media only screen and (min-width: $tablet_min) and (max-width: $desktop_min) {
    .nav-stepped-anchors li a {
        padding-right: 10%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: $tablet_min) {
    .nav-stepped-anchors li {
        float: left;
        width: 23%;
        margin-right: 2.6%;
    }

    .nav-stepped-anchors li:last-child,
    .nav-stepped-anchors li.last {
        margin-right: 0;
    }
}


/*
 JS OVERFLOW HELPER
---------------------------------------
*/

.overflow-liner {
    position: relative;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: hidden;
}

@media only screen and (min-width: $mobile_min) {
    .js-scroll-trigger {
        @include transition(all, .5s, ease-in-out);

        position: absolute;
        top: em(41);
        right: 0;
        z-index: 2;
        display: block;
        width: em(52);
        height: em(50);
        background: #fff;
        visibility: hidden;
        opacity: 0;
    }

    .js-scroll-trigger.active {
        visibility: visible;
        opacity: .5;
    }

    .js-scroll-trigger.active:hover,
    .js-scroll-trigger.active:focus {
        opacity: 1;
    }

    .js-scroll-trigger .scroll-button {
        display: block;
        float: right;
        width: em(42);
        height: em(82);
        margin-top: em(2);
        background: $mbiebuilding_blue_6;
        color: $mbiebuilding_blue_1;
        text-align: center;
        cursor: pointer;
    }

    .js-scroll-trigger .icon {
        padding-left: em(3, 40);
        margin-top: em(18, 40);
        font-size: em(40);
    }

    .js-scroll-trigger .icon::before {
        content: '\e804';
    }

    .js-scroll-trigger:hover .scroll-button,
    .js-scroll-trigger:focus .scroll-button {
        background-color: $mbiebuilding_blue_1;
        color: #fff;
    }

    /* left version */

    .js-scroll-trigger.left .scroll-button {
        float: left;
    }

    .js-scroll-trigger.left {
        right: auto;
        left: 0;
    }

    .js-scroll-trigger.left .icon {
        padding: 0 em(3, 40) 0 0;
    }

    .js-scroll-trigger.left .icon::before {
        content: '\e803';
    }
}

/*
 BACK TO TOP
---------------------------------------
*/

.pinned.link-back-to-top {
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    width: 100%;
}

.link-back-to-top a {
    @include border-radius(em(11, 14) em(11, 14) 0 0);

    position: absolute;
    right: em(15, 14);
    bottom: 0;
    width: em(48, 14);
    height: em(41, 14);
    overflow: hidden;
    border: 1px solid $mbiebuilding_gray_7;
    border-bottom: none;
    background: rgba(255, 255, 255, .9);
    font-size: em(14);
    line-height: 1;
    color: $mbiebuilding_gray_4;
    text-align: center;
}

.link-back-to-top a span {
    display: block;
    margin: em(4, 14) 0 em(2, 14);
    font-size: em(14, 14);
    color: $mbiebuilding_blue_1;
}

.link-back-to-top a:hover,
.link-back-to-top a:active,
.link-back-to-top a:focus {
    border-color: $mbiebuilding_blue_3;
    background: $mbiebuilding_blue_3;
    color: #fff;
    text-decoration: none;
}

.link-back-to-top a:hover span,
.link-back-to-top a:active span,
.link-back-to-top a:focus span {
    color: #fff;
}

@media print, screen and (min-width: $mobile_min) {
    .link-back-to-top a {
        right: em(30, 14);
    }
}

@media only screen and (min-width: $tablet_min) and (max-width: $desktop_max) {
    .link-back-to-top .page-wrapper {
        padding: 0 em(30);
    }
}

@media only screen and (min-width: $desktop_min) {
    .link-back-to-top a {
        right: 0;
    }
}

@media only screen and (min-width: 1400px) {
    .link-back-to-top a {
        right: em(-97, 14);
    }
}


/*
 PAGINATION
---------------------------------------
*/

.nav-pagination {
    margin: 0 0 em(37);
    text-align: center;
}

.nav-pagination ol {
    display: inline-block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    list-style: none;
}

#shell .nav-pagination li {
    @extend .gustan-bold;

    display: block;
    float: left;
    margin: 0 -1px em(5) 0;
    font-size: 1em;
    line-height: 1.3;
}

#shell .nav-pagination li:last-child,
#shell .nav-pagination li.last {
    margin-right: 0 !important;
}

.nav-pagination li a,
.nav-pagination li strong,
.nav-pagination li span {
    display: block;
    padding: em(7) em(13) em(8);
    border: 1px solid $mbiebuilding_blue_1;
    color: $mbiebuilding_blue_1;
}

.nav-pagination li strong {
    @extend .gustan-bold;

    background-color: $mbiebuilding_blue_1;
    color: #fff;
}

.nav-pagination li span {
    padding-right: em(8);
    padding-left: em(8);
    color: $mbiebuilding_gray_7;
}

.nav-pagination li a:hover,
.nav-pagination li a:active,
.nav-pagination li a:focus {
    background-color: $mbiebuilding_blue_3;
    color: #fff;
    text-decoration: none;
}

.nav-pagination li .ellipses {
    border-color: $mbiebuilding_gray_7;
    background: tint($mbiebuilding_gray_2, 20%);
}

@media only screen and (max-width: $mobile_max) {
    .nav-pagination .previous,
    .nav-pagination .next {
        position: relative;
        width: em(27);
        text-indent: -999em;
    }

    .nav-pagination .previous::before {
        left: em(21, 26);
        content: '\e803';
    }

    .nav-pagination .next::before {
        left: em(24, 26);
        content: '\e804';
    }

    .nav-pagination .previous::before,
    .nav-pagination .next::before {
        position: absolute;
        top: em(4, 26);
        font-size: em(26);
        text-indent: 0;
    }
}

@media print, screen and (min-width: $mobile_min) {
    .nav-pagination {
        margin-bottom: em(47);
    }

    #shell .nav-pagination li {
        margin: 0 -1px em(5, 14) 0;
        font-size: em(14);
    }

    .nav-pagination li a,
    .nav-pagination li strong,
    .nav-pagination li span {
        padding: em(7, 14) em(13, 14) em(8, 14);
    }

    .nav-pagination li span {
        padding-right: em(8, 14);
        padding-left: em(8, 14);
    }

    .nav-pagination .previous {
        padding-right: em(25, 14);
        padding-left: em(25, 14);
        margin-right: em(17, 14);
    }

    .nav-pagination .next {
        padding-right: em(25, 14);
        padding-left: em(25, 14);
        margin-left: em(17, 14);
    }
}


@media only screen and (min-width: $tablet_min) {
    .nav-pagination {
        margin-bottom: em(8);
    }
}


/*
 DATA TABLES
---------------------------------------
*/


/*
 MESSAGES
---------------------------------------
*/


/*
 HELP PANELS
---------------------------------------
*/


/*
 GENERIC LINK STYLES
---------------------------------------
*/

.link-back-1 {
    @extend .gustan-bold;

    display: block;
    padding: em(15) 0;
    margin: em(10) 0 0;
    line-height: lh(24);
}

.link-back-1 .icon-left {
    margin-right: .5em;
    color: $mbiebuilding_blue_3;
}

@media only screen and (min-width: $tablet_min) {
    .link-back-1 {
        padding: em(15, 18) 0;
        margin: em(20, 18) 0;
        font-size: em(18);
        line-height: lh(24, 18);
    }
}

/*
 ICONS
---------------------------------------
*/

.ajax-spinner {
    display: block;
    width: 100%;
    height: em(50);
    background: url($img-path + 'icons/gallery-spinner.svg') no-repeat center center;
    background-size: contain;
}

/*
 CONCERTINAS
---------------------------------------
*/

.js-concertina {
    margin: em(25) 0;
}

.js-enabled .js-concertina .js-reveal {
    display: none;
}

.js-enabled .js-concertina.active .js-trigger .icon-plus::before,
.js-enabled .js-trigger-all.active .icon-plus::before {
    content: '\e816';
}

.expand-item {
    text-align: center;
}

.js-trigger.expand-item,
.expand-item .js-trigger {
    @extend .gustan-bold;

    display: inline-block;
    padding: em(8) em(48);
    outline: none;
    text-align: center;
    white-space: nowrap;
}

.active .js-trigger.expand-item,
.active .expand-item .js-trigger {
    background-color: $mbiebuilding_blue_6;
}

.js-trigger .icon-plus {
    margin-right: .25em;
}

@media only screen and (min-width: $mobile_min) {
    .expand-item {
        text-align: right;
    }
}

/*
 BUTTONS
---------------------------------------
*/

/* More button - style 1 */

.button-more-1 {
    display: block;
    padding: em(11, 18) em(20, 18) em(12, 18);
    outline: none;
    font-size: em(18);
    line-height: lh(24, 18);
    text-align: center;
    text-decoration: none !important;
}

.button-more-1:hover,
.button-more-1:focus {
    color: $mbiebuilding_blue_3;
}

.button-more-1::before {
    content: '\e81e';
    margin-right: em(9);
    font-size: em(16, 18);
    color: $mbiebuilding_blue_3;
}

.active .button-more-1::before {
    content: '\e816';
}

@media only screen and (min-width: $mobile_min) {
    .button-more-1 {
        text-align: right;
    }
}


/*
 MODALS
---------------------------------------
*/

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
    vertical-align: top;
    padding: 0;
    border: 0;
    outline: none;
    margin: 0;
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}

.fancybox-skin {
    position: relative;
    background: #f9f9f9;
    color: #444;
}

.fancybox-opened {
    z-index: 8030;
}

.fancybox-outer,
.fancybox-inner {
    position: relative;
}

.fancybox-inner {
    overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}

.fancybox-error {
    padding: 15px;
    margin: 0;
    color: #444;
    white-space: nowrap;
}

.fancybox-image,
.fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading,
.fancybox-prev span,
.fancybox-next span {
    background-image: url($img-path + 'icons/fancybox_sprite.png');
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 8060;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: .8;
    cursor: pointer;
}

#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url($img-path + 'icons/fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
    position: absolute;
    top: 17px;
    right: 17px;
    z-index: 8040;
    width: 26px;
    height: 26px;
    overflow: hidden;
    padding: em(3);
    border-radius: 50%;
    background: #fff;
    line-height: em(27);
    color: $mbiebuilding_blue_3;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.fancybox-close:hover,
.fancybox-close:focus {
    background: $mbiebuilding_body;
    color: #fff;
}

.fancybox-close::before {
    content: '\e81b';
}


.fancybox-nav {
    position: absolute;
    top: 0;
    z-index: 8040;
    width: 40%;
    height: 100%;
    background: transparent url('blank.gif'); /* helps IE */
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fancybox-prev {
    left: 0;
}

.fancybox-next {
    right: 0;
}

.fancybox-nav span {
    position: absolute;
    top: 50%;
    z-index: 8040;
    width: 36px;
    height: 34px;
    margin-top: -18px;
    visibility: hidden;
    cursor: pointer;
}

.fancybox-prev span {
    left: 10px;
    background-position: 0 -36px;
}

.fancybox-next span {
    right: 10px;
    background-position: 0 -72px;
}

.fancybox-nav:hover span {
    visibility: visible;
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important;
    visibility: hidden;
}

/* Overlay helper */

.fancybox-lock {
    width: auto;
    overflow: hidden !important;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8010;
    display: none;
    overflow: hidden;
    background: url($img-path + 'backgrounds/fancybox_overlay.png');
}

.fancybox-overlay-fixed {
    position: fixed;
    right: 0;
    bottom: 0;
}

.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
    position: relative;
    z-index: 8050;
    visibility: hidden;
}

.fancybox-opened .fancybox-title {
    visibility: visible;
}

.fancybox-title-float-wrap {
    position: absolute;
    right: 50%;
    bottom: 0;
    z-index: 8050;
    margin-bottom: -35px;
    text-align: center;
}

.fancybox-title-float-wrap .child {
    display: inline-block;
    padding: 2px 20px;
    margin-right: -100%;
    background: transparent; /* Fallback for web browsers that doesn't support RGBa */
    background: rgba(0, 0, 0, .8);
    line-height: 24px;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
}

.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}

.fancybox-title-inside-wrap {
    padding-top: 10px;
}

.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, .8);
    color: #fff;
}

/* stylelint-disable */

/* Retina graphics! */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    #fancybox-loading,
    .fancybox-prev span,
    .fancybox-next span {
        background-image: url($img-path + 'icons/fancybox_sprite@2x.png');
        background-size: 44px 152px; /* The size of the normal image, half the size of the hi-res image */
    }

    #fancybox-loading div {
        background-image: url($img-path + 'icons/fancybox_loading@2x.gif');
        background-size: 24px 24px; /* The size of the normal image, half the size of the hi-res image */
    }
}
/* stylelint-enable */


/*
 ---------------------------------------
 DIVIDERS
 ---------------------------------------
*/

.divider {
    clear: both;
    width: 100%;
    height: 1px;
    overflow: hidden;
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin: em(30) 0 em(40);
}


/*
 BLOCK - REFERRAL
---------------------------------------
*/

.block-referral {
    position: relative;
    clear: both;
    overflow: hidden;
    padding: em(30) em(16);
    border-bottom: 1px solid $mbiebuilding_gray_3;
    margin: em(30) 0;
    background: #fff;
}

.block-referral::before {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: '';
    height: em(3);
}

.block-referral .title {
    padding: 0;
    border: none;
    margin: em(25, 22) 0 em(15, 22);
    font-size: em(22);
    line-height: lh(25, 22);
    color: $mbiebuilding_gray_5;
}

.block-referral .description p {
    margin-bottom: em(8);
    font-size: em(16);
    line-height: lh(24);
    color: $mbiebuilding_gray_5;
}

.block-referral .button {
    display: block;
    margin-top: em(20);
}

.block-referral.with-image .button {
    margin-top: 0;
}

@media print, screen and (min-width: $mobile_min) {
    .block-referral {
        padding: em(30);
    }

    .block-referral .image {
        float: left;
        width: 47.5%;
        max-width: em(362);
    }

    .block-referral.with-image .liner {
        float: left;
        width: 52.5%;
    }

    .block-referral .title {
        padding: 0;
        margin: em(-7, 25) 0 em(20, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }

    .block-referral.with-image .title {
        margin-left: em(30, 25);
    }

    .block-referral.with-image .description {
        margin: 0 0 0 em(30);
    }
}

@media print, screen and (min-width: $tablet_min) {
    .block-referral {
        padding: em(32) em(30);
    }
}

@media only screen and (min-width: $desktop_min) {
    .block-referral {
        padding-bottom: em(20);
        margin-bottom: em(52);
    }
}


/*
 BLOCK - NOTIFICATIONS
---------------------------------------
*/

.block-notification {
    position: relative;
    z-index: 3;
    border-bottom: 2px solid $mbiebuilding_yellow_1;
    background: tint($mbiebuilding_yellow_1, 20%);
    color: $mbiebuilding_gray_6;
}

.block-notification.urgent {
    border-color: $mbiebuilding_red_2;
    background: tint($mbiebuilding_red_2, 20%);
}

.block-notification .page-wrapper {
    @include box-sizing(border-box);

    overflow: hidden;
    padding: em(20) em(40) em(18) em(39);
}

.block-notification .title {
    display: inline;
    font-size: em(18);
    line-height: lh(24, 18);
}

.block-notification.urgent .title::before {
    position: absolute;
    top: em(11, 36);
    left: em(13, 36);
    content: '!';
    font-size: em(36, 18);
    line-height: 1;
    color: $mbiebuilding_red_2;
}

.block-notification .description {
    display: inline;
    font-size: em(18);
    line-height: lh(24, 18);
}

.block-notification .description p {
    display: inline;
}

.block-notification .description p,
.block-notification .description li {
    font-size: inherit;
    line-height: inherit;
}

.block-notification .close-notification {
    position: absolute;
    top: em(23);
    right: em(20);
    color: $mbiebuilding_body;
}

@media only screen and (max-width: $tablet_max) {
    .mobile-search-active .block-notification,
    .mobile-menu-active .block-notification {
        display: none;
    }
}

@media print, screen and (min-width: $mobile_min) {
    .block-notification .page-wrapper {
        padding: em(28) em(20) em(25) em(53);
    }

    .block-notification.urgent .title::before {
        top: em(20, 36);
        left: em(27, 36);
    }

    .block-notification .close-notification {
        top: em(30);
    }
}

@media only screen and (min-width: $desktop_min) {
    .block-notification .page-wrapper {
        padding-left: em(23);
    }

    .block-notification.urgent .title::before {
        top: em(20, 36);
        left: em(-3, 36);
    }
}


/*
 BLOCK - RELATED CONTENT
---------------------------------------
*/

.block-related-content header .title {
    padding-bottom: em(20, 30);
    border-bottom: 1px solid $mbiebuilding_gray_3;
    margin-bottom: em(30, 30);
}

.block-related-content .item .description {
    color: $mbiebuilding_gray_4;
}

.block-related-content .item .description p {
    margin: 0;
    font-size: em(14);
    line-height: 1.43;
}

@media print, screen and (min-width: $mobile_min) {
    .block-related-content header .title {
        padding-bottom: em(20, 34);
        margin-bottom: 0;
        margin-bottom: em(30, 34);
    }

    .block-related-content .item {
        position: relative;
        overflow: hidden;
        margin: em(30) 0 em(42);
    }

    .block-related-content .item.with-image {
        min-height: em(100);
    }

    .block-related-content .item .title {
        margin: em(-3, 25) 0 em(10, 25);
    }

    .block-related-content .item .image {
        position: absolute;
        top: 0;
        right: 0;
        width: em(166);
    }

    .block-related-content .item.with-image .liner {
        margin-right: em(181);
    }
}

@media only screen and (min-width: $tablet_min) {
    .block-related-content header .title {
        padding-bottom: em(20, 36);
        margin-bottom: em(30, 36);
    }

    .block-related-content .item.with-image .liner {
        margin-right: em(196);
    }

    .block-related-content .item .title {
        margin: em(-3, 25) 0 em(10, 25);
    }
}

@media only screen and (min-width: $desktop_min) {
    .block-related-content {
        margin: em(47) 0 em(50);
    }
}

/*
 BLOCK - SHARING POPUP
---------------------------------------
*/

.page-options .share {
    position: relative;
}

.sharing-popup {
    @include box-shadow(0, 3px, 7px, 0, rgba(0, 0, 0, .6));

    position: absolute;
    top: em(34);
    right: em(-27);
    z-index: 2;
    min-width: em(158);
    padding: em(13) em(12) em(13) em(14);
    margin: 0;
    background: #fff;
    visibility: hidden;
    opacity: 0;
}

.active .sharing-popup {
    visibility: visible;
    opacity: 1;
}

.sharing-popup::before {
    position: absolute;
    top: -10px;
    right: 17%;
    content: '';
    width: 21px;
    height: 10px;
    background: url($img-path + 'backgrounds/social-popup-bg.png') no-repeat;
    background-size: contain;
}

#shell .sharing-popup ul {
    margin: 0;
    text-wrap: nowrap;
}

#shell .sharing-popup li::before {
    display: none;
}

#shell .sharing-popup li {
    margin: 0;
}


/*
 BLOCK - DOCUMENT INTRO
---------------------------------------
*/

.block-document-intro {
    position: relative;
    overflow: hidden;
    padding: em(30) em(16) em(10);
    background: #fff;
}

.block-document-intro .image {
    margin-bottom: em(21);
}

.block-document-intro p {
    margin-bottom: em(10, 18);
    font-size: em(18);
    line-height: lh(24, 18);
    color: $mbiebuilding_gray_5;
}

.block-document-intro .liner .js-concertina {
    padding-bottom: em(13);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin-top: em(13);
}

.block-document-intro .js-reveal p,
.block-document-intro .content-wrapper li {
    font-size: em(16);
    line-height: lh(21);
    color: $mbiebuilding_gray_4;
}

.block-document-intro .js-reveal p {
    margin-bottom: 1em;
}

@media print, screen and (min-width: $mobile_min) {
    .block-document-intro {
        min-height: em(255);
        padding-bottom: em(17);
        margin-bottom: em(10);
    }

    .block-document-intro .image {
        position: absolute;
        top: em(30);
        left: em(16);
        width: em(254);
        height: em(233);
    }

    .block-document-intro .liner {
        margin: 0;
    }

    .block-document-intro.with-image .liner {
        margin-left: em(271);
    }
}

@media only screen and (min-width: $tablet_min) {
    .block-document-intro {
        padding: em(25) em(23);
        margin-bottom: em(19);
    }

    .block-document-intro .image {
        top: em(25);
        left: em(23);
    }

    .block-document-intro .liner {
        margin-top: em(4);
    }

    .block-document-intro p {
        margin-bottom: em(10, 27);
        font-size: em(20);
        line-height: lh(27, 20);
    }

    .block-document-intro .js-concertina {
        margin: em(19) 0;
    }

    .block-document-intro .js-concertina .js-reveal {
        padding: em(9) em(30) em(22) 0;
    }

    .block-document-intro .js-concertina .js-reveal p,
    .block-document-intro .content-wrapper li {
        font-size: em(14);
        line-height: 1.43;
    }

    .block-document-intro .js-concertina .js-reveal p {
        margin-bottom: em(20, 14);
    }
}


/*
 BLOCK - MULTIPROOF LISTING
---------------------------------------
*/

.block-multiproof-listing {
    padding: em(34) em(17) em(38);
    border-top: 3px solid $mbiebuilding_gray_2;
    margin: em(40) 0 em(15);
    background: #fff;
}

.block-multiproof-listing .title {
    @extend .gustan-book;

    margin: 0 0 em(15, 30);
}

.block-multiproof-listing .sub-title {
    margin: 0 0 em(2, 22);
}

.block-multiproof-listing .layout-row:first-child {
    padding-bottom: em(20);
    border-bottom: 1px solid $mbiebuilding_gray_2;
}

.block-multiproof-listing .block-document-link {
    margin-bottom: em(21);
}

.block-multiproof-listing .block-document-link .title-text {
    margin: em(5) 0 em(2);
}

.block-multiproof-listing .block-document-link .date {
    display: none;
}

.block-multiproof-listing .document-metadata {
    margin: em(26) 0 em(30);
    color: $mbiebuilding_gray_5;
}

.block-multiproof-listing .document-metadata dd {
    margin-bottom: em(19);
}

.block-multiproof-listing .document-metadata dd p,
.block-multiproof-listing .document-metadata dd li {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
}

.block-multiproof-listing .document-metadata dd ul {
    margin: .5em;
    list-style: disc;
}

.block-multiproof-listing .document-metadata dd ol {
    margin: .5em;
    list-style: decimal;
}

.block-multiproof-listing .approval .sub-title {
    padding-bottom: em(17, 22);
    border-bottom: 3px solid $mbiebuilding_gray_2;
    margin-bottom: em(22, 22);
}

.block-multiproof-listing .approval ul {
    @extend .gustan-bold;

    margin-left: 1em;
    color: $mbiebuilding_gray_4;
    list-style: disc;
}

.block-multiproof-listing .approval li {
    margin-bottom: em(12);
}

.block-multiproof-listing .approval .tip-box {
    margin: 0 0 em(2);
}

.block-multiproof-listing .approval .button {
    width: 100%;
    min-width: inherit;
}

.block-multiproof-listing .approval .button .icon {
    margin-left: .5em;
    font-size: em(12);
}

@media print, screen and (min-width: $mobile_min) {
    .block-multiproof-listing {
        padding: em(30) em(30) em(51);
    }

    .block-multiproof-listing .title {
        margin: 0 0 em(15, 34);
    }

    .block-multiproof-listing .document-metadata {
        overflow: hidden;
        margin: em(31) 0 0;
    }

    .block-multiproof-listing .document-metadata dt {
        @include box-sizing(border-box);

        float: left;
        clear: left;
        width: 30.2%;
        padding-right: em(20);
        margin-bottom: em(20);
    }

    .block-multiproof-listing .document-metadata dd {
        float: left;
        width: 69%;
        margin-bottom: em(20);
    }

    .block-multiproof-listing .approval {
        overflow: hidden;
        margin-top: em(18);
    }

    .block-multiproof-listing .approval .sub-title {
        padding-bottom: em(15, 22);
        margin-bottom: em(22, 22);
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .block-multiproof-listing .approval ul {
        float: left;
        width: 48%;
        margin-left: em(20);
    }

    .block-multiproof-listing .approval .tip-box {
        float: left;
        width: 40%;
        padding-top: em(9);
    }

    .block-multiproof-listing .approval .button {
        width: 45%;
        margin: em(-6) 0 0 em(8);
    }
}

@media only screen and (min-width: $tablet_min) {
    .multiproof-listing-item {
        margin-top: em(20);
    }

    .multiproof-listing-item .layout-col-4 {
        width: 28.9%;
    }

    .multiproof-listing-item .layout-col-8 {
        width: 68.5%;
    }

    .block-multiproof-listing {
        position: relative;
        padding: em(51) em(30) em(30);
    }

    .block-multiproof-listing .title {
        float: left;
        width: 60%;
        margin: 0 0 em(17, 36);
    }

    .block-multiproof-listing .sub-title {
        float: left;
        width: 60%;
        margin: 0;
    }

    .block-multiproof-listing .block-document-link {
        position: absolute;
        top: em(49);
        right: 0;
        width: 34.2%;
    }

    .block-multiproof-listing .layout-row:first-child {
        padding-bottom: em(43);
    }

    .block-multiproof-listing .approval .sub-title {
        float: none;
        width: auto;
        padding-bottom: em(15, 25);
        margin: em(7, 25) 0 em(21, 25);
    }

    .block-multiproof-listing .layout-col-5 {
        width: 64.5%;
    }

    .block-multiproof-listing .layout-col-3 {
        width: 32.9%;
    }

    .block-multiproof-listing .document-metadata dt {
        width: 42%;
        margin-bottom: em(23, 14);
        font-size: em(14);
    }

    .block-multiproof-listing .document-metadata dd {
        width: 55%;
        margin-bottom: em(23, 14);
        font-size: em(14);
    }

    .block-multiproof-listing .approval ul {
        margin-left: em(20);
    }

    .block-multiproof-listing .approval .tip-box {
        padding: em(7) 0 0 em(48);
        margin-bottom: em(14);
    }

    .block-multiproof-listing .approval .tip-box .icon {
        top: 0;
        left: 0;
    }
}


/*
 BLOCK - DISCLAIMER
---------------------------------------
*/

.block-disclaimer {
    padding-top: em(20);
    border-top: 3px solid $mbiebuilding_gray_2;
    margin-top: em(30);
    color: $mbiebuilding_gray_4;
}

.block-disclaimer .title {
    padding-bottom: em(26, 22);
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin: em(6, 22) 0 em(25, 22);
}

.block-disclaimer ul {
    margin: 0 0 1em em(31);
    list-style: disc;
}

.block-disclaimer li {
    margin-bottom: em(5);
}

.block-disclaimer .divider {
    border-width: 1px;
    margin: em(44) 0 em(12);
}

@media print, screen and (min-width: $mobile_min) {
    .block-disclaimer {
        margin-top: em(94);
    }
}

@media only screen and (min-width: $tablet_min) {
    .block-disclaimer {
        margin-top: em(59);
    }

    .block-disclaimer .title {
        padding-bottom: em(25, 25);
        margin: em(3, 25) 0 em(24, 25);
    }

    .block-disclaimer ul {
        margin-left: em(29);
    }

    .block-disclaimer p,
    .block-disclaimer li {
        margin-bottom: em(21, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .block-disclaimer li {
        margin-bottom: em(5, 14);
    }

    .block-disclaimer .divider {
        margin: em(33) 0 em(29);
    }
}


/*
 BLOCK - DOCUMENT META DATA
---------------------------------------
*/

.block-document-meta.js-concertina {
    margin-bottom: 0;
}

.block-document-meta li {
    margin: 0 0 em(18);
    line-height: lh(22);
    color: $mbiebuilding_gray_4;
}

@media print, screen and (min-width: $mobile_min) {
    .block-document-meta {
        margin: em(26) 0 em(11);
    }

    .block-document-meta .layout-col {
        float: left;
        margin-right: 0;
    }

    .block-document-meta .layout-col-8 {
        width: 65%;
    }

    .block-document-meta .layout-col-4 {
        width: 35%;
    }

    .block-document-meta li {
        margin: 0 2em em(11, 14) 0;
        font-size: em(14);
        line-height: lh(22, 14);
    }

    .block-document-meta .last li {
        margin-right: 0;
        text-align: right;
    }

    .block-document-meta li .button-light-blue {
        font-size: em(13, 14);
    }
}

@media only screen and (min-width: $tablet_min) {
    .block-document-meta li {
        margin: 0 2em em(11, 13) 0;
        font-size: em(13);
        line-height: lh(18, 13);
    }

    .block-document-meta li .button-light-blue {
        font-size: em(13, 13);
    }

    /* Variation inside .block-document-intro */

    .block-document-intro .block-document-meta.js-concertina {
        margin: em(25) 0 0 0;
    }

    .block-document-intro.with-image .block-document-meta.js-concertina {
        margin-left: em(271);
    }

    .block-document-intro .block-document-meta .block-version-control-table {
        padding-right: 0;
    }

    .block-document-intro.with-image .block-document-meta .block-version-control-table {
        margin-left: em(-250);
    }

    .block-document-intro .block-document-meta .block-version-control-table .caption {
        margin-left: em(21, 25);
    }
}


/*
 BLOCK - NAV IN THIS DOCUMENT
---------------------------------------
*/

.nav-in-this-document .nav-title {
    padding-bottom: em(14, 25);
    border-bottom: 4px solid $mbiebuilding_gray_2;
    margin-bottom: 0;
    font-size: em(25);
}

.nav-in-this-document li {
    position: relative;
    margin: 0;
    font-size: em(16);
}

.nav-in-this-document a {
    display: block;
    padding: em(20) 0 em(20) em(6);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    text-decoration: none;
}

.nav-in-this-document .title {
    margin: 0 0 em(8, 18) em(19, 18);
    font-size: em(18);
    line-height: lh(24, 18);
}

.nav-in-this-document .title .icon-angle-right {
    position: absolute;
    top: em(20, 18);
    left: em(5, 18);
    margin-right: em(8, 18);
    color: $mbiebuilding_blue_3;
}

.nav-in-this-document a .title {
    color: $mbiebuilding_blue_1;
}

.nav-in-this-document a:hover .title,
.nav-in-this-document a:active .title,
.nav-in-this-document a:focus .title {
    text-decoration: underline;
}

.nav-in-this-document p {
    margin: 0 0 0 em(18);
    font-size: em(16);
    line-height: lh(20);
}

.nav-in-this-document a p {
    color: $mbiebuilding_gray_4;
}

@media print, screen and (min-width: $mobile_min) {
    .nav-in-this-document .nav-title {
        padding-bottom: em(14, 34);
        font-size: em(34);
        line-height: lh(39, 34);
    }
}

@media only screen and (min-width: $tablet_min) {
    .nav-in-this-document .nav-title {
        padding-bottom: em(14, 36);
        margin-bottom: em(-5, 36);
        font-size: em(36);
        line-height: lh(40, 36);
    }

    .nav-in-this-document .title {
        margin: em(5, 20) 0 em(8, 20) em(19, 20);
        font-size: em(20);
        line-height: lh(26, 20);
    }

    .nav-in-this-document .title .icon-angle-right {
        top: em(25, 20);
        left: em(5, 20);
        margin-right: em(8, 20);
    }

    .nav-in-this-document a {
        padding-bottom: em(26);
    }

    .nav-in-this-document p {
        margin: 0 0 0 em(18, 18);
        font-size: em(18);
        line-height: lh(20, 18);
    }
}


/*
 BLOCK - NAV DOCUMENT EXTRAS
---------------------------------------
*/

.nav-document-extras .nav-title {
    padding-bottom: em(14, 25);
    border-bottom: 4px solid $mbiebuilding_gray_2;
    margin-bottom: 0;
    font-size: em(25);
}

.nav-document-extras li {
    margin: 0;
    font-size: em(16);
}

.nav-document-extras a {
    display: block;
    overflow: hidden;
    padding: em(28) 0 em(24);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    text-decoration: none;
}

.nav-document-extras .title {
    float: left;
    width: 50%;
    margin: 0 0 em(8, 18) 0;
    font-size: em(16);
    line-height: lh(20);
}

.nav-document-extras a .title {
    color: $mbiebuilding_blue_1;
}

.nav-document-extras a:hover .title,
.nav-document-extras a:active .title,
.nav-document-extras a:focus .title {
    text-decoration: underline;
}

.nav-document-extras .image {
    float: right;
    width: 45%;
    max-width: em(169);
    margin-top: em(2);
}

.nav-document-extras p {
    clear: both;
    padding-top: em(15);
    margin: 0;
    font-size: em(16);
    line-height: lh(20);
}

.nav-document-extras a p {
    color: $mbiebuilding_gray_4;
}

@media print, screen and (min-width: $mobile_min) {
    .nav-document-extras .nav-title {
        padding-bottom: em(14, 34);
        font-size: em(34);
        line-height: lh(39, 34);
    }

    .nav-document-extras a {
        padding-bottom: em(30);
    }

    .nav-document-extras p {
        float: left;
        clear: none;
        width: 50%;
        padding-top: em(3);
    }
}

@media only screen and (min-width: $tablet_min) {
    .nav-document-extras .nav-title {
        padding-bottom: em(14, 36);
        margin-bottom: em(-5, 36);
        font-size: em(36);
        line-height: lh(40, 36);
    }

    .nav-document-extras p {
        padding-top: em(7, 14);
        font-size: em(14);
        line-height: 1.43;
    }
}


/*
 BLOCK - DOCUMENT LIST
 (contains .block-publication-promo-content)
---------------------------------------
*/

.block-documents-list h2 {
    margin-bottom: 0;
}

.block-documents-list .block-publication-promo-content {
    position: static;
    padding: em(14) em(8) 0;
}

.block-documents-list .block-publication-promo-content .title {
    padding: 0 0 em(8, 18) em(45, 18);
    border: none;
    margin: 0;
    font-size: em(18);
    line-height: lh(26, 18);
}

.block-documents-list .block-publication-promo-content .title [class^='icon-'] {
    font-size: em(47, 18);
}

.block-documents-list .block-publication-promo-content .title .icon-pdf {
    font-size: em(25, 18);
}

.block-documents-list .block-publication-promo-content .description {
    @extend .gustan-light;

    padding: 0 0 0 em(42);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin: 0 em(3);
    color: $mbiebuilding_gray_5;
}

.block-documents-list .block-publication-promo-content .description p {
    margin-bottom: em(17);
    font-size: em(16);
    line-height: lh(20);
}

@media only screen and (max-width: $mobile_max) {
    .block-documents-list .image img {
        width: 100%;
    }
}

@media print, screen and (min-width: $mobile_min) {
    .block-documents-list .block-publication-promo-content .title {
        padding-left: em(51, 18);
        font-size: em(18);
    }

    .block-documents-list .block-publication-promo-content .description {
        padding-left: em(47);
    }

    .block-documents-list .block-publication-promo-content .description p {
        margin-bottom: em(17, 14);
        font-size: em(14);
        line-height: 1.43;
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .block-documents-list {
        overflow: hidden;
    }

    .block-documents-list .image {
        float: right;
        width: 40%;
        margin: 0 0 em(20) em(30);
    }
}

@media only screen and (min-width: $tablet_min) {
    .block-documents-list .block-publication-promo-content:first-child {
        margin-top: em(10);
    }

    .block-documents-list .block-publication-promo-content {
        padding-top: em(4);
        padding-bottom: 0;
        border-bottom: none;
    }

    .block-documents-list .block-publication-promo-content .title {
        padding: em(21, 18) em(10, 18) em(21, 18) em(80, 18);
        font-size: em(18);
    }

    .block-documents-list .block-publication-promo-content .title [class^='icon-'] {
        top: em(26, 47);
        left: em(22, 47);
        font-size: em(47, 18);
    }

    .block-documents-list .block-publication-promo-content .title .icon-pdf {
        top: em(26, 25);
        left: em(22, 25);
        font-size: em(25, 18);
    }

    .block-documents-list .block-publication-promo-content .description {
        padding: 0 0 em(20) em(58);
        margin: 0 em(22);
    }

    .block-documents-list .block-publication-promo-content:last-child .description,
    .block-documents-list .block-publication-promo-content.last .description {
        border-bottom: none;
    }

    .block-documents-list .block-publication-promo-content .description p {
        margin-bottom: em(4, 14);
    }
}

/* Mailchimp form styles overrides */

#mc_embed_signup .field {
    max-width: em(300);
}

#mc_embed_signup .Actions {
    margin-top: 2em;
}

#mc_embed_signup div.mce_inline_error {
    padding-left: 0 !important;
    margin: 0 !important;
    background-color: transparent !important;
    font-weight: normal !important;
    color: $mbiebuilding_red_1 !important;
}

#mc_embed_signup input.mce_inline_error {
    border-color: $mbiebuilding_red_1 !important;
}

#mce-error-response {
    margin-bottom: 1em;
    color: $mbiebuilding_red_1;
}

#mce-success-response {
    margin-bottom: 1em;
    color: $mbiebuilding_blue_1;
}

#mc_embed_signup .asterisk {
    color: $mbiebuilding_red_1;
}

#mc_embed_signup ul {
    margin: 0 !important;
    list-style: none !important;
}

#mc_embed_signup ul li::before {
    display: none;
}

#mc_embed_signup ul label {
    margin-left: 0;
}

#mc_embed_signup ul label::after {
    left: 0;
}


/*
 ---------------------------------------
 BLOCK - alert
 ---------------------------------------
*/

.block-alert {
    position: relative;
    overflow: hidden;
    padding: em(3) 0 0 em(30);
}

.block-alert .icon {
    position: absolute;
    left: em(1, 19);
    left: 0;
    font-size: em(19);
    color: $mbiebuilding_orange_2;
}

.block-alert .title {
    @extend .gustan-bold;

    padding: 0 0 em(7);
    border-bottom: 2px solid $mbiebuilding_orange_2;
    line-height: lh(20);
}

.block-alert .description {
    margin-top: em(6);
    line-height: lh(22);
    color: $mbiebuilding_gray_4;
}

.block-alert .description p,
.block-alert .description li {
    font-size: inherit !important;
    line-height: inherit !important;
}

.block-alert .description p {
    margin-bottom: 0;
}

@media only screen and (min-width: $tablet_min) {
    .block-alert {
        padding-left: em(26);
    }

    .block-alert .title {
        padding-top: em(3);
    }

    .block-alert .description {
        margin-top: em(6, 14);
        font-size: em(14);
        line-height: 1.43;
    }
}


/*
 ---------------------------------------
 BLOCK - LANDING PAGES - STYLE 1
 ---------------------------------------
*/

.block-landing-1 {
    @extend .gustan-light;

    overflow: hidden;
    margin: 0 0 em(10);
}

.block-landing-1 .image {
    overflow: hidden;
}

.block-landing-1 .image img {
    width: 100%;
    height: em(140);
    object-fit: cover;
}

.no-objectfit .block-landing-1 .image {
    width: 100%;
    height: em(140);
    overflow: hidden;
}

.no-objectfit .block-landing-1 .image img {
    width: auto;
    max-width: none;
}

.block-landing-1 .title {
    border-bottom: 2px solid $mbiebuilding_gray_2;
}

.block-landing-1 h2.title {
    padding-bottom: em(25, 25);
    margin-bottom: em(22, 25);
    font-size: em(25);
}

.block-landing-1 h3.title {
    padding-bottom: em(25, 22);
    margin-bottom: em(22, 22);
    font-size: em(22);
}

.block-landing-1 .description {
    margin: 0 0 em(22);
    color: $mbiebuilding_gray_5;
}

.block-landing-1 .description p {
    margin-bottom: 1em;
    font-size: inherit;
    line-height: inherit;
}

.block-landing-1 .nav-list {
    margin: em(-7) 0 em(25);
}

.block-landing-1 .nav-list a {
    position: relative;
    display: block;
    padding: em(3) em(5) em(3) em(16);
}

.block-landing-1 .nav-list a .icon {
    position: absolute;
    top: em(2, 18);
    left: 0;
    font-size: em(18);
}

.block-landing-1 .nav-list a .icon-external {
    top: em(6, 12);
    font-size: em(12);
}

.block-landing-1 .nav-list a::after {
    display: none !important;
}

/* hide */

.block-landing-1 .action .button {
    width: 100%;
}

@media only screen and (min-width: 400px) and (max-width: 499px) {
    .block-landing-1 .image img,
    .no-objectfit .block-landing-1 .image {
        height: em(190);
    }
}

@media only screen and (min-width: 500px) and (max-width: 599px) {
    .block-landing-1 .image img,
    .no-objectfit .block-landing-1 .image {
        height: em(240);
    }
}

@media only screen and (min-width: 600px) and (max-width: 699px) {
    .block-landing-1 .image img,
    .no-objectfit .block-landing-1 .image {
        height: em(140);
    }
}

@media only screen and (min-width: 700px) and (max-width: 799px) {
    .block-landing-1 .image img,
    .no-objectfit .block-landing-1 .image {
        height: em(170);
    }
}

@media only screen and (min-width: 800px) and (max-width: 899px) {
    .block-landing-1 .image img,
    .no-objectfit .block-landing-1 .image {
        height: em(210);
    }
}

@media only screen and (min-width: 900px) and (max-width: 999px) {
    .block-landing-1 .image img,
    .no-objectfit .block-landing-1 .image {
        height: em(235);
    }
}

@media only screen and (min-width: 1000px) {
    .block-landing-1 .image img,
    .no-objectfit .block-landing-1 .image {
        height: em(275);
    }
}


@media print, screen and (min-width: $mobile_min) {
    /* JS height adjusted content */

    .block-landing-1.height-adjusted {
        position: relative;
    }

    .block-landing-1.height-adjusted .action {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .block-landing-1 .image {
        margin: 0 0 em(25);
    }

    .block-landing-1 h3.title {
        padding-bottom: em(23, 25);
        margin-top: em(8, 25);
        margin-bottom: em(22, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .block-landing-1-row .layout-col-6 {
        float: left;
        width: 47.5%;
        margin-right: 5%;
    }

    .block-landing-1-row .layout-col-6:last-child,
    .block-landing-1-row .layout-col-6.last {
        margin-right: 0;
    }

    .block-landing-1-row .layout-col-6 .layout-col-6 {
        float: none;
        width: auto;
    }
}

@media only screen and (min-width: $tablet_min) {
    .block-landing-1 .image {
        margin: 0 0 em(35);
    }

    .block-landing-1 h2.title {
        padding-bottom: em(25, 34);
        margin-top: em(8, 34);
        margin-bottom: em(22, 34);
        font-size: em(34);
        line-height: lh(29, 34);
    }

    .block-landing-1 h3.title {
        margin-bottom: em(22, 25);
    }

    .block-landing-1 .description {
        line-height: 1.5;
    }

    .block-landing-1 .nav-list {
        @include box-sizing(border-box);

        padding-left: em(35);
        margin: em(-3) 0 0;
    }

    .block-landing-1 .action {
        margin: em(8) 0 0;
    }
}

/*
 ---------------------------------------
 BLOCK - LANDING PAGES - STYLE 2
 ---------------------------------------
*/

.block-landing-2 {
    overflow: hidden;
    margin: 0;
}

.block-landing-2 .title {
    padding-bottom: em(20, 22);
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin: em(6, 22) 0 em(18, 22);
}

.block-landing-2 .description {
    color: $mbiebuilding_gray_4;
}

.block-landing-2 .description p {
    margin: 0 0 em(20);
    font-size: inherit;
    line-height: inherit;
}

.block-landing-2 .description .icon {
    margin-right: .5em;
}

@media only screen and (min-width: $tablet_min) {
    .block-landing-2 .title {
        padding-bottom: em(20, 25);
        margin: em(2, 25) 0 em(18, 25);
    }

    .block-landing-2 .description p {
        margin-bottom: em(20, 14);
        font-size: em(14);
        line-height: 1.43;
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .block-landing-2-row .layout-col-4 {
        float: left;
        width: 47.5%;
        margin-right: 5%;
    }

    .block-landing-2-row .layout-col-4:nth-child(2n+1) {
        margin-right: 0;
    }

    .block-landing-2-row .layout-col-4.nth-child-2-1 {
        margin-right: 0;
    }
}


/*
 ---------------------------------------
 BLOCK - LANDING PAGES - STYLE 3
 ---------------------------------------
*/

.block-landing-3-row {
    position: relative;
    padding: em(30) em(17) em(22);
    margin-bottom: em(30);
    background: #fff;
    color: $mbiebuilding_gray_5;
}

.block-landing-3-row::after {
    position: absolute;
    bottom: -35px;
    left: 50%;
    content: '';
    width: 0;
    height: 0;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 35px 35px 0 35px;
    margin-left: -35px;
}

.block-landing-3-row:last-child::after {
    display: none;
}

.block-landing-3-row img {
    margin-bottom: em(26);
}

.block-landing-3-row .button {
    min-width: inherit;
    margin-top: em(8);
    margin-bottom: em(15);
}

.block-landing-3-row .title {
    margin: em(19, 20) 0 em(17, 20);
    font-size: em(20);
    line-height: lh(26, 20);
}

.block-landing-3-row li {
    position: relative;
    font-size: em(15);
    line-height: em(20, 15);
}

.block-landing-3-row li a {
    display: block;
    padding: em(7, 15) 0 em(8, 15) em(20, 15);
}

.block-landing-3-row li a::before {
    position: absolute;
    top: em(7, 19);
    left: em(2, 19);
    content: '\e804';
    font-size: em(19);
    color: $mbiebuilding_blue_3;
}

/* Tip box variation */

#shell .block-landing-3-row .tip-box {
    padding: em(25) em(19) em(10);
    border: 2px solid $mbiebuilding_blue_6;
}

#shell .block-landing-3-row .tip-box .icon {
    top: em(12, 32);
    left: em(18, 32);
    font-size: em(32);
}

#shell .block-landing-3-row .tip-box .title {
    padding-left: em(35);
    margin: 0 0 em(20);
    font-size: em(16);
}

#shell .block-landing-3-row .tip-box a::before {
    content: '\e804';
    margin-right: .4em;
    font-size: em(20);
    color: $mbiebuilding_blue_3;
}

@media print, screen and (min-width: $mobile_min) {
    .block-landing-3-row {
        padding: em(27) em(15) em(22);
    }

    .block-landing-3-row .layout-col .image {
        float: left;
        width: 46.5%;
        margin: em(3) 5.3% 0 0;
    }

    .block-landing-3-row .layout-col.with-image .liner {
        float: left;
        width: 46.5%;
    }

    .block-landing-3-row .button {
        margin-bottom: 0;
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: 949px) {
    /* overriding the default breakpoints */

    .block-landing-3-row .layout-col-6,
    .block-landing-3-row .layout-col-9 {
        width: auto;
        margin-right: 0;
    }

    .block-landing-3-row .layout-col-3 {
        float: left;
        width: 46.5%;
        margin-right: 5.3%;
    }

    .block-landing-3-row .layout-col-3:last-child,
    .block-landing-3-row .layout-col-3.last {
        margin-right: 0;
    }
}

@media only screen and (min-width: 950px) {
    .block-landing-3-row {
        padding: em(42) em(42) em(33);
        margin-bottom: em(50);
    }

    .block-landing-3-row .title {
        margin-top: em(-4, 20);
    }

    .block-landing-3-row .layout-col-3 {
        width: 21%;
        margin-right: 6%;
    }

    .block-landing-3-row .layout-col-3:last-child,
    .block-landing-3-row .layout-col-3.last {
        margin-right: 0;
    }

    #shell .block-landing-3-row .tip-box {
        margin: 0;
    }

    #shell .block-landing-3-row .tip-box .title {
        margin-bottom: em(18);
    }
}


/* BLOCK - issue author
 ---------------------------------------
*/

.block-issue-author {
    margin: em(38) 0 0;
}

.block-issue-author img {
    height: auto;
}

.block-issue-author .layout-col-2 {
    float: left;
    width: 34%;
    margin: em(3) 6% 0 0;
}

.block-issue-author .layout-col-4 {
    float: left;
    width: 60%;
}

.block-issue-author p {
    font-size: em(15);
    line-height: lh(16, 15);
}

.block-issue-author .title {
    @extend .gustan-bold;

    margin-bottom: em(12, 18);
    font-size: em(18);
    line-height: lh(20, 18);
}

@media print, screen and (min-width: $mobile_min) {
    .block-issue-author {
        margin: em(33) 0 em(49);
    }

    .block-issue-author img {
        width: em(167);
    }

    .block-issue-author .layout-col-2 {
        width: em(199);
        margin: 0;
    }

    .block-issue-author .layout-col-4 {
        width: em(380);
        margin: em(39) 0 0;
    }

    .block-issue-author .title {
        margin-bottom: em(19, 18);
    }
}

@media only screen and (min-width: $tablet_min) {
    .block-issue-author {
        margin: em(36) 0 em(52);
    }

    .block-issue-author .layout-col-4 {
        margin-top: em(52);
    }
}


/*
 ---------------------------------------
 BLOCK - ISSUE ARTICLE
 ---------------------------------------
*/

.block-issue-article {
    max-width: em(754);
    margin: em(34) auto;
}

.block-issue-article .title {
    @extend .gustan-bold;

    margin: 0 0 em(18, 18);
    font-size: em(18);
    line-height: lh(25, 18);
}

.block-issue-article .article-actions {
    position: relative;
    padding-bottom: em(11);
    margin: em(20) 0 0;
    text-align: center;
}

.block-issue-article .article-actions::before {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    height: em(2);
}

.block-issue-article .js-reveal {
    margin-top: 2em;
}

@media print, screen and (min-width: $mobile_min) {
    .block-issue-article {
        margin: em(31) auto;
    }

    .block-issue-article .title {
        margin: 0 0 em(18, 22);
        font-size: em(22);
        line-height: lh(30, 22);
    }

    .block-issue-article .description {
        margin-bottom: 0;
    }

    .block-issue-article .article-actions {
        padding-bottom: em(12);
        margin: 0;
        text-align: right;
    }
}


/*
 ---------------------------------------
 BLOCK - DOCUMENT LINK
 This is used on several pages but will
 requires CSS overrides to achieve the many
 variations of layout.
 ---------------------------------------
*/

.block-document-link {
    min-height: em(63);
}

.block-document-link a {
    position: relative;
    display: block;
    padding: em(5) 0 0 em(61);
    color: $mbiebuilding_gray_4;
    text-decoration: none;
}

.block-document-link .icon-pdf {
    position: absolute;
    top: em(9, 25);
    left: em(1, 25);
}

.block-document-link .title-text {
    @extend .gustan-bold;

    display: block;
    margin-bottom: em(13);
    color: $mbiebuilding_gray_5;
}

.block-document-link a .title-text {
    color: $mbiebuilding_blue_1;
}

.block-document-link .date,
.block-document-link .meta {
    display: block;
    margin-bottom: em(10);
}

@media print, screen and (min-width: $mobile_min) {
    .block-document-link a {
        overflow: hidden;
    }

    .block-document-link .date,
    .block-document-link .meta {
        float: left;
        width: 50%;
    }

    .block-document-link .date {
        text-align: right;
    }
}

@media only screen and (min-width: $tablet_min) {
    .block-document-link .date,
    .block-document-link .meta {
        font-size: em(14);
        line-height: 1.43;
    }

    .block-document-link .date {
        text-align: right;
    }
}


/*
 ---------------------------------------
 FILTERED SEARCH RESULTS HEADER (EG. BCA, determinations register)
 ---------------------------------------
*/

/* Filtered search results header */

.search-results .search-results-header {
    position: relative;
    overflow: hidden;
    padding: em(33) 0 em(11);
    border-bottom: 3px solid $mbiebuilding_gray_2;
}

.search-results .search-results-header .search-matches {
    @extend .gustan-light;

    font-size: rem(22);
    line-height: lh(25, 22);
}

.search-results .search-results-header .expand-all-button {
    @extend .gustan-bold;

    display: block;
    padding: em(18) 0 em(15);
    text-align: center;
}

.search-results .search-results-header .expand-all-button .icon-plus {
    margin-right: .25em;
}

@media print, screen and (min-width: $mobile_min) {
    .search-results .search-results-header {
        padding-top: em(36);
    }

    .search-results .search-results-header .search-matches {
        font-size: rem(25);
    }

    .search-results .search-results-header .expand-all-button {
        text-align: right;
    }
}

@media only screen and (min-width: $tablet_min) {
    .search-results .search-results-header {
        padding: em(91) 0 em(12);
        margin-bottom: em(9);
    }

    .search-results .search-results-header .search-matches {
        float: left;
        width: 45%;
        color: $mbiebuilding_gray_5;
        text-align: left;
    }

    .search-results .search-results-header .expand-all-button {
        float: right;
        width: 40%;
        padding: em(6, 15) 0 em(15, 15);
        font-size: em(15);
        text-align: right;
    }
}

/* BCA search header */

.bca-search-results .search-results-header {
    text-align: right;
}

.bca-search-results .search-results-header .az-letter {
    @extend .gustan-black;

    position: absolute;
    bottom: em(12, 52);
    left: 0;
    font-size: em(52);
    line-height: 1;
    color: $mbiebuilding_gray_4;
}

.bca-search-results .search-results-header .expand-all-button {
    padding: em(2) 0 0;
    text-align: right;
}

@media only screen and (min-width: $tablet_min) {
    .bca-search-results .search-results-header .search-matches {
        margin-left: em(96, 22);
    }

    .bca-search-results .search-results-header .expand-all-button {
        padding: em(6, 15) 0 0;
    }
}

/* Determinations search header */

@media only screen and (min-width: $tablet_min) {
    .determinations-search-results .search-results-header {
        padding-top: em(45);
    }
}

/* Search header with sort */

.search-results-with-sort .search-results-header {
    margin-bottom: em(9);
}

@media print, screen and (min-width: $mobile_min) {
    .search-results-with-sort .search-results-header {
        padding-top: em(45);
        padding-bottom: em(18);
        margin-bottom: em(-8);
    }

    .search-results-with-sort .search-results-header .search-matches {
        float: left;
        width: 50%;
    }
}

@media only screen and (min-width: $tablet_min) {
    .search-results-with-sort .search-results-header {
        padding-top: em(45);
        padding-bottom: em(18);
        margin-bottom: em(2);
    }
}


/* Consumer complaint search header */

.consumer-complaint-search-results .search-results-header .search-matches {
    padding-bottom: em(13, 22);
}

@media only screen and (min-width: $tablet_min) {
    .consumer-complaint-search-results .search-results-header {
        padding-top: em(45);
        margin-bottom: 0;
    }

    .consumer-complaint-search-results .search-results-header .search-matches {
        padding-bottom: em(13, 25);
    }
}


/*
 ---------------------------------------
 BCA SEARCH RESULTS
 ---------------------------------------
*/

/* BCA search result items */

.bca-search-results .search-result-item {
    @extend .gustan-book;

    padding: em(22) 0 em(3);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin: 0;
    color: $mbiebuilding_gray_5;
}

.bca-search-results .search-result-item .title {
    @extend .gustan-book;

    margin: 0 0 em(24, 22);
    font-size: em(22);
    line-height: lh(29, 22);
    color: $mbiebuilding_gray_3;
}

.bca-search-results .search-result-item .title-with-byline {
    margin-bottom: .1em;
}

.bca-search-results .search-result-item .sub-title {
    @extend .gustan-bold;

    margin: 0 0 em(7, 18);
    font-size: em(18);
    line-height: lh(24, 18);
}

.bca-search-results .search-result-item .title-byline {
    @extend .gustan-bold;

    margin: 0 0 em(24, 22);
    font-size: em(18);
    line-height: lh(24, 18);
}

.bca-search-results .search-result-item .icon-plus {
    margin-right: .25em;
}

.bca-search-results .search-result-item .icon {
    margin: 0 em(8) 0 em(3);
    color: $mbiebuilding_gray_7;
}

.bca-search-results .search-result-item a {
    text-decoration: none;
}

.bca-search-results .search-result-item a:hover .span,
.bca-search-results .search-result-item a:active span,
.bca-search-results .search-result-item a:focus span {
    text-decoration: underline;
}

.bca-search-results .search-result-item a:hover .icon,
.bca-search-results .search-result-item a:active .icon,
.bca-search-results .search-result-item a:focus .icon {
    color: $mbiebuilding_blue_3;
}

.bca-search-results .search-result-item .layout-col {
    overflow: hidden;
    margin-bottom: em(16);
}

.bca-search-results .search-result-item .layout-col div {
    line-height: 1.5;
}

.bca-search-results .search-result-item .expand-item {
    display: block;
    max-width: em(200);
    padding-right: em(20);
    padding-left: em(20);
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.bca-search-results .search-result-item .expand-item:hover,
.bca-search-results .search-result-item .expand-item:focus {
    text-decoration: underline;
}

.bca-search-results .search-result-item dl {
    overflow: hidden;
}

.bca-search-results .search-result-item dt {
    @extend .fira-sans-medium;

    line-height: lh(24);
}

.bca-search-results .search-result-item dd {
    @extend .fira-sans-regular;

    margin-bottom: em(16);
    line-height: lh(24);
    color: $mbiebuilding_gray_5;
}

.bca-search-results .search-result-item dt a:hover {
    text-decoration: underline;
}

.bca-search-results .search-result-item dd *:last-child {
    margin-bottom: 0;
}

.bca-search-results .search-result-item .email,
.bca-search-results .search-result-item .url {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Breakpoints differ from defaults due to unique layout */
@media only screen and (min-width: 450px) {
    .bca-search-results .search-result-item .layout-col {
        @include box-sizing(border-box);

        float: left;
        width: 50%;
        padding-right: em(10);
    }
}

@media only screen and (min-width: 450px) and (max-width: 999px) {
    .bca-search-results .search-result-item .layout-col-3:last-child,
    .bca-search-results .search-result-item .layout-col-3.last {
        clear: both;
        width: 100%;
        margin: 0;
    }

    .bca-search-results .search-result-item .expand-item {
        margin-bottom: em(10);
    }
}

@media only screen and (min-width: 675px) {
    .bca-search-results .search-result-item .layout-col {
        width: 33%;
        margin-right: 0;
    }

    .bca-search-results .search-result-item .title {
        margin: 0 0 em(23, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }

    .bca-search-results .search-result-item .title-with-byline {
        margin-bottom: .1em;
    }

    .bca-search-results .search-result-item .title-byline {
        margin: 0 0 em(23, 25);
    }

    .bca-search-results .search-result-item .sub-title {
        margin: 0 0 em(6, 18);
    }

    .bca-search-results .search-result-item .expand-item {
        padding-right: em(20, 15);
        padding-left: em(20, 15);
        margin: em(-6, 15) 0 em(17, 15);
        font-size: em(15);
    }

    .bca-search-results .search-result-item .more-detail {
        margin: em(33) 0 em(9);
    }

    .bca-search-results .search-result-item dt {
        @include box-sizing(border-box);

        float: left;
        clear: both;
        width: 50.15%;
        padding-right: em(20, 14);
        margin: 0 0 em(12, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .bca-search-results .search-result-item dd {
        float: left;
        width: 40%;
        margin: 0 0 em(12, 14);
        font-size: em(14);
        line-height: 1.43;
    }
}

@media only screen and (min-width: 1000px) {
    .bca-search-results .search-result-item {
        padding: em(25) 0 em(16);
        margin: 0 em(98);
    }

    .bca-search-results .search-result-item .layout-col {
        width: 27.7%;
    }

    .bca-search-results .search-result-item .layout-col:last-child,
    .bca-search-results .search-result-item .layout-col.last {
        width: 16%;
        padding-right: 0;
    }

    .bca-search-results .search-result-item .expand-item {
        padding-right: 0;
        margin: em(26, 15) 0 0;
        text-align: right;
        text-align: left;
    }
}


/*
 ---------------------------------------
 DETERMINATIONS SEARCH RESULTS
 ---------------------------------------
*/

/* Determinations search result items */

.determinations-search-results .search-result-item {
    padding: em(34) 0 em(3);
    border-bottom: 4px solid $mbiebuilding_gray_7;
    margin: 0;
    color: $mbiebuilding_gray_5;
}

.determinations-search-results .search-result-item .title {
    @extend .gustan-book;

    margin: 0 0 em(24, 22);
    font-size: em(22);
    line-height: lh(25, 22);
}

.determinations-search-results .search-result-item .description {
    margin: 0 0 em(16);
    line-height: lh(22);
}

.determinations-search-results .search-result-item .description p {
    margin: 0;
}

.determinations-search-results .search-result-item .expand-item {
    @extend .gustan-bold;

    overflow: hidden;
    padding: em(11) 0 em(41);
    margin: em(10) 0 0;
    text-align: center;
}

.determinations-search-results .search-result-item .expand-item a {
    padding: em(8) em(48);
}

.determination-listing-item .more-detail {
    padding-top: em(27);
    border-top: 1px solid $mbiebuilding_gray_2;
    margin-top: em(23);
}

.determination-listing-item .more-detail .title {
    @extend .gustan-bold;

    margin-bottom: em(20);
    font-size: em(16);
}

.determination-listing-item .more-detail .document-metadata dt {
    margin-bottom: em(8);
    color: $mbiebuilding_gray_5;
}

.determination-listing-item .more-detail .document-metadata dd {
    margin-bottom: em(26);
    color: $mbiebuilding_gray_5;
}

.determinations-search-results .nav-pagination {
    margin-top: em(30);
}

@media print, screen and (min-width: $mobile_min) {
    .determinations-search-results .search-result-item {
        padding-top: em(32);
    }

    .determinations-search-results .search-result-item .title {
        margin: 0 0 em(24, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }

    .determinations-search-results .search-result-item .expand-item {
        padding: em(21) 0 em(34);
        margin-top: em(1);
        text-align: right;
    }

    .determinations-search-results .search-result-item.active .expand-item {
        border-top: none;
    }

    .determination-listing-item .more-detail .title {
        margin-bottom: em(20);
        font-size: em(16);
    }

    .determination-listing-item .more-detail .document-metadata {
        overflow: hidden;
        padding-top: em(10);
    }

    .determination-listing-item .more-detail .document-metadata dt {
        @include box-sizing(border-box);

        float: left;
        clear: both;
        width: 27.75%;
        padding-right: em(20);
        margin-bottom: em(21);
    }

    .determination-listing-item .more-detail .document-metadata dd {
        float: left;
        width: 70%;
        margin-bottom: em(21);
    }

    .determination-listing-item .more-detail .document-metadata .tag {
        margin-top: em(-10);
        margin-bottom: em(16);
    }
}

@media only screen and (min-width: $tablet_min) {
    .determinations-search-results .search-result-item {
        padding-top: em(32);
        margin: 0 em(98);
    }

    .determinations-search-results .search-result-item .description {
        margin: 0 0 em(19, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .determinations-search-results .search-result-item .expand-item {
        padding: em(19) 0 em(16);
        border-top: 1px solid $mbiebuilding_gray_2;
        margin-top: em(23);
    }

    .determinations-search-results .search-result-item.active .expand-item {
        padding-bottom: em(32);
        margin-top: em(35);
    }

    .determinations-search-results .search-result-item .expand-item a {
        padding: em(10, 14) em(48, 14);
        font-size: em(14);
    }

    .determination-listing-item .more-detail .document-metadata {
        padding-top: em(12);
    }

    .determination-listing-item .more-detail .document-metadata dt,
    .determination-listing-item .more-detail .document-metadata dd {
        margin-bottom: em(21, 14);
        font-size: em(14);
        line-height: lh(20);
    }

    .determination-listing-item .more-detail .document-metadata dt {
        width: 20.5%;
    }

    .determination-listing-item .more-detail .document-metadata dd {
        width: 79%;
    }

    .determination-listing-item .more-detail .document-metadata .tag {
        margin-top: em(-10, 14);
        margin-bottom: em(16, 14);
        font-size: em(14, 14);
    }

    .determinations-search-results .nav-pagination {
        margin-top: em(96);
    }
}


/*
 ---------------------------------------
 MULTIPROOF SEARCH RESULTS
 ---------------------------------------
*/

/* Multiproof search result items */

.multiproof-search-results .search-result-item {
    overflow: hidden;
    padding: em(25) 0 em(30);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin: 0;
    color: $mbiebuilding_gray_5;
}

.multiproof-search-results .search-result-item .title {
    @extend .gustan-book;

    margin: 0 0 em(20, 22);
}

.multiproof-search-results .search-result-item .description {
    margin: 0 0 em(14);
    line-height: lh(22);
}

.multiproof-search-results .search-result-item .results-type {
    @extend .gustan-bold;

    margin: 0 0 em(16);
    line-height: lh(22);
}

.multiproof-search-results .search-result-item .date {
    margin: 0 0 em(24);
    line-height: lh(22);
    color: $mbiebuilding_gray_4;
}

.multiproof-search-results .search-result-item .button {
    width: 100%;
}

.multiproof-search-results .nav-pagination {
    margin-top: em(30);
}

@media print, screen and (min-width: $mobile_min) {
    .multiproof-search-results .search-result-item {
        padding: em(36) 0 em(40);
    }

    .multiproof-search-results .search-result-item .title {
        margin: 0 0 em(18, 25);
        font-size: em(25);
    }

    .multiproof-search-results .search-result-item .button {
        width: auto;
        min-width: em(287);
    }
}

@media only screen and (min-width: $tablet_min) {
    .multiproof-search-results .search-result-item {
        padding: em(54) 0 em(40);
        margin: 0 em(98);
    }

    .multiproof-search-results .search-result-item .layout-col-9 {
        width: 69.5%;
    }

    .multiproof-search-results .search-result-item .layout-col-3 {
        width: 27.8%;
        margin-top: em(9);
        margin-right: 0;
        text-align: right;
    }

    .multiproof-search-results .search-result-item .description {
        margin: 0 0 em(14, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .multiproof-search-results .search-result-item .date {
        margin: 0 0 em(48, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .multiproof-search-results .search-result-item .button {
        width: 100%;
        min-width: inherit;
    }

    .multiproof-search-results .nav-pagination {
        margin-top: em(96);
    }
}

/*
 ---------------------------------------
  PRODUCT SEARCH RESULTS
 ---------------------------------------
*/

/* Product register search result items */

.product-search-results .search-result-item {
    padding: em(34) 0 em(3);
    border-bottom: 4px solid $mbiebuilding_gray_7;
    margin: 0;
    color: $mbiebuilding_gray_5;
}

.product-search-results .search-result-item .title {
    @extend .gustan-book;

    margin: 0 0 em(24, 22);
    font-size: em(22);
    line-height: lh(25, 22);
}

.product-search-results .search-result-item .description {
    margin: 0 0 em(16);
    line-height: lh(22);
}

.product-search-results .search-result-item .description p {
    margin: 0 0 1em;
    line-height: inherit;
}

.product-search-results .search-result-item .expand-item {
    @extend .gustan-bold;

    overflow: hidden;
    padding: em(11) 0 em(41);
    margin: em(10) 0 0;
}

.product-search-results .search-result-item .expand-item a {
    padding: em(8) em(48);
}

.product-listing-item .more-detail {
    padding-top: em(27);
    border-top: 1px solid $mbiebuilding_gray_2;
    margin-top: em(23);
}

.product-listing-item .more-detail .title {
    @extend .gustan-bold;

    margin-bottom: em(20);
    font-size: em(16);
}

.product-listing-item .more-detail .document-metadata dt {
    margin-bottom: em(8);
    color: $mbiebuilding_gray_5;
}

.product-listing-item .more-detail .document-metadata dd {
    margin-bottom: em(26);
    color: $mbiebuilding_gray_5;
}

.product-listing-item .more-detail .document-metadata dd p,
.product-listing-item .more-detail .document-metadata dd li {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
}

.product-search-results .nav-pagination {
    margin-top: em(30);
}

@media print, screen and (min-width: $mobile_min) {
    .product-search-results .search-result-item {
        padding-top: em(32);
    }

    .product-search-results .search-result-item .title {
        margin: 0 0 em(24, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }

    .product-search-results .search-result-item .expand-item {
        padding: em(21) 0 em(34);
        margin-top: em(1);
        text-align: right;
    }

    .product-search-results .search-result-item.active .expand-item {
        border-top: none;
    }

    .product-listing-item .more-detail .title {
        margin-bottom: em(20);
        font-size: em(16);
    }

    .product-listing-item .more-detail .document-metadata {
        overflow: hidden;
        padding-top: em(10);
    }

    .product-listing-item .more-detail .document-metadata dt {
        @include box-sizing(border-box);

        float: left;
        clear: both;
        width: 27.75%;
        padding-right: em(20);
        margin-bottom: em(21);
    }

    .product-listing-item .more-detail .document-metadata dd {
        float: left;
        width: 70%;
        margin-bottom: em(21);
    }

    .product-listing-item .more-detail .document-metadata .tag {
        margin-top: em(-10);
        margin-bottom: em(16);
    }
}

@media only screen and (min-width: $tablet_min) {
    .product-search-results .search-result-item {
        padding-top: em(32);
        margin: 0 em(98);
    }

    .product-search-results .search-result-item .description {
        margin: 0 0 em(19, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .product-search-results .search-result-item .description p {
        font-size: inherit;
        line-height: inherit;
    }

    .product-search-results .search-result-item .expand-item {
        padding: em(19) 0 em(16);
        border-top: 1px solid $mbiebuilding_gray_2;
        margin-top: em(23);
    }

    .product-search-results .search-result-item.active .expand-item {
        padding-bottom: em(32);
        margin-top: em(35);
    }

    .product-search-results .search-result-item .expand-item a {
        padding: em(10, 14) em(48, 14);
        font-size: em(14);
    }

    .product-listing-item .more-detail .document-metadata {
        padding-top: em(12);
    }

    .product-listing-item .more-detail .document-metadata dt,
    .product-listing-item .more-detail .document-metadata dd {
        margin-bottom: em(21, 14);
        font-size: em(14);
        line-height: lh(20);
    }

    .product-listing-item .more-detail .document-metadata dt {
        width: 20.5%;
    }

    .product-listing-item .more-detail .document-metadata dd {
        width: 79%;
    }

    .product-listing-item .more-detail .document-metadata .tag {
        margin-top: em(-10, 14);
        margin-bottom: em(16, 14);
        font-size: em(14, 14);
    }

    .product-search-results .nav-pagination {
        margin-top: em(96);
    }
}


/*
 ---------------------------------------
 CONSUMER COMPLAINT SEARCH RESULTS
 ---------------------------------------
*/

/* Consumer complaint search result items */

.consumer-complaint-search-results .search-result-item {
    padding: em(34) 0;
    border-bottom: 4px solid $mbiebuilding_gray_2;
    margin: 0;
    color: $mbiebuilding_gray_5;
}

.consumer-complaint-search-results .search-result-item .title {
    @extend .gustan-book;

    margin: 0 0 em(19, 22);
    font-size: em(22);
    line-height: lh(25, 22);
}

.consumer-complaint-search-results .search-result-item .description {
    margin: 0 0 em(-7);
}

.consumer-complaint-search-results .search-result-item .description dt {
    margin: 0;
    line-height: lh(24);
}

.consumer-complaint-search-results .search-result-item .description dd {
    margin: 0 0 em(26);
    line-height: lh(24);
}

.consumer-complaint-search-results .search-result-item .description dd p,
.consumer-complaint-search-results .search-result-item .description dd li {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
}

.consumer-complaint-search-results .nav-pagination {
    margin-top: em(30);
}

@media print, screen and (min-width: $mobile_min) {
    .consumer-complaint-search-results .search-result-item {
        padding-top: em(32);
        padding-bottom: em(27);
    }

    .consumer-complaint-search-results .search-result-item .title {
        margin: 0 0 em(37, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }

    .consumer-complaint-search-results .search-result-item .description {
        margin-bottom: em(5);
    }

    .consumer-complaint-search-results .search-result-item .description dl {
        overflow: hidden;
    }

    .consumer-complaint-search-results .search-result-item .description dt {
        @include box-sizing(border-box);

        float: left;
        clear: both;
        width: 33.25%;
        padding-right: em(20);
        margin-bottom: em(20);
        line-height: lh(20);
    }

    .consumer-complaint-search-results .search-result-item .description dd {
        float: left;
        width: 66%;
        margin-bottom: em(20);
        line-height: lh(20);
    }
}

@media only screen and (min-width: $tablet_min) {
    .consumer-complaint-search-results .search-result-item {
        padding-top: em(53);
        padding-bottom: em(77);
        margin: 0 em(98);
    }

    .consumer-complaint-search-results .search-result-item .title {
        margin: 0 0 em(57, 25);
    }

    .consumer-complaint-search-results .search-result-item .description {
        margin-bottom: em(5);
    }

    .consumer-complaint-search-results .search-result-item .description dt {
        width: 20.7%;
        margin-bottom: em(20, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .consumer-complaint-search-results .search-result-item .description dd {
        width: 78%;
        margin-bottom: em(20, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .consumer-complaint-search-results .nav-pagination {
        margin-top: em(96);
    }
}


/*
 ---------------------------------------
 SITE SEARCH RESULTS
 ---------------------------------------
*/

.site-search-results {
    margin-bottom: 3em;
}

.site-search-results.search-results-with-sort .search-results-header {
    margin-bottom: em(5);
}

/* Site search result items */

.site-search-results .search-result-item {
    padding: rem(30) 0 rem(25);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin: 0;
    color: $mbiebuilding_gray_5;
}

.site-search-results .search-result-item .title {
    @extend .gustan-book;

    margin: 0 0 em(15, 22);
    font-size: em(22);
    line-height: lh(25, 22);
}

.site-search-results .search-result-item h4.title {
    margin: inherit;
    font-size: inherit;
    line-height: inherit;
}

.site-search-results .search-result-item .section {
    margin: 0 0 em(32, 14);
    font-size: em(14);
    line-height: lh(18, 14);
    color: $mbiebuilding_gray_5;
}

.site-search-results .search-result-item .description,
.site-search-results .search-result-item .count {
    margin: 0 0 em(18);
}

@media print, screen and (min-width: $mobile_min) {
    .site-search-results.search-results-with-sort .search-results-header {
        margin-bottom: 0;
    }

    .site-search-results .search-result-item {
        padding-top: em(43);
        padding-bottom: em(27);
    }

    .site-search-results .search-result-item .title {
        margin-bottom: em(10, 25);
        font-size: em(25);
        line-height: lh(29, 25);
    }

    .site-search-results .search-result-item h4.title {
        margin: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    .site-search-results .search-result-item .description,
    .site-search-results .search-result-item .count {
        margin: 0 0 em(18, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .site-search-results .consumer-complaint-search-results .search-result-item .description,
    .site-search-results .determinations-search-results .search-result-item .description,
    .site-search-results .bca-search-results .search-result-item .description,
    .site-search-results .multiproof-search-results .search-result-item .description {
        font-size: inherit;
        line-height: inherit;
    }

    /* reset */

    .site-search-results .determinations-search-results .search-result-item .description p {
        font-size: em(14);
        line-height: 1.43;
    }
}

@media only screen and (min-width: $tablet_min) {
    .site-search-results {
        margin-bottom: 6em;
    }

    .site-search-results .search-result-item {
        padding-top: em(43);
        padding-bottom: em(38);
        margin: 0 em(98);
    }
}

/* Overrides for registers within site search results */

.site-search-results .determinations-search-results .search-result-item .expand-item,
.site-search-results .product-search-results .search-result-item .expand-item {
    padding: em(10) 0;
}

.site-search-results .more-detail {
    border-top: none !important;
    margin-top: 0 !important;
}

@media print, screen and (min-width: $mobile_min) {
    .site-search-results .determinations-search-results .search-result-item .expand-item,
    .site-search-results .product-search-results .search-result-item .expand-item {
        padding: em(10) 0;
    }
}

@media print, screen and (min-width: $tablet_min) {
    .site-search-results .determinations-search-results .search-result-item .expand-item,
    .site-search-results .product-search-results .search-result-item .expand-item {
        padding: em(10) 0 0;
        border-top: none;
        margin-top: 0;
    }
}


/*
 ---------------------------------------
 CODEWORDS PREVIOUS ISSUES
 ---------------------------------------
*/

/* No JS */

.codewords-landing-previous-issues .nav-issue,
.js-enabled .previous-issues-container h3.non-js {
    display: none;
}

.js-enabled .codewords-landing-previous-issues .nav-issue,
.previous-issues-container h3.non-js {
    display: block;
}

/* Heading */

.codewords-landing-previous-issues.page-wrapper {
    padding-top: em(19);
}

.codewords-landing-previous-issues .h1-wrap {
    margin-bottom: em(50);
}

/* Codewords issue tabs */

.codewords-landing-previous-issues .nav-issue {
    overflow: hidden;
    margin: em(10) 0 em(24);
}

.codewords-landing-previous-issues .nav-issue ol {
    float: left;
    width: 75%;
}

.codewords-landing-previous-issues .nav-issue li,
.codewords-landing-previous-issues .nav-issue div {
    @extend .gustan-light;

    float: left;
    width: 33%;
    font-size: em(22);
    line-height: 1;
    color: $mbiebuilding_gray_5;
}

.codewords-landing-previous-issues .nav-issue li a {
    display: block;
    padding: em(5, 22) 0;
    outline: none;
}

.codewords-landing-previous-issues .nav-issue div {
    width: 25%;
    padding: em(5, 22) 0;
}

.codewords-landing-previous-issues .nav-issue .ui-state-active a {
    @extend .gustan-bold;

    color: $mbiebuilding_gray_5;
}

.codewords-landing-previous-issues .issue-description {
    font-size: em(15);
    line-height: lh(19, 15);
}

.codewords-landing-previous-issues .issue-description p,
.codewords-landing-previous-issues .issue-description li {
    font-size: inherit;
    line-height: inherit;
}

/* Codewords issues - show more */

.codewords-landing-previous-issues .divider {
    border-width: 1px;
}

.codewords-landing-previous-issues .show-more {
    @extend .gustan-bold;

    display: block;
    padding: 0 0 em(42);
    text-align: center;
}

.codewords-landing-previous-issues .show-more .icon {
    margin-right: .5em;
}

/* No results */

.codewords-landing-previous-issues .block-alert02 {
    padding: em(20) em(40) em(5);
}

@media print, screen and (min-width: $mobile_min) {
    .codewords-landing-previous-issues.page-wrapper {
        padding-top: em(7);
    }

    .codewords-landing-previous-issues .h1-wrap {
        margin-bottom: em(32);
    }

    .codewords-landing-previous-issues .h1-wrap .layout-row {
        position: relative;
        overflow: visible;
    }

    .codewords-landing-previous-issues .nav-issue {
        width: 50%;
        margin: em(33) 0 em(20);
    }

    .codewords-landing-previous-issues .issue-description {
        position: absolute;
        right: 0;
        bottom: em(6, 15);
        width: 48.2%;
        line-height: lh(20, 15);
    }

    .codewords-landing-previous-issues .divider {
        margin-bottom: em(23);
    }

    .codewords-landing-previous-issues .show-more {
        padding-right: em(31, 15);
        padding-bottom: em(28, 15);
        font-size: em(15);
        text-align: right;
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .codewords-landing-previous-issues .previous-issues-container .layout-col-4 {
        float: left;
        width: 47.7%;
        margin-right: 4.3%;
    }

    .codewords-landing-previous-issues .previous-issues-container .layout-col-4:nth-child(2n) {
        margin-right: 0;
    }

    .codewords-landing-previous-issues .previous-issues-container .layout-col-4.nth-child-2 {
        margin-right: 0;
    }

    .codewords-landing-previous-issues .previous-issues-container .layout-col-4:last-child,
    .codewords-landing-previous-issues .previous-issues-container .layout-col-4.last {
        display: none;
    }

    .codewords-landing-previous-issues .previous-issues-container.js-ajax-opened .layout-col-4:last-child,
    .codewords-landing-previous-issues.js-ajax-opened .previous-issues-container .layout-col-4.last {
        display: block;
    }
}

@media only screen and (min-width: $tablet_min) {
    .codewords-landing-previous-issues.page-wrapper {
        margin-bottom: em(91);
    }

    .codewords-landing-previous-issues .h1-wrap {
        margin-bottom: em(30);
    }

    .codewords-landing-previous-issues h2 {
        margin: em(18, 52) 0 0;
        font-size: em(52);
        line-height: 1;
    }

    .codewords-landing-previous-issues .nav-issue {
        margin: em(52) 0 em(29);
    }

    .codewords-landing-previous-issues .nav-issue li,
    .codewords-landing-previous-issues .nav-issue div {
        font-size: em(25);
    }

    .codewords-landing-previous-issues .nav-issue li a,
    .codewords-landing-previous-issues .nav-issue div {
        padding: em(5, 25) 0;
    }

    .codewords-landing-previous-issues .issue-description {
        right: 0;
        bottom: em(12, 15);
        width: 49%;
    }

    .codewords-landing-previous-issues .show-more {
        padding-top: em(5, 15);
    }

    .codewords-landing-previous-issues .js-ajax-container .layout-col-4:nth-child(3n) {
        margin-right: 0;
    }
}

@media only screen and (min-width: $desktop_min) {
    .codewords-landing-previous-issues .nav-issue div {
        width: 19%;
    }

    .codewords-landing-previous-issues .nav-issue ol {
        width: 57%;
    }
}


/*
 SPECIAL NEWS TAGS (EG. CANTERBURY)
---------------------------------------
*/

.canterbury-tag::after {
    @extend .gustan-black;

    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
    content: 'Canterbury';
    padding: em(8, 14) em(44, 14);
    background: $mbiebuilding_blue_2;
    font-size: em(14);
    line-height: 1.2;
    color: #fff;
    text-transform: uppercase;
}


/*
 MATHS NOTATION
---------------------------------------
*/

span[lang=latex] img {
    display: inline;
}


/*
 ---------------------------------------
 SPECIFIC PAGES
 These override styles previously set
 elsewhere.
 ---------------------------------------
*/

/* Codewords landing page
 ---------------------------------------
*/

.codewords-landing .h1-wrap .button {
    display: none;
}

.codewords-landing .codewords-intro {
    margin: em(19) 0 em(44);
}

.codewords-landing .codewords-intro .title {
    margin: em(23, 25) 0 em(19, 25);
    font-size: em(25);
}

.codewords-landing .codewords-intro .date {
    @extend .gustan-bold;

    margin: 0 0 em(19, 14);
    font-size: em(14);
    text-transform: uppercase;
}

.codewords-landing .codewords-intro .button {
    width: 100%;
}

.codewords-landing .codewords-intro p .button {
    margin-top: em(25);
}

@media print, screen and (min-width: $mobile_min) {
    .codewords-landing .h1-wrap {
        position: relative;
    }

    .codewords-landing .h1-wrap .button {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        min-width: em(200);
    }

    .codewords-landing .codewords-intro {
        margin: em(22) 0 em(35);
    }

    .codewords-landing .codewords-intro .image {
        float: right;
        width: 48%;
    }

    .codewords-landing .codewords-intro .image img {
        margin: em(7) 0 0 0;
    }

    .codewords-landing .codewords-intro .layout-col-6 {
        @include box-sizing(border-box);

        float: left;
        width: 52%;
        padding-right: em(30);
        margin-right: 0;
    }

    .codewords-landing .codewords-intro .title {
        margin: 0 0 em(12, 28);
        font-size: em(28);
    }

    .codewords-landing .codewords-intro .date {
        margin: 0 0 em(17, 14);
    }

    .codewords-landing .codewords-intro .button {
        display: none;
    }

    .codewords-landing .codewords-intro p .button {
        display: inline-block;
        width: auto;
        margin-top: em(17);
    }
}

@media only screen and (min-width: $tablet_min) {
    .codewords-landing .codewords-intro {
        margin: em(42) 0 em(91);
    }

    .codewords-landing .codewords-intro .image {
        width: 48.8%;
    }

    .codewords-landing .codewords-intro .image img {
        margin: em(9) 0 0 0;
    }

    .codewords-landing .codewords-intro .layout-col-6 {
        width: 51.2%;
    }

    .codewords-landing .codewords-intro .title {
        margin: 0 0 em(8, 36);
        font-size: em(36);
    }

    .codewords-landing .codewords-intro .date {
        margin: 0 0 em(21, 14);
    }

    .codewords-landing .codewords-intro p .button {
        margin-top: em(9);
        font-size: em(16, 15);
    }
}


/* Codewords issue page
 ---------------------------------------
*/

/* Issue summary */

.codewords-issue .issue-hero-image {
    width: 100%;
    margin: em(19) 0 em(33);
}

.codewords-issue .issue-summary .button {
    width: 100%;
}

#shell .codewords-issue .issue-summary h2 {
    padding-bottom: em(20, 25);
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin-top: em(37, 25);
    margin-bottom: em(20, 25);
}

.codewords-issue .issue-summary .date {
    @extend .gustan-bold;

    margin: 0 0 em(28, 14);
    font-size: em(14);
    text-transform: uppercase;
}

#shell .codewords-issue .content-wrapper .page-options {
    margin-top: em(28);
}

.codewords-issue .nav-tertiary-heading {
    display: none;
}

.codewords-issue .nav-tertiary ul {
    margin-bottom: 0;
}

.codewords-issue .nav-tertiary li {
    float: none;
}

/* Issue content */

.codewords-issue .issue-content .h2-wrap {
    margin: em(40) 0 em(27);
}

#shell .codewords-issue .issue-content h2 {
    padding-bottom: 0;
    border-bottom: none;
    margin: em(22, 25) 0 em(18, 25);
}

@media print, screen and (min-width: $mobile_min) {
    .codewords-issue .issue-hero-image {
        margin: em(29) 0 em(27);
    }

    .codewords-issue .issue-summary {
        position: relative;
    }

    #shell .codewords-issue .issue-summary h2 {
        padding-bottom: em(14, 34);
        margin-bottom: em(20, 34);
    }

    .codewords-issue .issue-summary .actions {
        position: absolute;
        top: em(41);
        right: 0;
        text-align: right;
    }

    .codewords-issue .issue-summary .actions p {
        display: inline;
        margin-left: em(19);
        font-size: em(16);
    }

    .codewords-issue .issue-summary .button {
        width: auto;
        min-width: 0;
        padding-right: em(30);
        padding-left: em(30);
    }

    .codewords-issue .issue-content .h2-wrap {
        margin: em(25) 0 em(21);
    }

    #shell .codewords-issue .issue-content .h2-wrap h2 {
        margin-bottom: em(18, 34);
    }

    .codewords-issue .issue-content .block-issue-author {
        margin-bottom: em(11);
    }
}

@media only screen and (min-width: 650px) {
    .codewords-issue .issue-summary .button {
        padding-right: em(50);
        padding-left: em(50);
    }
}

@media only screen and (min-width: $tablet_min) {
    .codewords-issue .issue-hero-image {
        margin: em(50) 0 em(36);
    }

    #shell .codewords-issue .issue-summary h2 {
        padding-bottom: em(14, 36);
        margin: em(20, 36) 0 em(20, 36);
    }

    .codewords-issue .issue-summary .date {
        margin-bottom: em(20, 14);
    }

    .codewords-issue .issue-summary .actions {
        top: em(32);
        right: 0;
    }

    .codewords-issue .issue-summary .button {
        padding-right: em(73);
        padding-left: em(73);
    }

    .codewords-issue .issue-content .h2-wrap {
        margin: em(7) 0 em(21);
    }

    #shell .codewords-issue .issue-content .h2-wrap h2 {
        margin-bottom: em(27, 36);
    }

    .codewords-issue .issue-content .layout-col-9 {
        width: 79%;
        margin-right: 3.35%;
    }

    .codewords-issue .issue-content .layout-col-3 {
        width: 17.65%;
    }

    .codewords-issue .issue-content .block-issue-author {
        margin: em(5) 0 0;
    }

    .codewords-issue .issue-content .block-issue-author .layout-col {
        float: none;
        width: auto;
    }

    .codewords-issue .issue-content .block-issue-author img {
        width: 100%;
    }

    .codewords-issue .issue-content .block-issue-author .layout-col-4 {
        margin-top: em(17);
    }
}

/* Campaign page
 ---------------------------------------
*/

@media only screen and (min-width: $desktop_min) {
    .campaign-page .intro-text {
        padding-right: rem(290);
    }
}

/* Resolving problems landing page
 ---------------------------------------
*/

.resolving-problems-landing-intro {
    padding: 0 em(12);
    margin-bottom: em(48);
    background: #fff;
}

.resolving-problems-landing-intro .intro-text p {
    margin-bottom: 1em;
}

.resolving-problems-landing-intro .nav-in-this-section li {
    line-height: lh(21);
}

.resolving-problems-landing-intro .nav-in-this-section li a {
    padding-top: em(12);
    padding-bottom: em(14);
}

.resolving-problems-landing-intro .nav-in-this-section li .icon {
    top: em(6, 24);
}

.resolving-problems-landing-intro .nav-in-this-section li .title {
    @extend .gustan-bold;

    margin: 0;
    font-size: inherit;
    line-height: inherit;
}

.resolving-problems-landing-intro h2 {
    padding-bottom: em(26, 25);
    border-bottom: 2px solid $mbiebuilding_gray_3;
    margin: em(46, 25) 0 em(26, 25);
    font-size: em(25);
}

.resolving-problems-landing-row .block-landing-1 {
    margin-bottom: em(46);
}

@media print, screen and (min-width: $mobile_min) {
    .resolving-problems-landing-intro {
        padding: 0 em(30) em(30);
    }

    .resolving-problems-landing-intro .nav-in-this-section li:last-child,
    .resolving-problems-landing-intro .nav-in-this-section li.last {
        border-bottom: none;
    }

    .resolving-problems-landing-intro .nav-in-this-section li a {
        padding-top: em(21);
        padding-bottom: em(22);
    }

    .resolving-problems-landing-intro .nav-in-this-section li .icon {
        top: em(17, 24);
    }

    .resolving-problems-landing-intro .nav-in-this-section li .title {
        font-size: em(20);
        line-height: lh(26, 20);
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .resolving-problems-landing-intro .layout-col {
        float: none;
        width: auto;
    }
}

@media only screen and (min-width: $tablet_min) {
    .resolving-problems-landing-intro {
        padding: 0 em(39) em(30);
        margin-bottom: em(60);
    }

    .resolving-problems-landing-intro .intro-text {
        margin: em(46, 20) 0 em(10, 20);
    }

    .resolving-problems-landing-intro .nav-in-this-section {
        margin-top: em(-10);
    }

    .resolving-problems-landing-intro .nav-in-this-section .title {
        font-size: em(20);
        line-height: lh(26, 20);
    }

    .resolving-problems-landing-intro .layout-col-8 {
        width: 59%;
        margin-right: 4%;
    }

    .resolving-problems-landing-intro .layout-col-4 {
        width: 37%;
    }

    .resolving-problems-landing-intro h2 {
        padding-bottom: em(24, 30);
        margin: em(41, 30) 0 em(19, 30);
        font-size: em(30);
    }

    .resolving-problems-landing-intro .nav-landing-list {
        margin-top: em(-5);
    }
}


/* Managing buildings landing page
 ---------------------------------------
*/

.building-code-landing-row h2 {
    padding-bottom: em(26, 30);
    border-bottom: 2px solid $mbiebuilding_gray_7;
}

.building-code-landing-row.block-landing-1-row {
    border-top: 2px solid $mbiebuilding_gray_7;
}

@media print, screen and (min-width: $mobile_min) {
    .managing-buildings-landing-row.block-landing-news-row {
        border-bottom: 2px solid $mbiebuilding_gray_7;
        margin: em(32) 0 em(45);
    }

    .building-code-landing-row {
        margin-top: em(-16) 0 0;
    }

    .building-code-landing-row.block-landing-1-row {
        padding-top: rem(26);
    }

    .building-code-landing-row.block-landing-1-row,
    .building-code-landing-row.block-landing-2-row {
        margin: 0;
    }

    .building-code-landing-row h2 {
        padding-bottom: em(26, 34);
    }

    .resolving-problems-landing-row .block-landing-1 {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: $tablet_min) {
    .managing-buildings-landing-row.block-landing-news-row {
        margin: em(60) 0 em(56);
    }

    .managing-buildings-landing-row.block-landing-news-row .block-news-item {
        margin-bottom: em(19);
    }

    .building-code-landing-row {
        margin-top: em(25);
    }

    .building-code-landing-row.block-landing-1-row {
        padding-top: rem(36);
    }

    .building-code-landing-row.block-landing-1-row,
    .building-code-landing-row.block-landing-2-row {
        margin: 0 0 em(36);
    }

    .building-code-landing-row h2 {
        padding-bottom: em(26, 36);
    }
}


/* Projects and consent landing page
 ---------------------------------------
*/

.projects-consents-landing-intro {
    padding: em(29) 0 0;
    color: $mbiebuilding_gray_5;
}

.projects-consents-landing-intro .nav-stepped-anchors {
    padding-bottom: em(11);
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin: em(8) 0 em(30);
}

.projects-consents-landing h2 {
    margin-bottom: em(29, 30);
}

.projects-consents-landing .updates {
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin-bottom: em(25);
}

.projects-consents-landing .updates .block-news-item {
    margin-bottom: 0;
}

@media print, screen and (min-width: $mobile_min) {
    .projects-consents-landing h2 {
        margin-bottom: em(27, 34);
        text-align: center;
    }

    .projects-consents-landing .updates .block-news-item {
        margin-bottom: em(5);
    }

    .projects-consents-landing .updates .block-news-item .image {
        float: left;
        width: 47.75%;
        margin-right: 4.5%;
    }

    .projects-consents-landing .updates .block-news-item.with-image .liner {
        float: left;
        width: 47.75%;
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .projects-consents-landing-intro .layout-col-8 {
        float: left;
        width: 47%;
    }

    .projects-consents-landing-intro .layout-col-4 {
        float: left;
        width: 47.5%;
        margin-top: em(-4);
        margin-left: 5.3%;
    }

    .projects-consents-landing-intro .nav-stepped-anchors {
        padding-bottom: em(39);
    }
}

@media only screen and (min-width: $tablet_min) {
    .projects-consents-landing-intro {
        padding: em(50) 0 em(46);
    }

    .projects-consents-landing-intro p {
        margin-bottom: em(22);
    }

    .projects-consents-landing-intro .layout-col-8 {
        width: 70%;
        margin-right: 4.8%;
    }

    .projects-consents-landing-intro .layout-col-4 {
        width: 25.2%;
        margin: em(-7) 0 0;
    }

    .projects-consents-landing-intro .nav-stepped-anchors {
        padding-bottom: em(40);
        margin: em(4) 0 0;
    }

    .projects-consents-landing h2 {
        margin-bottom: em(37, 36);
    }

    .projects-consents-landing .updates {
        margin-bottom: em(47);
    }

    .projects-consents-landing .updates .block-news-item {
        padding-bottom: em(7);
        margin-bottom: 0;
    }
}


/* Getting started landing page
 ---------------------------------------
*/

.getting-started-landing-intro {
    padding: em(29) 0 em(11);
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin-bottom: em(30);
    color: $mbiebuilding_gray_5;
}

.getting-started-landing h2 {
    margin-bottom: em(29, 30);
}

.getting-started-landing-intro .h3 p {
    font-size: inherit;
}

.getting-started-landing-intro .image {
    margin-bottom: em(20);
}

.getting-started-landing-intro .image img {
    width: 100%;
    height: auto;
}

.getting-started-landing-intro .nav-stepped-anchors {
    margin: em(8) 0 0;
}

.getting-started-landing .updates {
    border-bottom: 2px solid $mbiebuilding_gray_2;
    margin-bottom: em(25);
}

.getting-started-landing .updates .block-news-item {
    margin-bottom: 0;
}

@media print, screen and (min-width: $mobile_min) {
    .getting-started-landing h2 {
        margin-bottom: em(27, 34);
        text-align: center;
    }

    .getting-started-landing-intro .nav-stepped-anchors li {
        width: 31.6%;
    }

    .getting-started-landing-intro .nav-stepped-anchors li:last-child,
    .getting-started-landing-intro .nav-stepped-anchors li.last {
        margin-right: 0;
    }

    .getting-started-landing-intro .nav-stepped-anchors li a {
        min-height: em(44, 18);
    }

    .getting-started-landing-intro .nav-stepped-anchors li:last-child a,
    .getting-started-landing-intro .nav-stepped-anchors li.last a {
        padding-right: .5em;
        padding-left: .5em;
    }

    .getting-started-landing .updates .block-news-item {
        margin-bottom: em(5);
    }

    .getting-started-landing .updates .block-news-item .image {
        float: left;
        width: 47.75%;
        margin-right: 4.5%;
    }

    .getting-started-landing .updates .block-news-item.with-image .liner {
        float: left;
        width: 47.75%;
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: 950px) {
    .getting-started-landing-intro {
        padding: em(33) 0 em(29);
    }

    .getting-started-landing-intro .image {
        margin: em(-2) 0 0 0;
    }

    .getting-started-landing-intro .layout-col-3 {
        float: left;
        width: 46.5%;
        margin-right: 5.3%;
        margin-bottom: em(31);
    }

    .getting-started-landing-intro .layout-col-9 {
        float: none;
        width: auto;
    }

    .getting-started-landing-intro .layout-col-9 .content-wrapper {
        float: left;
        width: 46.5%;
    }

    .getting-started-landing-intro .layout-col-9 .clear {
        display: none;
    }

    .getting-started-landing-intro .h3 {
        clear: both;
    }

    .getting-started-landing-intro .h3::after {
        display: block;
        content: '';
        clear: both;
        width: 100%;
    }

    .getting-started-landing-intro .nav-stepped-anchors {
        clear: left;
        margin: em(20) 0 em(10);
    }

    .getting-started-landing-intro .nav-stepped-anchors li {
        width: 31.3%;
        margin-right: 3%;
    }

    .getting-started-landing-intro .nav-stepped-anchors li:nth-child(2n) {
        margin-right: 3%;
    }

    .getting-started-landing-intro .nav-stepped-anchors li.nth-child-2 {
        margin-right: 3%;
    }

    .getting-started-landing-intro .nav-stepped-anchors li a {
        padding-right: .5em;
        padding-left: .5em;
    }
}

@media only screen and (min-width: 950px) {
    .getting-started-landing-intro {
        padding: em(58) 0 em(29);
    }

    .getting-started-landing h2 {
        margin-bottom: em(37, 36);
    }

    .getting-started-landing-intro .layout-col-3 {
        width: 23%;
        margin: em(-4) 0 0;
    }

    .getting-started-landing-intro .layout-col-9 {
        width: 74.5%;
        margin-left: 2.5%;
    }

    .getting-started-landing-intro .h3 {
        margin: em(33, 25) 0 0;
    }

    .getting-started-landing-intro .h3 p {
        margin: 0;
    }

    .getting-started-landing-intro p {
        margin-bottom: em(22);
    }

    .getting-started-landing-intro .nav-stepped-anchors {
        margin-top: em(25);
    }

    .getting-started-landing .updates {
        margin-bottom: em(47);
    }

    .getting-started-landing .updates .block-news-item {
        padding-bottom: em(7);
        margin-bottom: 0;
    }
}

/* Getting to grips (IFRAME)
 ---------------------------------------
*/

.iframe-getting-to-grips iframe {
    display: block;
    margin: em(62) auto;
}


/* Determinations listing page
 ---------------------------------------
*/

.determinations-listing .h1-wrap h1 {
    margin: em(7, 26) 0 em(30, 26);
    font-size: em(26);
    line-height: lh(27, 26);
}

.determinations-listing .h1-wrap .intro-text {
    margin-top: em(20, 18);
    margin-top: em(30, 18);
}

.determinations-listing h2 {
    padding: em(13, 25) 0 em(16, 25);
    border-bottom: 3px solid $mbiebuilding_gray_7;
    font-size: em(25);
}

.determinations-listing .block-documents-list h2 {
    border-bottom-color: $mbiebuilding_blue_4;
}

.determinations-listing .block-documents-list .block-publication-promo-content {
    margin-bottom: em(36);
}

.determinations-listing .block-documents-list .block-publication-promo-content .description {
    padding-bottom: em(15);
    border-bottom: none;
}

.determinations-listing .determination-listing-item .more-detail {
    padding-top: 0;
    padding-bottom: em(16);
    border-top: none;
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin-top: 0;
}

.determinations-listing .guidance-document-overview-text {
    margin-top: em(36);
}

@media print, screen and (max-width: $mobile_max) {
    .page-maincontent .determinations-listing .page-options,
    .page-maincontent .guidance-document .page-options {
        margin-top: em(20);
        margin-bottom: em(20);
    }

    .page-maincontent .guidance-video .page-options {
        margin-top: em(20);
        margin-bottom: 0;
    }
}

@media print, screen and (min-width: $mobile_min) {
    .determinations-listing .h1-wrap h1 {
        margin: 0 0 em(22, 36) 0;
        font-size: em(36);
        line-height: lh(40, 36);
    }

    .determinations-listing .h1-wrap .intro-text {
        margin: 0 0 em(25, 20);
        font-size: em(20);
        line-height: lh(27, 20);
    }

    .determinations-listing h2 {
        padding: 0 0 em(25, 34);
        margin: 0;
        font-size: em(34);
        line-height: lh(39, 34);
    }

    .determinations-listing .block-documents-list {
        margin-top: em(42);
    }

    .determinations-listing .block-documents-list .block-publication-promo-content {
        padding: em(37) em(28) 0;
    }

    .determinations-listing .determination-listing-item .more-detail {
        padding-bottom: em(2);
        margin-top: em(20);
    }

    .determinations-listing .guidance-document-overview-text {
        margin-top: em(42);
    }

    .determinations-listing .guidance-document-overview-text h2 {
        padding-bottom: em(22, 34);
        border-bottom: none;
    }

    .determinations-listing .guidance-document-overview-text p,
    .determinations-listing .guidance-document-overview-text li {
        font-size: em(14);
        line-height: lh(24, 14);
    }

    .determinations-listing .guidance-document-overview-text p {
        margin-bottom: em(20, 14);
    }

    .determinations-listing .guidance-document-overview-text .divider {
        margin: em(51) 0;
    }
}

@media only screen and (min-width: $tablet_min) {
    .determinations-listing .h1-wrap h1 {
        margin: 0 0 em(21, 38);
        font-size: em(38);
        line-height: lh(40, 38);
    }

    .determinations-listing .h1-wrap .intro-text {
        margin: em(18, 20) 0 em(30, 20);
    }

    .determinations-listing h2 {
        padding: 0 0 em(25, 36);
        font-size: em(36);
        line-height: lh(40, 36);
    }

    .determinations-listing .layout-col-4 {
        width: 34%;
    }

    .determinations-listing .layout-col-7 {
        width: 57.3%;
        margin-left: 3.5%;
    }

    .determinations-listing .block-documents-list {
        margin-top: 0;
    }

    .determinations-listing .block-documents-list .block-publication-promo-content {
        padding: em(16) em(8) em(18);
    }

    .determinations-listing .determination-listing-item .more-detail .document-metadata dt {
        width: 29.6%;
    }

    .determinations-listing .determination-listing-item .more-detail .document-metadata dd {
        width: 70%;
    }

    .determinations-listing .guidance-document-overview-text {
        margin-top: em(54);
    }

    .determinations-listing .guidance-document-overview-text .divider {
        margin: em(76) 0 em(60);
    }
}


/* Guidance overview page
 ---------------------------------------
*/

.guidance-overview .layout-col-1 {
    margin-right: 2.15%;
}


/* Guidance documents page
 ---------------------------------------
*/

.guidance-document h2 {
    padding-bottom: em(14, 30);
    border-bottom: 3px solid $mbiebuilding_gray_2;
}

.guidance-document .block-version-control-table {
    padding: 1em;
}

.guidance-document .guidance-document-overview-text {
    @extend .gustan-light;
}

.guidance-document .guidance-document-overview-text h3 {
    margin-bottom: em(18, 22);
}

.guidance-document .guidance-document-overview-text p,
.guidance-document .guidance-document-overview-text li {
    font-size: em(16);
    line-height: lh(24);
}

.guidance-document .guidance-document-overview-text ul {
    margin: em(7) 0 em(21) em(60);
    list-style: none;
}

.guidance-document .guidance-document-overview-text ul li {
    position: relative;
    margin: 0 0 em(10, 16);
}

.guidance-document .guidance-document-overview-text ul li::before {
    @include border-radius(50%);

    position: absolute;
    top: em(10);
    left: em(-19);
    content: '';
    width: em(6);
    height: em(6);
    background: $mbiebuilding_gray_3;
    font-size: em(16, 16);
}

.guidance-document .guidance-document-overview-text ol {
    margin: em(7) 0 em(21) em(39);
    list-style: none;
    counter-reset: section;
}

.guidance-document .guidance-document-overview-text ol li::before {
    display: inline-block;
    content: counters(section, '.') '. ';
    padding-right: em(4);
    counter-increment: section;
}

.guidance-document .block-document-meta {
    padding-bottom: em(15);
    border-bottom: 1px solid $mbiebuilding_gray_2;
}

@media only screen and (max-width: $mobile_max) {
    .guidance-document .block-document-meta li {
        margin-bottom: em(10, 14);
        font-size: em(14);
        line-height: 1.43;
    }
}

@media print, screen and (min-width: $mobile_min) {
    .guidance-document h2 {
        padding-bottom: em(14, 34);
    }

    .guidance-document .guidance-document-overview-text h3 {
        margin-bottom: em(18, 26);
        font-size: em(26);
    }

    .guidance-document .divider {
        margin: em(64) 0 em(50);
    }
}

@media only screen and (min-width: $tablet_min) {
    .guidance-document h2 {
        padding-bottom: em(25, 36);
    }

    .guidance-document .link-back-1 {
        margin: em(17, 18) 0 em(5, 18);
    }

    .guidance-document .layout-col-4 {
        width: 34%;
        margin-right: 6%;
    }

    .guidance-document .layout-col-7 {
        width: 57.5%;
        margin-right: 0;
    }

    .guidance-document .guidance-document-overview-text h3 {
        margin-bottom: em(27, 34);
        font-size: em(34);
    }
}


/*
 FOOTER
---------------------------------------
*/

.page-footer {
    margin-top: em(60);
    background: $mbiebuilding_gray_5;
    color: #fff;
}

.page-footer .page-wrapper {
    overflow: hidden;
    padding: em(3) em(15);
}

.page-footer .layout-row {
    padding: 0 0 em(26);
    border-bottom: 1px solid #fff;
}

.page-footer .layout-row:last-child,
.page-footer .layout-row.last {
    border: none;
}

.page-footer a,
.page-footer h2,
.page-footer h3 {
    color: #fff;
}

.page-footer h2,
.page-footer h3 {
    margin: em(38, 14) 0 em(30, 14);
    font-size: em(14);
    text-transform: uppercase;
}

.page-footer h3 {
    margin: em(33, 14) 0 0;
}

/* nav footer */

.nav-footer {
    padding: 0 0 em(1);
}

.nav-footer li {
    margin: em(7) 0;
    line-height: lh(22);
}

.nav-footer li a {
    display: block;
}

.nav-footer.last {
    margin: em(21) 0;
}

.nav-footer.last li {
    margin: em(10, 14) 0;
    font-size: em(14);
    text-transform: uppercase;
}

.nav-footer.last .copyright {
    margin-top: em(32, 14);
    text-transform: none;
}

/* Social links */

.page-footer .social-links {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin: em(-7) 0 em(7) em(-11);
}

@media only screen and (min-width: $desktop_min) {
    .page-footer .social-links {
        flex-direction: column;
    }
}

.page-footer .social-links li {
    padding: em(8, 21) em(12, 21);
    margin: 0;
}

.page-footer .social-links a {
    display: block;
}

.page-footer .social-links .linkedin {
    padding: em(8, 23) em(11, 23);
}

.page-footer .social-links .twitter {
    padding-top: em(10, 21);

    a::before {
        font-size: em(20);
    }
}

.page-footer .social-links .youtube {
    padding: em(8, 23) em(11, 23);

    a::before {
        font-size: em(22);
    }
}

/* Footer logos */

.footer-logos {
    display: flex;
    justify-content: space-between;
    margin: em(56) 0 em(26);
}

.footer-logos li {
    margin: em(10) 0;
}

.footer-logos img {
    display: block;
}

.footer-logos img.print {
    display: none;
}

.footer-logos .mbie {
    margin-left: em(1);
}

@media screen and (max-width: $mobile_min) {
    .footer-logos {
        display: flex;
        flex-direction: column;
    }
}

/* Tablet & desktop (shared) */
@media print, screen and (min-width: $mobile_min) {
    /* About us */

    .page-footer .about-us {
        position: absolute;
        top: em(79);
        right: 0;
        width: em(163);
        border-right: none;
    }

    /* Footer logos */

    .footer-logos {
        overflow: hidden;
        margin: em(8) 0 0 0;
    }

    .footer-logos .nz-govt img {
        float: right;
        width: auto;
        height: 47px;
        margin: em(2) em(43) em(2) 0;
    }

    .nav-footer.last {
        position: relative;
        overflow: hidden;
        margin: 0;
    }

    .nav-footer.last .copyright {
        position: absolute;
        top: em(6, 14);
        right: 0;
        margin: 0;
        text-transform: none;
    }
}

/* Tablet only */
@media print, screen and (min-width: $mobile_min) and (max-width: $desktop_max) {
    .page-footer .page-wrapper {
        padding: 0 em(30);
    }

    .page-footer .about-us {
        top: 0;
        right: em(30);
        width: em(241);
    }

    .page-footer .about-us h2 {
        margin-bottom: em(26, 14);
    }

    .page-footer .layout-row.first {
        padding-bottom: em(40);
    }

    .page-footer .layout-row.second {
        border: none;
    }

    .page-footer .layout-row.second .layout-col-3 {
        float: left;
        width: 33%;
        margin-right: 0;
    }

    .page-footer .layout-row.second .nav-footer {
        padding-right: em(20);
    }

    .page-footer .layout-row.third {
        padding-bottom: em(10);
    }

    .page-footer h3 {
        margin: em(2, 14) 0 em(9, 14);
    }

    .footer-logos .nz-govt img {
        width: em(188);
        margin: 0 em(3) 0 0;
    }

    .page-footer .layout-row.last {
        padding-top: em(16);
    }

    .nav-footer.last .copyright {
        top: em(11, 14);
        text-transform: none;
    }
}

/* Desktop only */
@media only screen and (min-width: $desktop_min) {
    .page-footer .page-wrapper {
        padding: 0;
    }

    .page-footer .layout-row {
        min-height: em(232);
        padding: 0;
        border-right: 1px solid #fff;
        border-bottom: none;
        margin: em(79) 0 0;
    }

    .page-footer .layout-row.first {
        float: left;
        clear: none;
        width: 20.5%;
        margin-left: em(44);
    }

    .page-footer .layout-row.first .layout-col {
        width: auto;
    }

    .page-footer .layout-row.second {
        float: left;
        clear: none;
        width: 51.5%;
        padding-left: em(38);
    }

    .page-footer .layout-row.second .layout-col {
        width: 29.5%;
    }

    .page-footer .layout-row.second .nav-footer {
        padding-right: em(30);
    }

    .page-footer .layout-row.third {
        min-height: inherit;
        border: none;
        margin: 0 0 0 em(38);
    }

    .page-footer h2 {
        margin: 0 0 em(33, 12);
        font-size: em(12);
    }

    .page-footer h3 {
        margin: 0 0 em(10, 12);
        font-size: em(12);
    }

    .nav-footer li {
        margin: em(7, 18) 0;
        font-size: em(14);
        line-height: 1.43;
    }

    /* About us */

    .page-footer .about-us {
        position: absolute;
        top: em(79);
        right: 0;
        width: em(163);
        border-right: none;
    }

    .nav-footer {
        padding: 0;
    }

    /* Footer logos */

    .page-footer .layout-row.last {
        min-height: inherit;
        padding-bottom: em(20);
        border-top: 1px solid #fff;
        border-right: none;
        margin: 0 em(45);
    }

    .footer-logos {
        margin: em(14) 0 em(3) em(7);
    }

    .nav-footer.last li {
        float: left;
        margin: 0 em(20, 13) 0 0;
        font-size: em(13);
    }

    .nav-footer.last li a {
        padding: em(6, 13) 0;
    }

    .nav-footer.last .copyright {
        top: em(6, 13);
        text-transform: none;
    }
}


/*
 ICONS
---------------------------------------
*/

.icon-phone::before {
    content: '\e800';
}

/* '' */

.icon-mail::before {
    content: '\e801';
}

/* '' */

.icon-globe::before {
    content: '\e802';
}

/* '' */

.icon-angle-left::before {
    content: '\e803';
}

/* '' */

.icon-angle-right::before {
    content: '\e804';
}

/* '' */

.icon-angle-up::before {
    content: '\e805';
}

/* '' */

.icon-angle-down::before {
    content: '\e806';
}

/* '' */

.icon-document::before {
    content: $icon-document;
}

/* '' */

.icon-expand::before {
    content: '\e808';
}

/* '' */

.icon-pdf3::before {
    content: '\e809';
}

/* '' */

.icon-house::before {
    content: '\e80a';
}

/* '' */

.icon-tool::before {
    content: $icon-tool;
}

/* '' */

.icon-play::before {
    content: '\e80c';
}

/* '' */

.icon-up::before {
    content: '\e80d';
}

/* '' */

.icon-right::before {
    content: '\e80e';
}

/* '' */

.icon-left::before {
    content: '\e80f';
}

/* '' */

.icon-down::before {
    content: '\e810';
}

/* '' */

.icon-facebook::before {
    content: '\e811';
    width: 15px;
    padding-right: 15px;
}

/* '' */

.icon-twitter::before {
    content: '\e812';
    width: 15px;
    padding-right: 15px;
}

/* '' */

.icon-youtube::before {
    content: '\e813';
    width: 15px;
    padding-right: 15px;
}

/* '' */

.icon-linkedin::before {
    content: '\e814';
    width: 15px;
    padding-right: 15px;
}

/* '' */

.icon-pdf2::before {
    content: '\e815';
}

/* '' */

.icon-minus::before {
    content: '\e816';
}

/* '' */

.icon-contact-us::before {
    content: '\e817';
}

/* '' */

.icon-calendar::before {
    content: '\e818';
}

/* '' */

.icon-star::before {
    content: '\e819';
}

/* '' */

.icon-external::before {
    content: $icon-external;
}

/* '' */

.icon-cross::before {
    content: '\e81b';
}

/* '' */

.icon-tick::before {
    content: '\e81c';
}

/* '' */

.icon-share::before {
    content: '\e81d';
}

/* '' */

.icon-plus-1::before {
    content: '\e81e';
}

/* '' */

.icon-powerpoint::before {
    content: '\e81f';
}

/* '' */

.icon-word::before {
    content: $icon-word;
}

/* '' */

.icon-excel::before {
    content: $icon-excel;
}

/* '' */

.icon-download::before {
    content: '\e822';
}

/* '' */

.icon-back-to-top::before {
    content: '\e823';
}

/* '' */

.icon-document3::before {
    content: '\e824';
}

/* '' */

.icon-print::before {
    content: '\e825';
}

/* '' */

.icon-magnify-glass::before {
    content: '\e826';
}

/* '' */

.icon-calculator::before {
    content: '\e827';
}

/* '' */

.icon-document2::before {
    content: $icon-document2;
}

/* '' */

.icon-rss::before {
    content: '\e829';
}

/* '' */

.icon-plus::before {
    content: '\e82a';
}

/* '' */

.icon-contact-add::before {
    content: '\e82b';
}

/* '' */

.icon-minus-1::before {
    content: '\e82c';
}

/* '' */

.icon-gplus::before {
    content: '\e82d';
}

/* '' */

.icon-burger::before {
    content: '\e82e';
}

/* '' */

.icon-alert2::before {
    content: '\e82f';
}

/* '' */

.icon-close::before {
    content: $icon-close;
}

/* '' */

.icon-pdf::before {
    content: '\e831';
}

/* '' */

.icon-lightbulb2::before {
    content: '\e832';
}

/* '' */

.icon-lightbulb::before {
    content: '\e833';
}

/* '' */

.icon-info::before {
    content: '\e834';
}

/* '' */

.icon-alert::before {
    content: '\e835';
}

/* '' */

.icon-hut::before {
    content: '\e836';
}

/* '' */

.icon-house2::before {
    content: $icon-house2;
}

/* '' */

.icon-dam::before {
    content: '\e838';
}

/* '' */

.icon-lightbulb3::before {
    content: '\e839';
}

/* '' */

.icon-minus-circle::before {
    content: '\e83a';
}

/* '' */

.icon-plus-circle::before {
    content: '\e83b';
}

/* '' */

/* Custom */

.icon-house::before,
.icon-sb::before {
    content: $icon-house2;
}

.icon-doc::before,
.icon-docx::before {
    content: $icon-word;
}

.icon-xls::before,
.icon-xlsx::before {
    content: $icon-excel;
}

/* Document icons */

.icon-pdf {
    position: relative;
    width: em(33, 25);
    height: em(44, 25);
    border: 1px solid $mbiebuilding_red_2;
    background-color: $mbiebuilding_red_2;
    font-size: em(25);
    line-height: em(42, 25);
    color: #fff;
    text-align: center;
}

/* keep font size in px */

.icon-doc,
.icon-docx,
.icon-xls,
.icon-xlsx {
    position: relative;
    font-size: em(25);
    line-height: 1;
    color: $mbiebuilding_red_2;
    text-align: center;
}

/* keep font size in px */

/*
 TRANSITIONS
---------------------------------------
*/

.link-back-to-top a,
.sharing-popup,
.dropdown,
.dropdown div,
.dropdown li,
.dropdown div::after,
.nav-secondary a,
.gallery-modal .close-modal,
.enlarge-image a img,
.enlarge-image a::before,
.nav-stepped-anchors li a,
#mobile-menu-container .child,
.icon-pdf,
.code-icon,
input.text,
textarea {
    @include transition(all, .2s, ease-in-out);
}

.no-csspseudotransitions .link-back-to-top a,
.no-csspseudotransitions .sharing-popup,
.no-csspseudotransitions .dropdown,
.no-csspseudotransitions .dropdown div,
.no-csspseudotransitions .dropdown li,
.no-csspseudotransitions .dropdown div::after,
.no-csspseudotransitions .button,
.no-csspseudotransitions button,
.no-csspseudotransitions .nav-secondary a,
.no-csspseudotransitions .gallery-modal .close-modal,
.no-csspseudotransitions .enlarge-image a img,
.no-csspseudotransitions .enlarge-image a::before,
.no-csspseudotransitions .nav-stepped-anchors li a,
.no-csspseudotransitions #mobile-menu-container .child,
.no-csspseudotransitions .icon-pdf,
.no-csspseudotransitions .code-icon,
.no-csspseudotransitions input.text,
.no-csspseudotransitions textarea {
    -webkit-transition: none;
    transition: none;
}

/*
 BUILDING CODE COLOURS
---------------------------------------
*/

.building-code-a .h1-wrap::before {
    background: $building_code_a;
}

.building-code-b .h1-wrap::before {
    background: $building_code_b;
}

.building-code-c .h1-wrap::before {
    background: $building_code_c;
}

.building-code-d .h1-wrap::before {
    background: $building_code_d;
}

.building-code-e .h1-wrap::before {
    background: $building_code_e;
}

.building-code-f .h1-wrap::before {
    background: $building_code_f;
}

.building-code-g .h1-wrap::before {
    background: $building_code_g;
}

.building-code-h .h1-wrap::before {
    background: $building_code_h;
}

.building-code-sb .h1-wrap::before {
    background: $building_code_sb;
}

.building-code-a .amendment .heading-wrap,
.building-code-a .nav-in-this-document .nav-title,
.building-code-a .block-documents-list h2,
.tag.section-a,
a.tag.section-a:hover,
a.tag.section-a:focus {
    border-color: $building_code_a;
}

.building-code-b .amendment .heading-wrap,
.building-code-b .nav-in-this-document .nav-title,
.building-code-b .block-documents-list h2,
.tag.section-b,
a.tag.section-b:hover,
a.tag.section-b:focus {
    border-color: $building_code_b;
}

.building-code-c .amendment .heading-wrap,
.building-code-c .nav-in-this-document .nav-title,
.building-code-c .block-documents-list h2,
.tag.section-c,
a.tag.section-c:hover,
a.tag.section-c:focus {
    border-color: $building_code_c;
}

.building-code-d .amendment .heading-wrap,
.building-code-d .nav-in-this-document .nav-title,
.building-code-d .block-documents-list h2,
.tag.section-d,
a.tag.section-d:hover,
a.tag.section-d:focus {
    border-color: $building_code_d;
}

.building-code-e .amendment .heading-wrap,
.building-code-e .nav-in-this-document .nav-title,
.building-code-e .block-documents-list h2,
.tag.section-e,
a.tag.section-e:hover,
a.tag.section-e:focus {
    border-color: $building_code_e;
}

.building-code-f .amendment .heading-wrap,
.building-code-f .nav-in-this-document .nav-title,
.building-code-f .block-documents-list h2,
.tag.section-f,
a.tag.section-f:hover,
a.tag.section-f:focus {
    border-color: $building_code_f;
}

.building-code-g .amendment .heading-wrap,
.building-code-g .nav-in-this-document .nav-title,
.building-code-g .block-documents-list h2,
.tag.section-g,
a.tag.section-g:hover,
a.tag.section-g:focus {
    border-color: $building_code_g;
}

.building-code-h .amendment .heading-wrap,
.building-code-h .nav-in-this-document .nav-title,
.building-code-h .block-documents-list h2,
.tag.section-h,
a.tag.section-h:hover,
a.tag.section-h:focus {
    border-color: $building_code_h;
}

.building-code-sb .amendment .heading-wrap,
.building-code-sb .nav-in-this-document .nav-title,
.building-code-sb .block-documents-list h2,
.tag.section-sb,
a.tag.section-sb:hover,
a.tag.section-sb:focus {
    border-color: $building_code_sb;
}

a.tag.section-a:hover,
a.tag.section-a:focus {
    background-color: tint($building_code_a, 50%);
}

a.tag.section-b:hover,
a.tag.section-b:focus {
    background-color: tint($building_code_b, 50%);
}

a.tag.section-c:hover,
a.tag.section-c:focus {
    background-color: tint($building_code_c, 50%);
}

a.tag.section-d:hover,
a.tag.section-d:focus {
    background-color: tint($building_code_d, 50%);
}

a.tag.section-e:hover,
a.tag.section-e:focus {
    background-color: tint($building_code_e, 50%);
}

a.tag.section-f:hover,
a.tag.section-f:focus {
    background-color: tint($building_code_f, 50%);
}

a.tag.section-g:hover,
a.tag.section-g:focus {
    background-color: tint($building_code_g, 50%);
}

a.tag.section-h:hover,
a.tag.section-h:focus {
    background-color: tint($building_code_h, 50%);
}

a.tag.section-sb:hover,
a.tag.section-sb:focus {
    background-color: tint($building_code_sb, 50%);
}

a:hover .code-description,
a:focus .code-description {
    text-decoration: underline;
}

.section-a a:hover .code-description,
.section-a a:focus .code-description {
    color: $building_code_a;
}

.section-b a:hover .code-description,
.section-b a:focus .code-description {
    color: $building_code_b;
}

.section-c a:hover .code-description,
.section-c a:focus .code-description {
    color: $building_code_c;
}

.section-d a:hover .code-description,
.section-d a:focus .code-description {
    color: $building_code_d;
}

.section-e a:hover .code-description,
.section-e a:focus .code-description {
    color: $building_code_e;
}

.section-f a:hover .code-description,
.section-f a:focus .code-description {
    color: $building_code_f;
}

.section-g a:hover .code-description,
.section-g a:focus .code-description {
    color: $building_code_g;
}

.section-h a:hover .code-description,
.section-h a:focus .code-description {
    color: $building_code_h;
}

.section-sb a:hover .code-description,
.section-sb a:focus .code-description {
    color: $building_code_sb;
}

.section-specific-buildings a:hover .code-description,
.section-specific-buildings a:focus .code-description {
    color: $mbiebuilding_blue_1;
}


/*
 MISC
---------------------------------------
*/


/* Responsive - generic styles */

.desktop,
.mobile,
.tablet,
.hide {
    position: absolute;
    left: -999em;
    width: 1px;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
}

.print,
.page-break,
.js-ajax-container {
    display: none;
}

.js-click-background-touch {
    cursor: pointer;
}

// For desktops

.visible-mobile {
    display: none !important;
}

.visible-tablet {
    display: none !important;
}

.hidden-desktop {
    display: none !important;
}

@media only screen and (max-width: $mobile_max) {
    .mobile {
        position: inherit;
        left: inherit;
        width: auto;
        overflow: inherit;
        visibility: visible;
        opacity: 1;
    }
    // Show

    .visible-mobile {
        display: inherit !important;
    }
    // Use inherit to restore previous behavior
    // Hide

    .hidden-mobile {
        display: none !important;
    }
    // Hide everything else

    .hidden-desktop {
        display: inherit !important;
    }

    .visible-desktop {
        display: none !important;
    }
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .tablet {
        position: inherit;
        left: inherit;
        width: auto;
        overflow: inherit;
        visibility: visible;
        opacity: 1;
    }
    // Show

    .visible-tablet {
        display: inherit !important;
    }
    // Hide

    .hidden-tablet {
        display: none !important;
    }
    // Hide everything else

    .hidden-desktop {
        display: inherit !important;
    }

    .visible-desktop {
        display: none !important;
    }
}

@media only screen and (min-width: $tablet_min) {
    .desktop {
        position: inherit;
        left: inherit;
        width: auto;
        overflow: inherit;
        visibility: visible;
        opacity: 1;
    }
}


/*
 SPECIFIC STYLES & OVERRIDES
---------------------------------------
*/

@import 'partials/accordion';
@import 'partials/all-asvm';
@import 'partials/article-tags';
@import 'partials/form-elements';
@import 'partials/guidance';
@import 'partials/block-news';
@import 'partials/block-news-item';
@import 'partials/block-alert02';
@import 'partials/block-alert03';
@import 'partials/block-publication-promo';
@import 'partials/block-signup';
@import 'partials/block-building-code';
@import 'partials/block-code-nav-bar';
@import 'partials/block-video';
@import 'partials/building-code-compliance';
@import 'partials/building-highlight-note';
@import 'partials/campaign-intro';
@import 'partials/code-clause-container';
@import 'partials/code-icon';
@import 'partials/error-pages';
@import 'partials/essential-links';
@import 'partials/featured-tiles';
@import 'partials/hero';
@import 'partials/homepage-hero';
@import 'partials/landing-intro';
@import 'partials/link-block';
@import 'partials/mega-navs';
@import 'partials/nav-landing-list';
@import 'partials/nav-pagination02';
@import 'partials/news-item';
@import 'partials/news-listing';
@import 'partials/page-options';
@import 'partials/popular-links';
@import 'partials/rating-feedback';
@import 'partials/search-result-metadata';
@import 'partials/search-sort';
@import 'partials/secondary-tiles';
@import 'partials/section-wrapper';
@import 'partials/skip-links';
@import 'partials/subsection';
@import 'partials/social-share-kit';
@import 'partials/tables';
@import 'partials/tag';
@import 'partials/tag-list';
@import 'partials/tip-box';
@import 'partials/update-event';
@import 'partials/video-element';

/*
 IE underline bug
---------------------------------------
*/

.link-back-1 .icon-left::before {
    height: em(14);
    overflow: hidden;
}

.block-landing-2 .description .icon::before {
    height: em(12, 14);
    overflow: hidden;
}

/* stylelint-disable */

.block-landing-3-row li a::before {
    position: absolute;
    top: em(7, 19);
    left: em(2, 19);
    content: '\e804';
    height: em(16, 19);
    overflow: hidden;
    font-size: em(19);
    color: $mbiebuilding_blue_3;
}

/* stylelint-enable */

.expand-item .icon-plus::before {
    height: 13px;
    overflow: hidden;
}

.code-clause-view-all .icon-angle-right::before {
    height: em(14);
    overflow: hidden;
}

.block-campaign-widget .block-campaign-widget-item a .description .faux-link::before {
    height: em(14);
    overflow: hidden;
}

.nav-mega ul.mega-nav-secondary li a::before {
    height: em(16, 19);
    overflow: hidden;
}

.codewords-landing-previous-issues .show-more .icon::before {
    height: 13px;
    overflow: hidden;
}
