@use 'sass:math';

/* Code clauses popup layer */

.code-clause-container {
    @include box-sizing(border-box);

    padding: rem(20);
    border-top: rem(4) solid transparent;
    border-bottom: rem(4) solid transparent;
    margin: rem(13) -#{rem(20)};
    background: $mbiebuilding_white_1;

    @supports (display: grid) {
        margin: rem(13) 0;
    }

    &.content-a {
        grid-area: contentRow1;
        border-color: $building_code_a;
    }

    &.content-b {
        grid-area: contentRow1;
        border-color: $building_code_b;
    }

    &.content-c {
        grid-area: contentRow2;
        border-color: $building_code_c;

        @media (min-width: 391px) {
            grid-area: contentRow1;
        }
    }

    &.content-d {
        grid-area: contentRow2;
        border-color: $building_code_d;

        @media (min-width: 510px) {
            grid-area: contentRow1;
        }
    }

    &.content-e {
        grid-area: contentRow3;
        border-color: $building_code_e;

        @media (min-width: 391px) {
            grid-area: contentRow2;
        }

        @media (min-width: 700px) {
            grid-area: contentRow1;
        }
    }

    &.content-f {
        grid-area: contentRow3;
        border-color: $building_code_f;

        @media (min-width: 391px) {
            grid-area: contentRow2;
        }

        @media (min-width: 830px) {
            grid-area: contentRow1;
        }
    }

    &.content-g {
        grid-area: contentRow4;
        border-color: $building_code_g;

        @media (min-width: 391px) {
            grid-area: contentRow3;
        }

        @media (min-width: 510px) {
            grid-area: contentRow2;
        }

        @media (min-width: 960px) {
            grid-area: contentRow1;
        }
    }

    &.content-h {
        grid-area: contentRow4;
        border-color: $building_code_h;

        @media (min-width: 391px) {
            grid-area: contentRow3;
        }

        @media (min-width: 510px) {
            grid-area: contentRow2;
        }

        @media (min-width: 1090px) {
            grid-area: contentRow1;
        }
    }

    &.content-specific-buildings {
        grid-area: contentRow5;
        border-color: $mbiebuilding_blue_1;

        @media (min-width: 391px) {
            grid-area: contentRow4;
        }

        @media (min-width: 510px) {
            grid-area: contentRow3;
        }

        @media (min-width: 700px) {
            grid-area: contentRow2;
        }

        @media (min-width: 1220px) {
            grid-area: contentRow1;
        }
    }

    .code-clause {
        display: flex;
        padding: rem(12.5) 0;
        color: $mbiebuilding_gray_5;
        text-decoration: none;

        &:hover,
        &:focus {
            .code-icon {
                background-color: $mbiebuilding_blue_1;
                color: $mbiebuilding_blue_1;
            }

            .clause {
                text-decoration: underline;
            }
        }
    }

    .code-icon {
        flex-shrink: 0;
        width: rem(80);
        height: rem(86);
        font-size: 2.7em;
    }

    .clause-description {
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: rem(18);
        font-size: rem(14);
        line-height: lh(17, 14);
        color: $mbiebuilding_gray_4;
    }

    .clause {
        @extend .gustan-black;

        display: block;
        margin-bottom: rem(8);
        font-size: rem(12);
        line-height: lh(13, 12);
        color: $mbiebuilding_blue_1;
        text-transform: uppercase;
    }

    @media (min-width: $mobile_min) {
        padding: rem(15) rem(30);
        margin-right: -#{rem(30)};
        margin-left: -#{rem(30)};

        @supports (display: grid) {
            margin-right: 0;
            margin-left: 0;
        }
    }

    @media screen and (min-width: $tablet_min) {
        .clause-list {
            @include grid-row('flex-start');
        }
    }

    @media screen and (min-width: $tablet_min) and (max-width: $desktop_max) {
        .clause-item {
            @include span-column(math.div(1, 2), rem(40), true);
        }
    }

    @media (min-width: $desktop_min) {
        .clause-list {
            max-width: rem(1150);
            padding-right: rem(30);
            padding-left: rem(30);
            margin-right: auto;
            margin-left: auto;
        }

        .clause-item {
            @include span-column(math.div(1, 3), rem(80), true, 3);
        }

        .code-clause {
            padding: rem(18.5) 0;
        }
    }

    @media (min-width: 1220px) {
        margin: rem(34) -#{rem(30)} 0 -#{rem(30)};

        @supports (display: grid) {
            margin-right: 0;
            margin-left: 0;
        }
    }

    @media print {
        padding-right: 0;
        padding-left: 0;
        margin-top: rem(22) !important;

        .clause-list {
            display: block;
            width: 100%;
            max-width: none;
            padding: 0;
        }

        .clause-item {
            display: block;
            max-width: none;
            margin: 0;
        }

        .code-clause,
        .clause-item {
            page-break-inside: avoid;
        }
    }
}

