.campaign-intro {
    $self: &;

    margin-bottom: rem(35);

    .intro-text {
        font-size: rem(23);
        line-height: lh(26, 23);
    }

    ol,
    ul {
        line-height: lh(26, 23);
    }

    ol {
        margin-left: rem(40);

        li {
            &::before {
                @include box-sizing();

                position: relative;
                left: -#{rem(30)};
                min-width: rem(30);
                padding-right: rem(8);
                margin-right: -#{rem(30)};
            }
        }
    }

    ul {
        margin-left: rem(40);

        li {
            &::before {
                top: .55em;
                left: -.7em;
                width: .2em;
                height: .2em;
            }
        }
    }

    li ol,
    li ul {
        margin-top: rem(15);
        margin-bottom: rem(10);
    }

    li ol {
        margin-left: rem(35);

        > li {
            &::before {
                content: counter(section, lower-alpha) '. ';
            }

            ol,
            ul {
                margin-left: rem(35);
            }

            li {
                &::before {
                    content: counter(section, lower-roman) '. ';
                }
            }
        }
    }

    li ul {
        margin-left: rem(32);

        > li {
            &::before {
                top: initial;
                content: '-';
                width: auto;
                height: auto;
                background-color: transparent;
            }
        }
    }

    li {
        &:not(:first-of-type) {
            margin-top: rem(15);
        }
    }

    .nav-tertiary {
        margin-top: rem(8);

        ul {
            margin-bottom: 0;
        }

        li {
            margin: rem(11) 0;
        }
    }

    .nav-tertiary-heading {
        border-bottom: rem(3) solid $mbiebuilding_gray_7;
        margin-bottom: rem(20);
    }

    @media print, screen and (min-width: $mobile_min) {
        .nav-tertiary li {
            float: none;
            width: auto;
        }
    }

    @media screen and (min-width: $tablet_min) {
        @include sidebar-l(rem(260), rem(40), right);

        margin-top: rem(32);
        margin-bottom: 0;

        .intro-text {
            margin-top: 0;
        }
    }

    @media screen and (min-width: $desktop_min) {
        .intro-text {
            margin-bottom: 0;
            font-size: rem(29);
            line-height: lh(34, 29);
        }

        ol,
        ul {
            line-height: lh(34, 29);
        }

        .nav-tertiary-heading {
            margin-bottom: rem(10);
        }
    }
}
