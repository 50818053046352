
@import '../tools';

/*
 ALL-ASVM - h1-wrap
---------------------------------------
*/

.all-asvm .h1-wrap h1 {
    font-size: em(25, 16);
    line-height: lh(32, 25);
}

@media print, screen and (min-width: $mobile_min) {
    .all-asvm .h1-wrap h1 {
        font-size: em(36, 16);
        line-height: lh(52, 36);
    }
}

/*
 ALL-ASVM - BLOCK ALERT 02 (overruling/adding styles)
---------------------------------------
*/

.all-asvm .block-alert02 {
    padding-left: em(61, 16);
}

@media print, screen and (min-width: $mobile_min) {
    .all-asvm .block-alert02 {
        padding-left: em(99, 16);
        margin-top: em(58, 16);
        margin-bottom: em(33, 16);
    }
}

/*
 ALL-ASVM - AMENDMENT (concertina)
---------------------------------------
*/

.all-asvm .amendment {
    padding: 0;
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin: 0;
}

.all-asvm .amendment.active {
    padding: 0 0 em(30, 16) 0;
    border-bottom: 4px solid $mbiebuilding_gray_2;
}

.all-asvm .amendment .heading-wrap {
    overflow: hidden;
    padding: em(19, 16) em(120, 16) em(13, 16) 0;
    border-style: solid;
    border-width: 0;
    margin: 0 0 0 0;
}

.all-asvm .amendment .heading-wrap .title {
    margin-top: 0;
    margin-bottom: em(6, 25);
    font-size: em(25);
    line-height: lh(40, 25);
    color: $mbiebuilding_gray_5;
}

.all-asvm .amendment.active .heading-wrap .title {
    margin: 0 0 em(2, 25);
    line-height: lh(40, 25);
    color: $mbiebuilding_gray_5;
}

.all-asvm .amendment .heading-wrap p {
    margin: 0 0 em(15, 14);
    font-size: em(14);
    line-height: 1.43;
    color: $mbiebuilding_gray_4;
}

.all-asvm .amendment.active .heading-wrap {
    padding: em(3, 16) 0 em(9, 16) 0;
    border-width: 0 0 em(4, 16) 0;
    margin: 0 0 em(23, 16) 0;
}

@media print, screen and (min-width: $mobile_min) {
    .all-asvm .amendment .heading-wrap {
        padding: em(19, 16) em(120, 16) em(37, 16) 0;
    }

    .all-asvm .amendment.active .heading-wrap {
        padding: em(22, 16) 0 0 0;
        margin: 0 0 em(46, 16) 0;
    }

    .all-asvm .amendment .heading-wrap .title {
        margin-bottom: em(10, 30);
        font-size: em(30);
        line-height: lh(40, 30);
    }

    .all-asvm .amendment.active .heading-wrap .title {
        font-size: em(36);
    }
}

/*
 ALL-ASVM - AMENDMENT (concertina) expand-item
---------------------------------------
*/

.all-asvm .amendment .expand-item .js-trigger {
    position: relative;
    top: em(-15, 15);
    padding: 0;
    font-size: em(15);
    line-height: lh(25, 15);
    color: $mbiebuilding_blue_1;
}

.all-asvm .amendment.active .expand-item {
    margin-top: em(30, 16);
}

.all-asvm .amendment.active .expand-item .js-trigger {
    position: static;
    top: auto !important;
    padding: em(8) em(48);
}

@media print, screen and (min-width: $mobile_min) {
    .all-asvm .amendment .expand-item {
        margin-top: em(-45, 16);
    }

    .all-asvm .amendment .expand-item .js-trigger {
        top: em(-43, 15);
    }
}

/*
 ALL-ASVM - AMENDMENT (concertina) more-detail
---------------------------------------
*/

.all-asvm .amendment .more-detail h3 {
    margin-bottom: em(20, 22);
}

.all-asvm .amendment .more-detail ul {
    margin: em(23) 0 em(21) em(26);
    list-style: none;
}

.all-asvm .amendment .more-detail ul li {
    position: relative;
    margin: 0 0 em(10, 15);
    font-size: em(15);
    line-height: lh(24, 15);
}

.all-asvm .amendment .more-detail ul li::before {
    @include border-radius(50%);

    position: absolute;
    top: em(6);
    left: em(-19);
    content: '';
    width: em(8);
    height: em(8);
    background: $mbiebuilding_gray_3;
    font-size: em(16, 15);
}

.all-asvm .amendment .more-detail ul li li::before {
    background-color: tint($mbiebuilding_gray_3, 50%);
    font-size: inherit !important;
}

@media print, screen and (min-width: $mobile_min) {
    .all-asvm .amendment .more-detail ul {
        margin: em(23) 0 em(21) em(66);
    }
}
