
@import '../tools';

/* Intro text */

.sub-section .intro-text p {
    margin-bottom: em(20, 18);
}

.sub-section .intro-text p .button {
    margin-top: em(15);
    margin-top: em(6);
    font-size: em(16, 18);
}

@media only screen and (max-width: $mobile_max) {
    .page-maincontent .sub-section .content-wrapper .page-options {
        margin-bottom: 2em;
    }
}

@media only screen and (min-width: $tablet_min) {
    .sub-section .intro-text {
        font-size: em(20);
        line-height: lh(27, 20);
    }

    .sub-section .intro-text p {
        margin-top: em(6, 20);
        margin-bottom: em(20, 20);
        font-size: inherit;
        line-height: inherit;
    }

    .sub-section .intro-text p .button {
        margin-top: em(9);
        margin-bottom: em(11);
        font-size: em(16, 20);
    }
}

/* In this section nav */

.nav-in-this-section .nav-title {
    padding-bottom: em(25, 30);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin-bottom: em(6, 30);
}

.nav-in-this-section li {
    padding: 0;
    border-bottom: 1px solid $mbiebuilding_gray_2;
}

.nav-in-this-section li a {
    position: relative;
    display: block;
    padding: em(17) 0 em(17) em(21);
}

.nav-in-this-section li a .icon {
    position: absolute;
    top: em(12, 24);
    left: em(1, 24);
    font-size: em(24);
}

.nav-in-this-section li .title {
    @extend .gustan-bold;

    overflow: hidden;
    margin: 0 0 em(16, 18);
    font-size: em(18);
    line-height: lh(26, 18);
}

.page-maincontent .nav-in-this-section li p {
    margin: 0 0 em(16);
    line-height: lh(21);
}

.nav-in-this-section li a .title {
    color: $mbiebuilding_blue_1;
}

.nav-in-this-section li a p {
    color: $mbiebuilding_body;
}

.nav-in-this-section li a:hover,
.nav-in-this-section li a:active,
.nav-in-this-section li a:focus {
    text-decoration: none;
}

.nav-in-this-section a:hover .title,
.nav-in-this-section a:active .title,
.nav-in-this-section a:focus .title {
    text-decoration: underline;
}

@media print, screen and (min-width: $mobile_min) {
    .nav-in-this-section .nav-title {
        padding-bottom: em(25, 30);
        margin-bottom: em(-2, 30);
        font-size: em(30);
    }
}

@media only screen and (min-width: $tablet_min) {
    .nav-in-this-section {
        overflow: hidden;
    }

    .nav-in-this-section .nav-title {
        padding-bottom: em(22, 36);
        margin-bottom: em(-2, 36);
        font-size: em(36);
    }

    .nav-in-this-section li a {
        padding: em(25) 0 em(25) em(34);
    }

    .nav-in-this-section li a .icon {
        top: em(22, 24);
        left: em(2, 24);
    }

    .nav-in-this-section li .title {
        margin: 0 0 em(16, 20);
        font-size: em(20);
        line-height: lh(26, 20);
    }

    .page-maincontent .nav-in-this-section li p {
        margin: 0;
        line-height: lh(21, 15);
    }
}

/* Featured item (aside) */

.block-featured-item {
    margin: em(50) 0 em(30);
    background: #fff;
}

.block-featured-item .image {
    @include box-sizing(border-box);

    padding: em(20) em(20) em(10);
}

.block-featured-item .liner {
    padding: em(17) em(16) em(26);
}

.block-featured-item .title {
    @extend .gustan-bold;

    padding: 0;
    border: none;
    margin: 0 0 em(20);
    font-size: em(16);
    line-height: lh(21);
}

.block-featured-item .description p {
    @extend .gustan-light;

    margin-bottom: em(12, 15);
    font-size: em(15);
    line-height: lh(21, 15);
}

.block-featured-item .action {
    @extend .gustan-light;
}

.block-featured-item .icon-external {
    margin-right: .5em;
    font-size: 75%;
}

@media print, screen and (min-width: $mobile_min) {
    .block-featured-item {
        overflow: hidden;
        margin-bottom: em(50);
    }

    .block-featured-item .image {
        float: left;
        width: 47.5%;
        padding-bottom: em(20);
    }

    .block-featured-item.with-image .liner {
        @include box-sizing(border-box);

        float: left;
        width: 50%;
        padding: em(19) em(20) 0 0;
        margin-left: 2.5%;
    }
}
