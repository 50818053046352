.search-sort {
    padding: rem(25) 0 rem(9);

    select {
        width: rem(150);
    }

    label {
        @extend .gustan-bold;

        display: block;
        margin-bottom: rem(10);
        font-size: rem(14);

        .ajax-spinner {
            float: left;
            width: rem(25);
            height: rem(25);
        }
    }

    .select2-selection__rendered {
        text-align: left;
    }

    @media print, screen and (min-width: $mobile_min) {
        float: right;
        width: 50%;
        padding: 0;
        text-align: right;

        label {
            display: inline-block;
            margin-bottom: 0;
        }

        .middleColumn {
            display: inline-block;
            margin-left: rem(9);
        }
    }
}
