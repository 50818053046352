.news-listing {
    margin: rem(55) 0 rem(70);

    &__container {
        max-width: rem(950);
        margin-right: auto;
        margin-left: auto;
    }

    @media print {
        &__container {
            max-width: initial;
        }
    }
}
