.tag {
    display: inline-block;
    padding: rem(10) rem(14);
    border: 1px solid $mbiebuilding_gray_7;
    margin: rem(6) rem(6) rem(4) 0;
    background-color: $mbiebuilding_white_1;
    font-size: rem(14);
    line-height: 1;
    color: $mbiebuilding_gray_4;
    transition: background-color .1s ease-in-out, border-color .1s ease-in-out, color .1s ease-in-out;

    &[class^='section-'],
    &[class*=' section-'] {
        padding-left: rem(9);
        border-left-width: 4px;
    }
}

a.tag {
    border-color: $mbiebuilding_blue_1;
    color: $mbiebuilding_blue_1;

    &--transparent {
        background-color: transparent;
    }

    &:active,
    &--current {
        background-color: $mbiebuilding_blue_1;
        color: $mbiebuilding_white_1;
    }

    &:hover,
    &:active,
    &:focus {
        outline: none;
        text-decoration: none;
    }

    &[class^='section-']:hover::before,
    &[class*=' section-']:hover {
        color: $mbiebuilding_white_1;
    }


    &:hover,
    &:focus {
        border-color: $mbiebuilding_blue_4;
        background-color: $mbiebuilding_blue_4;
        color: $mbiebuilding_blue_2;
    }

    @media print {
        background-color: transparent;
        color: $mbiebuilding_blue_1;
    }
}

