
@import '../tools';

.skip-links {
    display: none;

    &__anchor {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        padding: 8px;
        border: 0;
        background: transparent;
        color: #fff;
        text-decoration: none;
        transform: translateY(-40px);
        transition: transform 100ms ease-out, background 100ms linear;

        &:hover {
            text-decoration: none;
        }

        &:focus {
            background: $mbiebuilding_blue_1;
            transform: translateY(0);
        }
    }

    @media only screen and (min-width: $tablet_min) {
        display: block;
    }
}
