
@import '../tools';

/*
#Rating
*/

.rating {
    padding: 0 30px 40px;
    margin-top: 60px;
    background: #fff;

    @media print {
        background: transparent;
    }

    .field.textarea {
        clear: both;
        margin-bottom: 30px;

        .textarea {
            width: 80%;
            border: 1px solid #949494;
        }
    }

    label {
        display: block;
        margin-bottom: 4px;
    }

    input[type='submit'],
    button.action {
        width: auto;
        min-width: 200px;
        background: #005da6;
        color: #fff;
        transition: all .2s ease-in-out 0s;

        &:hover {
            background: #2b92d0;
        }
    }

    input[disabled='disabled'] {
        border-color: #c9c9c8;
        color: #c9c9c8;

        &:hover {
            border-color: #c9c9c8;
            background: #fff;
            color: #c9c9c8;
            cursor: default;
        }
    }


    div.rating-custom {
        margin: -15px 0 15px 0;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.js-radio input[type=radio] {
    position: absolute;
    top: 0;
    left: -999em;
}

.rating div.optionset {
    margin-top: 16px;
    margin-bottom: 74px;

    .message {
        display: block;
        clear: both;
        padding: 5px 0;
    }
}

.rating ul.optionset {
    clear: both;
    margin: 0;

    li {
        float: left;
        margin-right: 15px;
    }

    input {
        display: inline;
    }

    label {
        &::before,
        &::after {
            display: none;
        }
    }
}

.js-enabled .rating ul.optionset {
    li {
        position: relative;
        width: 43px; // 50 + 7 + 7
        height: 0;
        overflow: hidden;
        padding: 40px 0 0;
        margin: 0;
        cursor: pointer;

        &.focussed {
            outline: 1px dotted;
            outline-offset: 1px;
        }
    }

    li:first-child {
        width: 38px;

        &::before,
        &::after {
            left: 0;
        }
    }

    li::before,
    li::after {
        position: absolute;
        top: 0;
        left: 7px;
        display: block;
        content: ' ';
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
    }

    li::before {
        z-index: 2;
        border-radius: 0;
        background-image: url($img-path + 'icons/rating-star-fill.svg');
        background-color: transparent;
        opacity: 0;
        transition: .2s;

        @media print {
            background-image: url($img-path + 'icons/rating-star-fill-black.svg');
        }
    }

    li::after {
        z-index: 3;
        background-image: url($img-path + 'icons/rating-star-border.svg');

        @media print {
            background-image: url($img-path + 'icons/rating-star-border-black.svg');
        }
    }

    li.rating-hovered {
        &::before {
            opacity: .5;
        }
    }

    li.rating-selected {
        &::before {
            opacity: 1;
        }
    }

    label {
        position: absolute;
        top: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
        padding: 0;
        text-indent: -999em;
    }
}

.rating ul.optionset[disabled] li {
    cursor: initial;
}

.rating form.disabled .field.textarea {
    clear: both;
    opacity: .5;
}
