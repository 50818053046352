.block-code-nav-bar {
    padding: 0 rem(20) rem(25);
    margin: rem(30) 0 rem(8);

    @supports (display: grid) {
        display: grid;
        grid-template-areas:
            '. a b .'
            'contentRow1 contentRow1 contentRow1 contentRow1'
            '. c d .'
            'contentRow2 contentRow2 contentRow2 contentRow2'
            '. e f .'
            'contentRow3 contentRow3 contentRow3 contentRow3'
            '. g h .'
            'contentRow4 contentRow4 contentRow4 contentRow4'
            '. sb sb .'
            'contentRow5 contentRow5 contentRow5 contentRow5';
        grid-template-columns: 1fr 100px 100px 1fr;
        grid-column-gap: 18px;
        grid-row-gap: 8px;
        padding: 0 0 rem(25);
    }

    &__inner {
        // Fallback layout
        display: flex;
        flex-wrap: wrap;
        max-width: rem(1150);
        margin-right: auto;
        margin-left: auto;

        @supports (display: grid) {
            /* autoprefixer: ignore next */
            display: contents;
        }

        &[data-active='true'] {
            .code-button:not([aria-selected='true']) {
                .code-icon {
                    background-color: $mbiebuilding_gray_2;
                    color: $mbiebuilding_gray_2;
                }

                .code-description {
                    color: $mbiebuilding_gray_2;
                }
            }

            .section-a {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_a, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_a;
                        }
                    }

                    .code-description {
                        color: $building_code_a;
                    }
                }
            }

            .section-b {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_b, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_b;
                        }
                    }

                    .code-description {
                        color: $building_code_b;
                    }
                }
            }

            .section-c {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_c, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_c;
                        }
                    }

                    .code-description {
                        color: $building_code_c;
                    }
                }
            }

            .section-d {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_d, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_d;
                        }
                    }

                    .code-description {
                        color: $building_code_d;
                    }
                }
            }

            .section-e {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_e, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_e;
                        }
                    }

                    .code-description {
                        color: $building_code_e;
                    }
                }
            }

            .section-f {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_f, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_f;
                        }
                    }

                    .code-description {
                        color: $building_code_f;
                    }
                }
            }

            .section-g {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_g, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_g;
                        }
                    }

                    .code-description {
                        color: $building_code_g;
                    }
                }
            }

            .section-h {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_h, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_h;
                        }
                    }

                    .code-description {
                        color: $building_code_h;
                    }
                }
            }

            .section-sb {
                .code-button[aria-selected='false'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: tint($building_code_sb, 50%);
                        }
                    }
                }

                .code-button[aria-selected='true'] {
                    &:hover,
                    &:focus {
                        .code-icon {
                            background-color: $building_code_sb;
                        }
                    }

                    .code-description {
                        color: $building_code_sb;
                    }
                }
            }
        }
    }

    .code-section {
        display: flex;
        flex-basis: rem(100);
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;
        margin-right: rem(18);

        // Half of cluster margin
        margin-bottom: rem(7.25);

        &:last-of-type {
            margin-right: 0;
        }

        @supports (display: grid) {
            margin: 0;
        }

        &.section-a {
            grid-area: a;

            .code-arrow::before {
                border-bottom-color: $building_code_a;
            }
        }

        &.section-b {
            grid-area: b;

            .code-arrow::before {
                border-bottom-color: $building_code_b;
            }
        }

        &.section-c {
            grid-area: c;

            .code-arrow::before {
                border-bottom-color: $building_code_c;
            }
        }

        &.section-d {
            grid-area: d;

            .code-arrow::before {
                border-bottom-color: $building_code_d;
            }
        }

        &.section-e {
            grid-area: e;

            .code-arrow::before {
                border-bottom-color: $building_code_e;
            }
        }

        &.section-f {
            grid-area: f;

            .code-arrow::before {
                border-bottom-color: $building_code_f;
            }
        }

        &.section-g {
            grid-area: g;

            .code-arrow::before {
                border-bottom-color: $building_code_g;
            }
        }

        &.section-h {
            grid-area: h;

            .code-arrow::before {
                border-bottom-color: $building_code_h;
            }
        }

        &.section-specific-buildings {
            grid-area: sb;
            flex-basis: rem(190);

            .code-arrow::before {
                border-bottom-color: $mbiebuilding_blue_1;
            }
        }
    }

    .code-button {
        display: block;
        width: 100%;
        outline: 0;
        text-decoration: none;

        &[aria-selected='true'] {
            + .code-arrow {
                visibility: visible;
                opacity: 1;
            }
        }

        .code-icon {
            width: 100%;
            min-height: 1.97em;
            font-size: rem(55);
        }
    }

    .code-icon {
        margin: 0 auto;
    }

    .code-description {
        @extend .gustan-black;

        margin: rem(7) 0;
        font-size: rem(12);
        line-height: lh(14, 12);
        color: $mbiebuilding_gray_5;
        text-align: center;
    }

    .code-arrow {
        position: relative;
        top: 0;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;

        &::before {
            position: absolute;
            top: 0;
            left: 50%;
            content: '';
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 0 rem(21) rem(25) rem(21);
            transform: translateX(-50%);
        }

        &::after {
            position: absolute;
            top: rem(6);
            left: 50%;
            content: '';
            width: 0;
            height: 0;
            border-color: transparent;
            border-bottom-color: $mbiebuilding_white_1;
            border-style: solid;
            border-width: 0 rem(16) rem(20) rem(16);
            transform: translateX(-50%);
        }
    }

    @media (min-width: 391px) {
        @supports (display: grid) {
            grid-template-areas:
                '. a b c .'
                'contentRow1 contentRow1 contentRow1 contentRow1 contentRow1'
                '. d e f .'
                'contentRow2 contentRow2 contentRow2 contentRow2 contentRow2'
                '. g h . .'
                'contentRow3 contentRow3 contentRow3 contentRow3 contentRow3'
                '. sb sb . .'
                'contentRow4 contentRow4 contentRow4 contentRow4 contentRow4';
            grid-template-columns: 1fr 100px 100px 100px 1fr;
        }
    }

    @media (min-width: 510px) {
        @supports (display: grid) {
            grid-template-areas:
                '. a b c d .'
                'contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1'
                '. e f g h .'
                'contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2'
                '. sb sb . . .'
                'contentRow3 contentRow3 contentRow3 contentRow3 contentRow3 contentRow3';
            grid-template-columns: 1fr 100px 100px 100px 100px 1fr;
        }
    }

    @media (min-width: $mobile_min) {
        // Line up grid gap with page wrapper padding change
        grid-column-gap: 30px;

        // Padding for IE
        padding-right: rem(30);
        padding-left: rem(30);

        @supports (display: grid) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 700px) {
        @supports (display: grid) {
            grid-template-areas:
                '. a b c d e .'
                'contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1'
                '. f g h sb sb .'
                'contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2';
            grid-template-columns: 1fr 100px 100px 100px 100px 100px 1fr;
        }

        .code-section {
            // Half of cluster margin
            margin-bottom: rem(25);

            @supports (display: grid) {
                margin: 0;
            }
        }
    }

    /* stylelint-disable max-line-length */
    @media (min-width: 830px) {
        grid-template-areas:
            '. a b c d e f .'
            'contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1'
            '. g h sb sb . . .'
            'contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2';
        grid-template-columns: 1fr 100px 100px 100px 100px 100px 100px 1fr;
    }

    @media (min-width: 960px) {
        grid-template-areas:
            '. a b c d e f g .'
            'contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1'
            '. h sb sb . . . . .'
            'contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2';
        grid-template-columns: 1fr 100px 100px 100px 100px 100px 100px 100px 1fr;
    }

    @media print, screen and (min-width: 1090px) {
        grid-template-areas:
            '. a b c d e f g h .'
            'contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1'
            '. sb sb . . . . . . .'
            'contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2 contentRow2';
        grid-template-columns: 1fr 100px 100px 100px 100px 100px 100px 100px 100px 1fr;
    }
    /* stylelint-enable max-line-length */

    @media (max-width: 1219px) {
        .code-section {
            &.section-specific-buildings {
                .code-description {
                    // Align specific buildings description to left when its spanning two cells
                    text-align: left;
                }
            }
        }

        // Hide arrow in IE as it will be disconnected from the content
        .code-arrow {
            display: none;

            @supports (display: grid) {
                display: block;
            }
        }
    }

    @media (min-width: 1220px) {
        margin-bottom: 0;

        @supports (display: grid) {
            /* stylelint-disable max-line-length */
            grid-template-areas:
                '. a b c d e f g h sb .'
                'contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1 contentRow1';
            /* stylelint-enable max-line-length */
            grid-template-columns: 1fr 90px 90px 90px 90px 90px 90px 90px 90px 190px 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 0;
            padding-right: 0;
            padding-left: 0;
        }

        &__inner {
            justify-content: space-between;
        }

        .code-section {
            flex-basis: rem(90);
            margin-bottom: 0;
        }

        .code-button {
            .code-icon {
                font-size: rem(50);
            }
        }

        .code-arrow {
            top: rem(10);

            &::before {
                border-width: 0 rem(23) rem(28) rem(23);
            }

            &::after {
                border-width: 0 rem(19) rem(23) rem(19);
            }
        }
    }

    @media print {
        display: block;

        .code-arrow {
            display: none;
        }

        .code-section {
            display: inline-block;
            vertical-align: top;
            padding-right: rem(30);
        }

        .code-button {
            width: rem(100);

            &::after {
                display: none !important;
            }
        }
    }
}
