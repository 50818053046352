
@import '../tools';

/*
 TABLES - DEFAULT STYLES
---------------------------------------
*/

table {
    @extend .fira-sans-regular;

    width: 100%;
    border-collapse: collapse;
    margin: em(26) 0;
    background-color: white;
}

table tbody,
table thead {
    position: relative;
    display: table-header-group;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}

table caption,
.caption {
    @extend .gustan-light;

    margin: 0;
    font-size: em(25);
    line-height: lh(29, 25);
}

th,
td {
    vertical-align: top;
}

th {
    padding: em(25) em(11);
    border-bottom: 2px solid $mbiebuilding_blue_6;
    font-size: em(16);
    line-height: 1;
}

td {
    padding: em(25, 15) em(11, 15);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    font-size: em(15);
    line-height: lh(18, 15);
}

th p,
td p,
th li,
td li,
.page-maincontent th p,
.page-maincontent td p,
.page-maincontent th li,
.page-maincontent td li {
    font-size: inherit;
    line-height: inherit;
}

@media print, screen and (min-width: $mobile_min) {
    th {
        padding: em(38) em(23) em(15);
    }

    td {
        padding: em(26, 15) em(23, 15) em(22, 15);
    }
}

/*
 TABLE - JS ASSISTED
---------------------------------------
*/

.js-table-wrapper {
    position: relative;
    margin-bottom: em(26);
}

.js-table-wrapper table {
    margin-bottom: 0;
}

.js-table-wrapper tbody,
.js-table-wrapper thead {
    position: static;
    overflow: visible;
    overflow-x: visible;
}

/* reset */

.js-table-wrapper .js-hidden {
    display: none;
}

.js-table-responsive .js-show,
.js-cell-responsive {
    display: none;
}

@media only screen and (max-width: $mobile_max) {
    .js-table-responsive {
        width: 100%;
    }

    .js-table-responsive thead {
        display: none;
    }

    .js-cell-responsive {
        display: block;
    }

    .js-table-responsive tr {
        display: block;
        padding-bottom: 1em;
        border-bottom: 1px solid $mbiebuilding_gray_2;
        margin-top: 1em;
    }

    .js-table-responsive td,
    .page-maincontent .js-table-responsive th {
        display: none;
        border: none;
        font-size: 16px;
    }

    .js-table-responsive th {
        padding: em(10, 15) em(11, 15) 0;
    }

    .js-table-responsive td {
        padding: em(5, 15) em(11, 15) em(10, 15);
    }

    .js-table-responsive th:first-child {
        margin-bottom: 1em;
    }

    .js-table-responsive td:nth-child(2),
    .js-table-responsive .active th,
    .js-table-responsive .active td {
        display: block;
    }

    .js-table-responsive td:nth-child(2) {
        position: relative;
        padding: em(10, 15) em(50, 15) em(10, 15) em(11, 15);
    }

    .js-table-responsive td.nth-child-2 {
        position: relative;
        display: block;
        padding: em(10, 15) em(50, 15) em(10, 15) em(11, 15);
    }

    .js-table-responsive .js-show {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: em(50, 30);
        margin-top: em(-19, 30);
        font-size: em(30, 15);
        font-weight: normal;
        color: $mbiebuilding_blue_3;
        text-align: center;
        cursor: pointer;
    }

    .js-table-responsive .active .js-show::before {
        content: '\e816';
    }

    .js-table-responsive tr:first-child th:first-child {
        display: block !important;
        padding-bottom: em(25);
        border-bottom: 2px solid $mbiebuilding_blue_6;
    }
}


/*
 TABLE - Vertical overflow
---------------------------------------
*/

.js-table-wrapper .view-full-table {
    position: relative;
    width: 100%;
    height: em(100, 15);
    background-color: $mbiebuilding_blue_6;
}

.js-table-wrapper .view-full-table a {
    position: absolute;
    bottom: em(32);
    display: inline-block;
    width: 100%;
    text-align: center;
    text-decoration: none;
}

.js-table-wrapper .view-full-table a .icon {
    @extend .gustan-light;

    display: block;
    font-size: em(50);
    line-height: lh(27, 27);
    color: $mbiebuilding_blue_5;
}

.js-table-wrapper .view-full-table a .text {
    @extend .gustan-bold;

    display: block;
    clear: left;
    font-size: em(14);
    line-height: lh(14, 14);
    color: $mbiebuilding_gray_5;
    text-decoration: underline;
}

.js-table-wrapper .view-full-table a:focus,
.js-table-wrapper .view-full-table a:hover {
    text-decoration: none;
}

/*
 TABLE - Horizontal overflow
---------------------------------------
*/

.js-table-wrapper .js-scroll-trigger {
    top: 50%;
    margin-top: -42px;
}

/*
 TABLE - STYLE 1 (Guidance reading)
---------------------------------------
*/

.table-style-1 th,
.table-style-1 td {
    min-width: em(200);
}

.table-style-1 thead:first-child tr:first-child th {
    padding: em(30, 15) em(11, 15) em(24, 15) !important;
    font-size: em(15) !important;
}

.table-style-1 td {
    vertical-align: middle !important;
}


/*
 BLOCK - DOCUMENT VERSION CONTROL TABLE
---------------------------------------
*/

.block-version-control-table {
    overflow: auto;
    margin-top: em(23);
    background: #fff;
}

.block-version-control-table table {
    width: 100%;
}

.block-version-control-table th {
    padding-bottom: em(20, 20);
    white-space: nowrap;
}

.block-version-control-table td {
    padding-top: em(14, 15);
    padding-bottom: em(14, 15);
    border-color: tint($mbiebuilding_gray_2, 50%);
    line-height: lh(20, 15);
}

.block-version-control-table tr:last-child td,
.block-version-control-table tr.last td {
    border-bottom: none;
}

.block-version-control-table th p,
.block-version-control-table td p {
    font-size: inherit;
    line-height: inherit;
}

@media only screen and (min-width: $tablet_min) {
    .block-version-control-table th {
        padding-top: em(22, 20);
    }
}
