/**
 * Create an intrinsically responsive grid using CSS Grid.
 *
 * @param {number} $minWidth - minimum width of each grid item (determines how the layout breaks)
 * @param {unit} $space - unit of measurement for the gap between grid items (can be zero for no gap)
 * @param {string} $justify - justification of the grid content (horizontal positioning)
 * @param {string} $align - alignment of the grid content (vertical positioning)
 */
@mixin grid-l(
    $minWidth: 250px,
    $space: rem(40),
    $justify: 'inherit',
    $align: 'inherit',
    $autoTrackSizing: auto-fit
) {
    // Update fallback layout positioning properties
    align-items: #{$align};
    justify-content: #{$justify};

    @supports (display: grid) {
        display: grid;
        grid-column-gap: $space;
        grid-row-gap: $space;

        // Reset any fallback layouts if grid is supported
        > * {
            display: block;
            width: initial !important;
            max-width: initial !important;
        }

        /* stylelint-disable-next-line selector-max-universal */
        > * > * {
            margin-right: 0;
            margin-left: 0;
        }
    }

    // Use Sass unquote() to get native min() function compiling https://github.com/sass/sass/issues/2378
    @supports (width: unquote('min(250px, 100%)')) and (display: grid) {
        /* autoprefixer: off */
        grid-template-columns:
            repeat(
                #{$autoTrackSizing},
                minmax(unquote('min(#{$minWidth}, 100%), 1fr'))
            );
    }
}
