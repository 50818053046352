.homepage-hero {
    position: relative;
    overflow: hidden;
    margin-bottom: rem(30);

    &__image {
        display: block;
        width: 100%;
        height: rem(244);
        margin: 0;
        object-fit: cover;
        object-position: center;

        /* stylelint-disable */
        // For IE Polyfill object-fit
        font-family: 'object-fit: cover; object-position: center;';
        /* stylelint-enable */
    }

    &__container {
        @include box-sizing(border-box);

        width: 100%;
        padding: 0 rem(20);
    }

    &__message {
        @extend .gustan-light;

        max-width: rem(643);
    }

    &__title {
        margin-top: rem(24);
        font-size: rem(38);
    }

    .intro {
        margin-top: rem(25);
        font-size: rem(20);
        letter-spacing: -#{rem(.5)};

        p {
            line-height: lh(24, 20);
        }
    }

    &__intro-links {
        display: none;
    }

    .action-link {
        @extend .gustan-book;

        display: inline-flex;
        align-items: baseline;
        border-bottom: rem(.1) solid $mbiebuilding_blue_4;
        line-height: lh(38, 29);
        font-weight: 300;
        color: $mbiebuilding_white_1;
        transition: color .1s ease-in-out;

        &:hover {
            color: $mbiebuilding_blue_4;
            text-decoration: none;
        }

        &__icon {
            margin-right: rem(5);

            &::before {
                display: inline-block;
                content: '';
                width: .5em;
                height: .7em;
                background: url($img-path + 'icons/icon-arrow-down-blue.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }
    }

    .jump-links {
        &__title {
            @extend .gustan-bold;

            padding-bottom: rem(15);
            border-bottom: rem(3) solid $mbiebuilding_gray_7;
            margin-top: rem(25);
            margin-bottom: rem(25);
            font-size: rem(16);
        }

        &__item {
            &:not(:last-child) {
                margin-bottom: rem(20);
            }
        }

        &__link {
            @extend .gustan-bold;

            display: inline-flex;
            align-items: baseline;

            &:hover {
                .jump-links__icon {
                    background: url($img-path + 'icons/icon-arrow-down-blue.svg');
                }
            }
        }

        &__icon {
            align-self: center;
            width: em(12);
            height: em(17);
            margin-right: rem(25);
            background: url($img-path + 'icons/icon-arrow-down-grey.svg');
            background-repeat: no-repeat;
            color: $mbiebuilding_gray_7;
        }
    }

    @media only screen and (min-width: $tablet_min) {
        margin-bottom: 0;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            background:
                linear-gradient(
                    90deg,
                    rgba(1, 47, 100, 1) 0%,
                    rgba(1, 47, 100, .798739564185049) 45%,
                    rgba(1, 47, 100, 0) 80%
                );
            opacity: .8;
        }

        &__image {
            float: left;
            height: rem(500);
        }

        &__container {
            position: absolute;
            top: rem(31);
            left: 0;
            z-index: 1;
        }

        &__message {
            max-width: rem(340);
            margin-left: rem(73);
            color: $mbiebuilding_white_1;
        }

        &__title {
            width: rem(350);
            margin-top: rem(16);
            color: $mbiebuilding_white_1;
        }

        &__intro-links {
            display: block;
        }

        .jump-links {
            display: none;
        }
    }

    @media print, screen and (min-width: $desktop_min) {
        &__image {
            height: rem(660);
        }

        &__container {
            top: rem(100);
            left: -#{rem(15)};
        }

        &__message {
            max-width: rem(650);
            margin-left: 0;
        }

        &__title {
            width: rem(620);
            margin: 0;
            font-size: rem(62);
            line-height: lh(64, 62);
            letter-spacing: -#{rem(1)};
        }

        .intro {
            margin-left: rem(3);
            font-size: rem(29);

            p {
                line-height: lh(38, 29);
            }

            &:first-of-type {
                padding-right: rem(70);
            }
        }
    }

    @media only print {
        &__image {
            height: rem(400);
            margin-bottom: rem(10);
        }

        &__message {
            max-width: none;
        }

        &__title {
            width: 100%;
        }

        &__intro-links,
        .jump-links {
            display: none;
        }
    }
}
