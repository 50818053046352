@use 'sass:math';
@import '../tools';

/*
 UPDATE-EVENT
 ---------------------------------------
*/

.update-event .content-wrapper {
    padding-bottom: em(30);
    border-bottom: 1px solid $mbiebuilding_gray_2;
    margin-bottom: em(50);
}

@media only screen and (min-width: $tablet_min) {
    .update-event .content-wrapper {
        margin-bottom: em(22);
    }
}


/*
 UPDATE-EVENT - event-details
 ---------------------------------------
*/

.event-details {
    padding-bottom: em(15);
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin-bottom: em(48);
}

.event-details p {
    margin-bottom: em(17, 15);
    font-size: em(15, 15);
    line-height: lh(20, 15);
}

.update-event h2.border-bottom {
    padding-bottom: em(13, 22);
    border-bottom: 1px solid $mbiebuilding_gray_7;
    margin-top: em(26, 22);
    margin-bottom: em(25, 22);
    font-size: em(22);
    line-height: lh(27, 22);
}

@media only screen and (min-width: $tablet_min) {
    .event-details {
        padding-bottom: em(20);
    }

    .event-details .layout-col-right {
        float: right;
        width: math.div((100% - $margin_right), math.div($total_cols, 6));
        margin-right: 0;
        margin-left: 2.584%;
    }

    .event-details .layout-col-right:last-child,
    .event-details .layout-col-right.last {
        margin-left: 0;
    }
}

@media only screen and (min-width: $desktop_min) {
    .update-event h2.border-bottom {
        padding-bottom: em(21, 25);
        margin-top: em(11, 25);
        margin-bottom: em(22, 25);
        font-size: em(25);
        line-height: lh(27, 25);
    }
}

/*
 UPDATE-EVENT - intro-image tweak
 ---------------------------------------
*/

.event-details .intro-image {
    float: none;
    width: 100%;
    margin: em(21) 0 0;
}

@media print, screen and (min-width: $mobile_min) {
    .event-details .intro-image {
        float: none;
        width: 100%;
        margin: em(21) 0 0;
    }
}

@media only screen and (min-width: $tablet_min) {
    .event-details .intro-image {
        float: none;
        width: 100%;
        margin: em(64) 0 0;
    }
}

@media only screen and (min-width: $desktop_min) {
    .event-details .intro-image {
        margin: em(53) 0 0 em(30);
    }
}

/*
 UPDATE-EVENT - h3 tweak
 ---------------------------------------
*/

.update-event h3 {
    margin-top: em(35);
    margin-bottom: em(38);
    font-size: em(22);
    line-height: lh(24, 22);
}

@media only screen and (min-width: $tablet_min) {
    .update-event h3 {
        font-size: em(22);
        line-height: lh(27, 22);
    }
}

/*
 UPDATE-EVENT - ul tweak
 ---------------------------------------
*/

.update-event .content-wrapper ul {
    margin-top: em(21);
    margin-left: em(60);
}

/*
 UPDATE-EVENT - aside nav-secondary
 ---------------------------------------
*/

.update-event .nav-secondary {
    margin-top: em(24);
}
