
@import '../tools';

/*
 ERROR PAGE - IMAGE
---------------------------------------
*/

.error-page .captionImage {
    max-width: 50%;
    margin-top: em(30, 16);
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
}

.error-page .captionImage img {
    max-width: 100%;
}

@media only screen and (min-width: $tablet_min) {
    .error-page .captionImage {
        width: 100%;
        max-width: none;
        margin-top: em(45, 16);
    }
}

/*
 ERROR PAGE - HEADING
---------------------------------------
*/

.error-page h2 {
    margin-top: em(32, 30);
    margin-bottom: em(11, 30);
}

@media only screen and (min-width: $tablet_min) {
    .error-page h2 {
        margin-top: em(15, 36);
        margin-bottom: em(18, 36);
    }
}

/*
 ERROR PAGE - BLOCK ALERT
---------------------------------------
*/

.error-page .block-alert03 .icon {
    top: em(4, 25);
    left: em(5, 25);
}

@media only screen and (min-width: $tablet_min) {
    .error-page .block-alert03 {
        padding-left: em(52, 16);
        margin-bottom: em(-6, 16);
    }

    .error-page .block-alert03 .icon {
        top: em(7, 26);
        left: em(3, 26);
        font-size: em(26, 16);
    }
}

/*
 ERROR PAGE - UNSORTED LIST
---------------------------------------
*/
@media only screen and (min-width: $tablet_min) {
    .page-maincontent .error-page ul {
        margin-left: em(60, 16);
    }
}
