.code-icon {
    @extend .gustan-black;

    display: block;
    vertical-align: middle;
    width: em(74, 40);
    height: em(80, 40);
    overflow: hidden;
    background-image: url($img-path + 'icons/code-section-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: $mbiebuilding_gray_2;
    font-size: em(40);
    line-height: em(70, 40);
    color: $mbiebuilding_gray_2;
    text-align: center;
    text-transform: uppercase;

    .section-a & {
        background-color: $building_code_a;
        color: $building_code_a;
    }

    .section-a .code-button:hover &,
    .section-a .code-button:focus & {
        background-color: tint($building_code_a, 50%);
    }

    .section-b & {
        background-color: $building_code_b;
        color: $building_code_b;
    }

    .section-b .code-button:hover &,
    .section-b .code-button:focus & {
        background-color: tint($building_code_b, 50%);
    }

    .section-c & {
        background-color: $building_code_c;
        color: $building_code_c;
    }

    .section-c .code-button:hover &,
    .section-c .code-button:focus & {
        background-color: tint($building_code_c, 50%);
    }

    .section-d & {
        background-color: $building_code_d;
        color: $building_code_d;
    }

    .section-d .code-button:hover &,
    .section-d .code-button:focus & {
        background-color: tint($building_code_d, 50%);
    }

    .section-e & {
        background-color: $building_code_e;
        color: $building_code_e;
    }

    .section-e .code-button:hover &,
    .section-e .code-button:focus & {
        background-color: tint($building_code_e, 50%);
    }

    .section-f & {
        background-color: $building_code_f;
        color: $building_code_f;
    }

    .section-f .code-button:hover &,
    .section-f .code-button:focus & {
        background-color: tint($building_code_f, 50%);
    }

    .section-g & {
        background-color: $building_code_g;
        color: $building_code_g;
    }

    .section-g .code-button:hover &,
    .section-g .code-button:focus & {
        background-color: tint($building_code_g, 50%);
    }

    .section-h & {
        background-color: $building_code_h;
        color: $building_code_h;
    }

    .section-h .code-button:hover &,
    .section-h .code-button:focus & {
        background-color: tint($building_code_h, 50%);
    }

    .section-sb & {
        background-color: $building_code_sb;
        color: $building_code_sb;
    }

    .section-sb .code-button:hover &,
    .section-sb .code-button:focus & {
        background-color: tint($building_code_sb, 50%);
    }

    .section-specific-buildings & {
        background-color: $mbiebuilding_blue_1;
        color: $mbiebuilding_blue_1;
    }

    @media print {
        border: rem(8) solid currentColor;
    }
}

