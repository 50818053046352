.section-wrapper {
    border-top: rem(1) solid $mbiebuilding_gray_2;
    margin-top: rem(30);

    &__title-wrap {
        margin-bottom: rem(28);
    }

    &__title {
        margin: rem(35) 0 rem(28);
        font-size: rem(32);
        line-height: 1;
    }

    @media print, screen and (min-width: $mobile_min) {
        &__title {
            margin: rem(54) 0 rem(28);
        }
    }

    @media only screen and (min-width: $tablet_min) {
        &__title {
            margin: rem(25) 0 rem(35);
            font-size: rem(52);
            line-height: 1;
        }
    }

    @media only screen and (min-width: $desktop_min) {
        margin-top: rem(50);

        &__title-wrap {
            margin-bottom: rem(35);
        }

        &__title {
            margin-top: rem(65);
        }

        &:first-of-type {
            .homepage & {
                border-top: 0;
                margin-top: rem(25);
            }
        }
    }

    @media print {
        &__title-wrap {
            margin: 0;

            &::before {
                display: none;
            }
        }

        &__title {
            margin: 0 0 rem(10);
        }
    }
}
