
@import '../tools';

/*
 MEGA NAV MENU
---------------------------------------
*/

.nav-mega {
    @include box-shadow(0, 4px, 4px, 0, rgba(0, 0, 0, .4));
}

.nav-mega > .inner {
    position: relative;
    box-sizing: border-box;
    max-width: em(1200);
    min-height: em(432);
    padding: em(26) em(25) em(39);
    margin: 0 auto;
}

.nav-mega > .inner::before {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_1);

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: '';
    height: 1px;
}

.nav-mega > .inner a {
    padding: 0;
    color: $mbiebuilding_blue_1;
    text-align: left;
}

.nav-mega > .inner a:hover,
.nav-mega > .inner a:focus {
    background: none;
    background-color: transparent;
    color: $mbiebuilding_blue_1;
}

.nav-mega > .inner .title {
    display: inline-block;
    width: 100%;
    min-height: em(54, 25);
    margin: em(12, 25) 0 em(12, 25);
}

.nav-mega > .inner .title a:hover,
.nav-mega > .inner .title a:focus {
    color: $mbiebuilding_blue_1;
    text-decoration: underline;
}

.nav-mega > .inner .description {
    display: block;
    margin: em(11) 0 em(24);
}

.nav-mega > .inner .description p {
    font-size: em(14);
    line-height: 1.43;
}

.nav-mega .see-all {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_1);
}

.nav-mega .inner p a {
    background-color: transparent;
    color: $mbiebuilding_white_1;
    text-align: center;
}

.nav-mega .inner a:hover,
.nav-mega .inner a:focus {
    background-color: transparent;
    color: $mbiebuilding_white_1;
}

.nav-mega .see-all .inner {
    box-sizing: border-box;
    max-width: em(1180);
    padding: 0;
    margin: 0 auto;
}

.nav-mega .see-all a {
    padding: em(13) 0 em(17) 0;
}

.nav-mega .see-all a:hover,
.nav-mega .see-all a:focus {
    background: transparent;
    text-decoration: underline;
}

.nav-mega .see-all a::after {
    display: none;
}

.nav-mega ul.mega-nav-secondary {
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0 0 em(25);
    list-style-type: none;
}

.nav-mega ul.mega-nav-secondary.mega-nav-secondary--no-title {
    margin-top: em(-12);
    margin-bottom: em(16);
}

.section-4 .nav-mega ul.mega-nav-secondary,
.section-5 .nav-mega ul.mega-nav-secondary {
    margin-top: em(4);
}

.nav-mega ul.mega-nav-secondary:last-child,
.nav-mega ul.mega-nav-secondary.last {
    margin-bottom: 0;
}

.nav-mega ul.mega-nav-secondary li {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: em(16);
    list-style-type: none;
}

.nav-mega ul.mega-nav-secondary li a {
    @extend .gustan-bold;

    position: relative;
    display: block;
    padding: em(5) 0 em(5) em(18);
    margin: 0;
    background-color: transparent;
    line-height: lh(24);
    color: $mbiebuilding_gray_5;
    text-align: left;
}

.nav-mega ul.mega-nav-secondary li a:hover,
.nav-mega ul.mega-nav-secondary li a:focus {
    color: $mbiebuilding_gray_5;
    text-decoration: underline;
}

.nav-mega ul.mega-nav-secondary li a::before {
    position: absolute;
    top: em(7, 19);
    left: em(2, 19);
    content: '\e804';
    font-size: em(19);
    color: $mbiebuilding_blue_3;
}

.nav-mega .mega-nav02 .title {
    min-height: 0;
}

/*
 ---------------------------------------
 BLOCK - BUILDING CODE NAVIGATION BAR
 ---------------------------------------
*/

.nav-mega .mega-nav-block-code-nav-bar {
    margin: 0 0 0 em(-4);
}

.nav-mega .mega-nav-block-code-nav-bar ul {
    padding: 0;
    margin: em(23) em(-60) 0 0;
}

.nav-mega .mega-nav-block-code-nav-bar .code-section {
    float: left;
    width: em(111, 18);
    min-height: em(115, 18);
    padding: 0 em(58, 18) 0 em(29, 18);
    margin-bottom: em(13, 18);
}

.nav-mega .mega-nav-block-code-nav-bar .code-button {
    display: block;
    outline: none;
    font-size: .8889em;
    text-decoration: none;
}

.nav-mega .mega-nav-block-code-nav-bar .code-icon {
    margin: 0 auto;
}

.nav-mega .mega-nav-block-code-nav-bar .code-description {
    @extend .gustan-black;

    margin: em(7, 13) 0;
    font-size: em(13);
    line-height: lh(15, 13);
    color: $mbiebuilding_gray_5;
    text-align: center;
}

.nav-mega .mega-nav-block-code-nav-bar.active .code-section:not(.active) .code-button .code-icon,
.nav-mega .mega-nav-block-code-nav-bar.active .section-specific-buildings:not(.active) .code-image {
    background-color: $mbiebuilding_gray_2;
    color: $mbiebuilding_gray_2;
}

.nav-mega .mega-nav-block-code-nav-bar.active .code-section:not(.active) .code-button .code-description {
    color: $mbiebuilding_gray_2;
}

@media print, screen and (min-width: $mobile_min) {
    .nav-mega .mega-nav-block-code-nav-bar .code-description {
        margin: em(7, 12);
        font-size: em(12);
        line-height: lh(14, 12);
    }

    .code-icon {
        width: em(67, 37);
        height: em(73, 37);
        font-size: em(37);
        line-height: em(63, 37);
    }
}

@media only screen and (min-width: $tablet_min) and (max-width: $desktop_max) {
    .nav-mega .mega-nav-block-code-nav-bar .code-button {
        overflow: visible;
    }
}

