.block-signup {
    padding: rem(30) 0 rem(38);
    margin: rem(50) 0 rem(-60);

    &__title {
        padding-bottom: rem(18);
        border-bottom: rem(2) solid $mbiebuilding_gray_5;
        margin: 0 0 rem(20);
        font-size: rem(32);
        line-height: lh(32, 32);
        color: $mbiebuilding_gray_5;
    }

    &__text {
        @extend .gustan-book;

        font-size: rem(14);
        line-height: lh(21);
        color: $mbiebuilding_gray_5;

        p {
            margin-bottom: 0;
            font-size: inherit;
            line-height: inherit;
        }
    }

    &__actions {
        margin-top: rem(20);
    }

    &__button {
        width: 100%;
        min-width: rem(262);
        background: $mbiebuilding_blue_1;
        font-size: rem(18);
        color: $mbiebuilding_white_1;

        &:hover,
        &:focus {
            background: $mbiebuilding_blue_3;
        }
    }

    @media print, screen and (min-width: $mobile_min) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: rem(20);
        grid-row-gap: rem(20);

        &__title {
            grid-column: 1 / span 2;
        }

        &__text {
            grid-row: 2;
            padding-right: rem(20);
        }

        &__actions {
            grid-row: 2;
            grid-column: 2;
            justify-self: end;
            margin-top: 0;
        }

        &__button {
            width: auto;
        }
    }

    @media only screen and (min-width: $desktop_min) {
        grid-template-columns: 1fr 1fr auto;
        padding: rem(64) 0 rem(58);
        margin-top: rem(68);

        &__title {
            grid-column: 1;
            padding-bottom: 0;
            border-bottom: 0;
            margin-bottom: 0;
            margin-left: 0;
            font-size: rem(52);
            line-height: lh(35, 52);
        }

        &__text {
            grid-column: 2;
            grid-row: 1;
            margin-right: rem(40);

            @supports (display: grid) {
                margin-right: 0;
            }
        }

        &__actions {
            grid-column: 3;
            grid-row: 1;
        }
    }
}

