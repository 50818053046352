@use 'sass:math';

.essential-links {
    &__heading {
        margin-top: 0;
        margin-bottom: rem(10);
        font-size: rem(25);
    }

    &__description {
        @extend .gustan-book;

        padding-right: rem(55);
        font-size: rem(14);
    }

    &__item {
        padding-top: rem(20);
        border-top: 2px solid $mbiebuilding_gray_5;
        margin-top: rem(45);

        &:first-child {
            padding-top: 0;
            border-top: 0;
            margin-top: 0;
        }
    }

    &__list-item {
        &:not(:first-child) {
            margin-top: rem(10);
        }
    }

    @media screen and (min-width: $mobile_min) {
        @include grid-row();

        &__heading {
            margin-top: 0;
        }

        &__description {
            padding-right: 0;
        }

        &__item {
            @include span-column(math.div(1, 2), rem(33));

            margin-top: rem(60);

            &:nth-child(2) {
                padding-top: 0;
                border-top: 0;
                margin-top: 0;
            }
        }
    }

    @media (min-width: $tablet_min) {
        p {
            font-size: rem(14);
        }
    }

    @media (min-width: $desktop_min) {
        &__item {
            @include grid-row();

            padding-top: rem(25);
        }

        &__content {
            @include span-column(math.div(1, 3), rem(50));
        }

        &__list {
            @include span-column(math.div(2, 3), rem(50));
        }
    }

    @media print {
        &__item {
            display: block;
            border-top-width: 1px;
            margin-top: rem(20);
            page-break-inside: avoid;

            > * {
                max-width: none;
            }
        }
    }
}
