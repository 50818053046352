@use 'sass:math';
@import 'variables', 'colours';

/* -------------------------------------
   FUNCTIONS
   -------------------------------------
*/

/* Convert pixels to ems, passing pixels in only */
@function em($px, $context: 16, $basesize: 16) {
    @return math.div(math.div($px, $basesize), math.div($context, 16))+em;
}

/* Generate a line height without any rounding errors
   (for Firefox & Chrome), passing pixels in only */
@function lh($lineheight_in_pixels, $fontsize_in_pixels: 16) {
    @return (math.div(round($lineheight_in_pixels), $fontsize_in_pixels));
}

/* Tint colour */
@function tint($color, $amount: 50%) {
    @if $color == transparent {
        $color: rgba(white, 0);
    }

    @return mix($color, white, $amount);
}

/* Shade colour */
@function shade($color, $amount: 50%) {
    @if $color == transparent {
        $color: rgba(white, 0);
    }

    @return mix($color, black, $amount);
}


/* -------------------------------------
   MIXINS
   -------------------------------------
*/
@mixin gradient($startcolour, $endcolour) {
    background: $endcolour;
    //background: -moz-linear-gradient(top, $startcolour 0%, $endcolour 100%);
    //background: -webkit-gradient(linear, left top, left bottom,
    // color-stop(0%, $startcolour), color-stop(100%, $endcolour));
    //background: -webkit-linear-gradient(top, $startcolour 0%, $endcolour 100%);
    //background: -o-linear-gradient(top, $startcolour 0%, $endcolour 100%);
    //background: -ms-linear-gradient(top, $startcolour 0%, $endcolour 100%);
    background: linear-gradient(to bottom, $startcolour 0%, $endcolour 100%);
}

@mixin gradient-left-right($startcolour, $endcolour) {
    background: $endcolour;
    //background: -moz-linear-gradient(left, $startcolour 0%, $endcolour 100%); /* FF3.6+ */
    //background: -webkit-gradient(linear, left top, right top, color-stop(0%, $startcolour),
    // color-stop(100%, $endcolour)); /* Chrome,Safari4+ */
    //background: -webkit-linear-gradient(left, $startcolour 0%, $endcolour 100%); /* Chrome10+,Safari5.1+ */
    //background: -o-linear-gradient(left, $startcolour 0%, $endcolour 100%); /* Opera 11.10+ */
    //background: -ms-linear-gradient(left, $startcolour 0%, $endcolour 100%); /* IE10+ */
    background: linear-gradient(to right, $startcolour 0%, $endcolour 100%); /* W3C */
}

@mixin box-shadow($h, $v, $blur, $radius, $color) {
    //-webkit-box-shadow: $h $v $blur $radius $color;
    box-shadow: $h $v $blur $radius $color;
}

@mixin box-shadow-inset($h, $v, $blur, $radius, $color) {
    //-webkit-box-shadow: $h $v $blur $radius $color inset;
    box-shadow: $h $v $blur $radius $color inset;
}

@mixin text-shadow($h, $v, $blur, $color) {
    text-shadow: $h $v $blur $color;
}

@mixin box-sizing($type: border-box) {
    //-moz-box-sizing: border-box;
    //-webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin border-radius($radius) {
    //-webkit-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-radius-separate($topLeft: 5px, $topRight: 5px, $bottomLeft: 5px, $bottomRight: 5px) {
    //-webkit-border-top-left-radius: $topLeftRadius;
    //-webkit-border-top-right-radius: $topRightRadius;
    //-webkit-border-bottom-right-radius: $bottomRightRadius;
    //-webkit-border-bottom-left-radius: $bottomLeftRadius;
    //-moz-border-radius-topleft: $topLeftRadius;
    //-moz-border-radius-topright: $topRightRadius;
    //-moz-border-radius-bottomright: $bottomRightRadius;
    //-moz-border-radius-bottomleft: $bottomLeftRadius;
    border-radius: $topLeft $topRight $bottomRight $bottomLeft;
}

@mixin transition($scope: all, $time: .2s, $ease:ease-in-out, $delay:0s) {
    //-webkit-transition: $scope $time $ease $delay;
    //-moz-transition: $scope $time $ease $delay;
    //-o-transition: $scope $time $ease $delay;
    transition: $scope $time $ease $delay;
}

@mixin appearance($type:none) {
    //-webkit-appearance: $type;
    //-moz-appearance: $type;
    appearance: $type;
}

@mixin filter($type:grayscale, $amount:100%) {
    //-webkit-filter: $type ($amount);
    //-moz-filter: $type ($amount);
    //-ms-filter: $type ($amount);
    //-o-filter: $type ($amount);
    filter: $type ($amount);
}

@mixin box-sizing($type:border-box) {
    //-webkit-box-sizing: $type;
    //-moz-box-sizing: $type;
    box-sizing: $type;
}

@mixin transform-translate($x:0, $y:0) {
    //-webkit-transform: translate($x, $y);
    //-moz-transform: translate($x, $y);
    //-ms-transform: translate($x, $y);
    //-o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin transform-translate3d($x:0, $y:0, $z:0) {
    //-webkit-transform: translate3d($x, $y, $z);
    //-moz-transform: translate3d($x, $y, $z);
    //-ms-transform: translate3d($x, $y, $z);
    //-o-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin transform-perspective($amount:1000px) {
    //-webkit-transform: perspective($amount);
    //-moz-transform: perspective($amount);
    //-ms-transform: perspective($amount);
    //-o-transform: perspective($amount);
    transform: perspective($amount);
}

@mixin transform-style($type:preserve-3d) {
    //-webkit-transform-style: $type;
    //-moz-transform-style: $type;
    //-ms-transform-style: $type;
    //-o-transform-style: $type;
    transform-style: $type;
}

@mixin backface-visibility($visibility:hidden) {
    //-webkit-backface-visibility: $visibility;
    //-moz-backface-visibility: $visibility;
    //-ms-backface-visibility: $visibility;
    //-o-backface-visibility: $visibility;
    backface-visibility: $visibility;
}

@mixin transform-rotateY($degrees:0deg) {
    //-webkit-transform: rotateY($degrees);
    //-moz-transform: rotateY($degrees);
    //-ms-transform: rotateY($degrees);
    //-o-transform: rotateY($degrees);
    transform: rotateY($degrees);
}

@mixin transform-rotate($degrees:0deg) {
    //-webkit-transform: rotate($degrees);
    //-moz-transform: rotate($degrees);
    //-ms-transform: rotate($degrees);
    //-o-transform: rotate($degrees);
    transform: rotate($degrees);
}
