
@import '../tools';

/*
 ---------------------------------------
 FORMS - GENERAL STYLES
 Silverstripe
 ---------------------------------------
*/

.form .field,
.userform .field {
    position: relative;
    padding: em(10) 0;
}

.form label,
.form .label,
.userform label,
.userform legend {
    @extend .gustan-bold;

    display: block;
    margin: 0 0 em(12);
    font-size: em(14);
    line-height: em(19);
}

.form input.text,
.form textarea,
.userform input.text,
.userform textarea {
    @include box-sizing(border-box);

    width: 100%;
    padding: em(11) em(13);
    border: 1px solid $mbiebuilding_blue_1;
    border-radius: 0;
    background: #fff;
    line-height: lh(20);
    color: $mbiebuilding_gray_5;
    -webkit-appearance: none;
}

.form input.text:focus,
.form textarea:focus {
    @include box-shadow(0, 0, 3px, 0, $mbiebuilding_blue_4);

    outline: 0;
}

.form textarea,
.userform textarea {
    min-height: em(200);
}

@media print, screen and (min-width: $mobile_min) {
    .form input.text,
    .form textarea,
    .userform input.text,
    .userform textarea {
        padding: em(11, 14) em(13, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .form .field,
    .userform .field {
        padding: em(10) 0 em(18);
    }

    .form label,
    .form .label,
    .userform label,
    .userform legend {
        margin: 0 0 em(9, 14);
        line-height: em(19, 14);
    }
}

ul.optionset,
ul.step-buttons {
    margin: 0;

    li {
        font-size: em(16, 16);

        &::before {
            display: none;
        }
    }
}

/* Form error messages */

.error-container * {
    color: $mbiebuilding_red_1;

    .error-list {
        padding-left: em(20);
        margin: 0;
        list-style: disc;

        li {
            &::before {
                display: none;
            }
        }
    }

    h4 {
        padding: em(8) em(16) em(8) em(16);
        border-left: 7px solid $mbiebuilding_red_1;
        margin-bottom: em(16);
        background-repeat: no-repeat;
        background-color: #f4d6da;
        color: $mbiebuilding_gray_5;
    }
}

.form-message {
    @include border-radius(em(21) 0 em(21) em(21));

    padding: 1em;
    margin: 0 0 1em;
    background: $mbiebuilding_gray_1;
}

.form-message.error,
form .message.required {
    color: $mbiebuilding_red_2;
}

.form-message p {
    margin: 0 !important;
}

/* JS generated clear icon */

.js-clear {
    position: relative;
}

.js-clear input.text,
.js-clear textarea {
    padding-right: 40px;
}

/* keep as px */

.js-clear .icon-close {
    @include transition(opacity, .5s);

    position: absolute;
    top: 0;
    right: 8px;
    padding: 9px;
    font-size: 18px;
    color: $mbiebuilding_blue_3;
    opacity: 1;
    cursor: pointer;
}

/* keep as px */

.js-clear.active .icon-close {
    opacity: 0;
}

/* Radio/checkboxes (input style only) */

.js-radio label {
    position: relative;
    padding: 0 0 0 em(28);
    font-size: em(14);
    cursor: pointer;
}

.js-radio label.focus {
    outline: 1px dotted $mbiebuilding_gray_1;
}

.js-radio label::after {
    position: absolute;
    top: em(2);
    left: em(2);
    content: '';
    width: em(19);
    height: em(19);
    background-repeat: no-repeat;
    background-size: contain;
}

.js-radio .radio::after {
    background-image: url($img-path + 'icons/form-radio-off.svg');
}

.no-svg .js-radio .radio::after {
    background-image: url($img-path + 'icons/form-radio-off-ie.png');
}

.js-radio .radio.active::after {
    background-image: url($img-path + 'icons/form-radio-on.svg');
}

.no-svg .js-radio .radio.active::after {
    background-image: url($img-path + 'icons/form-radio-on-ie.png');
}

.js-radio .checkbox::after {
    background-image: url($img-path + 'icons/form-checkbox-off.svg');
}

.no-svg .js-radio .checkbox::after {
    background-image: url($img-path + 'icons/form-checkbox-off-ie.png');
}

.js-radio .checkbox.active::after {
    background-image: url($img-path + 'icons/form-checkbox-on.svg');
}

.no-svg .js-radio .checkbox.active::after {
    background-image: url($img-path + 'icons/form-checkbox-on-ie.png');
}

.js-radio input[type=checkbox],
.js-radio input[type=radio] {
    position: absolute;
    left: -999em;
}

.js-radio input[type=checkbox]:focus + label::after,
.js-radio input[type=radio]:focus + label::after {
    outline: 1px dotted $mbiebuilding_blue_1;
}

.userform .error {
    border-color: $mbiebuilding_red_1 !important;
    color: $mbiebuilding_red_1 !important;
}

.userform .error.message {
    display: inline-block;
    margin: 4px 0;
    font-size: em(14);
}


/* Additional SS form styles */

.userform .right-title {
    display: inline-block;
    margin: em(16) 0;
    font-size: 14px;
    line-height: 1;
}

input[type=file] {
    padding: em(16);
    border: 1px dashed #424242;
    cursor: pointer;
}

.userform legend {
    display: block;
}

.userform fieldset.userformsgroup {
    padding: em(16);
    border: 1px solid $mbiebuilding_gray_5;
}

/* userform progress */

.userform-progress {
    margin-top: em(40);
    margin-bottom: em(40);
}

.userform-progress .step-buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-bottom: 1px solid $mbiebuilding_blue_1;
    margin: em(16);
    list-style-type: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
}

.step-buttons .step-button-wrapper {
    display: inline-block;
    margin: 0;
    list-style-type: none;
}

.userform-progress .step-buttons .step-button-wrapper .step-button-jump {
    position: absolute;
    bottom: em(-24);
    width: em(40);
    height: em(40);
    min-width: auto;
    padding: 0;
    border: 2px solid $mbiebuilding_blue_1;
    border-radius: 100%;
    margin: 0 em(-24);
    background-color: #fff;
    font-size: em(18);
    line-height: 1;
    font-weight: 500;
    color: $mbiebuilding_blue_1;
    text-align: center;
    cursor: default;
}

.progress {
    position: relative;
    height: em(16);
    border: 1px solid $mbiebuilding_blue_1;
    background-color: #fff;
}

.progress .progress-bar {
    position: absolute;
    height: em(16);
    background-color: $mbiebuilding_blue_1;
}

.userform-progress p {
    margin-top: em(36);
    font-size: em(14);
    text-align: right;
}

/*
 JQUERY AUTOCOMPELTE
---------------------------------------;
*/

.ui-helper-hidden {
    display: none;
}

.ui-helper-hidden-accessible {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0 0 0 0);
}

.ui-helper-reset {
    padding: 0;
    border: 0;
    outline: 0;
    margin: 0;
    font-size: 100%;
    line-height: 1.3;
    text-decoration: none;
    list-style: none;
}

.ui-helper-clearfix::before,
.ui-helper-clearfix::after {
    display: table;
    content: '';
    border-collapse: collapse;
}

.ui-helper-clearfix::after {
    clear: both;
}

.ui-helper-clearfix {
    min-height: 0; /* support: IE7 */
}

.ui-helper-zfix {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: alpha(opacity=0); /* support: IE8 */
}

.ui-front {
    z-index: 100;
}

/* Interaction Cues */

.ui-state-disabled {
    cursor: default !important;
}

/* Overlays */

.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ui-autocomplete {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba($mbiebuilding_blue_1, .97);
    color: #fff;
    cursor: default;
}

.ui-menu {
    display: block;
    padding: 0;
    outline: none;
    margin: 0;
    list-style: none;
}

.ui-menu .ui-menu {
    position: absolute;
}

.ui-menu .ui-menu-item {
    position: relative;
    min-height: 0;
    padding: 3px 1em 3px .4em;
    margin: 0;
    color: #fff;
    cursor: pointer;
}

/*
 SELECT2 THEME
---------------------------------------;
*/

select {
    width: 100%;
}

.select2-hidden-accessible {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0 0 0 0);
}

.select2-container {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    margin: 0;
}

.select2-container .select2-selection--single {
    display: block;
    box-sizing: border-box;
    min-height: em(28);
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    min-height: em(20);
    overflow: hidden;
    padding: em(11) em(30) em(11) em(13);
    line-height: lh(20);
    text-overflow: ellipsis;
}

.select2-container .select2-selection--multiple {
    display: block;
    box-sizing: border-box;
    min-height: em(44);
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
}

.select2-container .select2-search--inline {
    float: left;
}

.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    margin-top: em(8);
    font-size: 100%;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.select2-dropdown {
    position: absolute;
    left: -100000px;
    z-index: 1051;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid $mbiebuilding_blue_1;
    background-color: white;
}

.select2-results {
    display: block;
}

.select2-results__options {
    padding: 0;
    margin: 0;
    list-style: none;
}

.select2-results__option {
    padding: em(12) em(13);
    border-bottom: 1px solid tint($mbiebuilding_gray_2, 50%);
    color: $mbiebuilding_gray_5;
    -webkit-user-select: none;
    user-select: none;
}

.select2-results__option[aria-selected] {
    cursor: pointer;
}

.select2-container--open .select2-dropdown {
    left: 0;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
}

.select2-search--dropdown {
    display: block;
    padding: 4px;
}

.select2-search--dropdown .select2-search__field {
    @extend .fira-sans-regular;

    box-sizing: border-box;
    width: 100%;
    padding: em(9) em(8);
    color: $mbiebuilding_body;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
    display: none;
}

.select2-close-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: block;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    padding: 0;
    border: 0;
    margin: 0;
    background-color: #fff;
    opacity: 0;
    filter: alpha(opacity=0);
}

.select2-container--default .select2-selection--single {
    border: 1px solid $mbiebuilding_blue_1;
    outline: none;
    background-color: #fff;
}

.select2-container--default.select2-container--open .select2-selection--single {
    @include box-shadow(0, 0, 3px, 0, $mbiebuilding_blue_4);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $mbiebuilding_gray_5;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    position: relative;
    float: right;
    width: 20px;
    height: 1px;
    overflow: hidden;
    padding-top: 18px;
    cursor: pointer;
}

.select2-container--default .select2-selection--single .select2-selection__clear::before {
    position: absolute;
    top: 1px;
    left: 1px;
    content: $icon-close;
    font-size: 18px;
    color: $mbiebuilding_blue_3;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: em(12);
    right: em(12);
}

.select2-selection__arrow::before {
    content: '\e806';
    font-size: em(20);
    color: $mbiebuilding_blue_1;
}

.select2-container--open .select2-selection__arrow::before {
    content: '\e805';
}

.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__arrow {
    right: auto;
    left: 1px;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
    border: 1px solid $mbiebuilding_blue_1;
    background-color: #fff;
    cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    width: 96%;
    padding: 0 3px;
    margin: 0;
    list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    float: left;
    margin-top: 5px;
    color: #999;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    float: right;
    margin-top: 5px;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    float: left;
    padding: em(9, 14);
    margin-top: em(3, 14);
    margin-right: em(3, 14);
    background-color: $mbiebuilding_blue_1;
    font-size: em(14);
    line-height: 1;
    color: #fff;
    cursor: default;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: right;
    height: em(16, 18);
    margin: em(-2, 18) em(1, 18) 0 em(10, 18);
    font-size: em(18, 14);
    font-weight: bold;
    color: $mbiebuilding_blue_7;
    cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #fff;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__placeholder {
    float: right;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice {
    margin-right: auto;
    margin-left: 5px;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice__remove {
    margin-right: auto;
    margin-left: 2px;
}

.select2-container--default.select2-container--focus {
    @include box-shadow(0, 0, 3px, 0, $mbiebuilding_blue_4);

    outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid $mbiebuilding_blue_4;
}

.select2-container--default .select2-search--inline .select2-search__field {
    padding: em(4) 0 0 em(7);
    border: none;
    outline: 0;
    background: transparent;
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: em(200);
    overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $mbiebuilding_blue_1 !important;
    color: #fff;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    padding-left: 2em;
    margin-left: -1em;
}

/* stylelint-disable */

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    padding-left: 3em;
    margin-left: -2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    padding-left: 4em;
    margin-left: -3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    padding-left: 5em;
    margin-left: -4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    padding-left: 6em;
    margin-left: -5em;
}
/* stylelint-enable */

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $mbiebuilding_gray_2;
}

.select2-container--default .select2-results__group {
    display: block;
    padding: 6px;
    cursor: default;
}

.select2-container--default .select2-results__option span.tip {
    display: block;
    font-size: 95%;
    color: $mbiebuilding_gray_3;
}

.select2-container--default .select2-results__option--highlighted span.tip {
    color: #fff;
}

@media only screen and (max-width: $mobile_max) {
    .select2-container {
        width: 100% !important;
    }
}

@media print, screen and (min-width: $mobile_min) {
    .select2-container .select2-selection--single .select2-selection__rendered {
        min-height: em(20, 14);
        padding: em(11, 14) em(30, 14) em(11, 14) em(13, 14);
        font-size: em(14);
        line-height: 1.43;
    }

    .select2-results__option {
        padding: em(12, 14) em(13, 14);
        font-size: em(14);
    }

    .select2-search--dropdown .select2-search__field {
        padding: em(8, 14) em(11, 14);
        font-size: em(14);
    }

    .select2-container--default .select2-search--inline .select2-search__field {
        padding: em(5, 14) 0 0 em(7, 14);
        font-size: em(14);
    }
}


/*
 ---------------------------------------
 BUTTONS
 ---------------------------------------
*/

/* Simple button */

.button,
input[type=submit],
button,
.button-2 {
    @extend .gustan-light;
    @include box-sizing(border-box);

    position: relative;
    display: inline-block;
    min-width: em(241);
    padding: em(9) em(20);
    border: 1px solid $mbiebuilding_blue_1;
    border-radius: 0;
    outline: none;
    background: #fff;
    font-size: em(16);
    color: $mbiebuilding_blue_1;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

.button,
button,
input[type=submit] {
    transition: background-color .2s ease-in-out, border-color .2s ease-in-out, color .2s ease-in-out;
}

a:hover .button,
a:active .button,
a:focus .button,
.button:hover,
.button:active,
.button:focus,
input[type=submit]:hover,
input[type=submit]:active,
input[type=submit]:focus,
button:hover,
button:active,
button:focus,
.active .button-light-blue {
    background: $mbiebuilding_blue_1;
    color: #fff;
    text-decoration: none;
}

.button .icon-plus-circle {
    position: absolute;
    top: em(6, 20);
    left: em(6, 20);
    font-size: em(20, 16);
}

.button .amount {
    color: $mbiebuilding_body;
}

/* Lighter version */

.button-light-blue {
    min-width: unset;
    padding: em(9) em(27);
    border: none;
    background: $mbiebuilding_blue_6;
    color: $mbiebuilding_blue_1;
}

.button-light-blue .icon {
    margin-left: .25em;
}

/* Darker version */

.button-dark-blue,
.Actions .button-dark-blue {
    background: $mbiebuilding_blue_2;
    color: #fff;
}

/*
 ---------------------------------------
 SEARCH FILTER FORMS
 ---------------------------------------
*/

.search-filter {
    padding-top: em(20);
    padding-bottom: 0;
    border-top: 4px solid $mbiebuilding_gray_2;
    margin-top: em(40);
    background: #fff;
}

.search-filter .header {
    margin: em(16) 0 em(13);
}

.search-filter .header h2 {
    margin: 0;
}

.search-filter .header p {
    margin: em(27) 0 em(2);
}

.search-filter .icon-alert {
    color: darken($mbiebuilding_orange_1, 25%);
}

.search-filter .header .help {
    position: relative;
    padding-left: em(35);
}

.search-filter .header .help .icon-alert {
    position: absolute;
    top: em(-7, 25);
    left: 0;
    font-size: em(25);
    color: darken($mbiebuilding_orange_1, 2%);
}

.search-filter .filter-region .select2 {
    max-width: em(400);
    min-width: em(265);
}

.search-filter .Actions {
    padding-bottom: em(22);
    text-align: center;
}

.search-filter .Actions .action {
    min-width: inherit;
    padding-right: em(60);
    padding-left: em(60);
    margin: em(28) 0 0;
}

.search-filter .Actions .filter-reset {
    display: block;
    padding: em(16, 15);
    font-size: em(15);
    text-align: center;
}

.search-filter .Actions .filter-reset .icon-cross {
    margin-right: em(7, 14);
    font-size: em(14, 15);
    color: $mbiebuilding_blue_3;
}

#shell .search-filter .tip-box {
    padding-top: em(14);
    padding-bottom: 0;
    margin: 0;
}

@media print, screen and (min-width: $mobile_min) {
    .search-filter {
        padding-bottom: em(6);
    }

    .search-filter .header {
        padding-bottom: em(22);
        border-bottom: 1px solid $mbiebuilding_gray_2;
        margin: em(16) 0 em(5);
    }

    .search-filter .field .select2 {
        width: 100% !important;
    }

    .search-filter .Actions {
        position: relative;
        padding-bottom: em(45);
    }

    .search-filter .Actions .action {
        margin-top: em(12);
    }

    .search-filter .Actions .filter-reset {
        position: absolute;
        top: em(7, 15);
        right: 0;
    }

    .search-filter .Actions .filter-reset .icon-cross::before {
        text-decoration: none;
    }
}

@media only screen and (min-width: $tablet_min) {
    .search-filter {
        min-height: em(307);
        padding-bottom: 0;
        margin-top: em(67);
    }

    .search-filter .header {
        padding-bottom: em(31);
        margin: em(16) 0 em(21);
    }

    .search-filter .header p {
        margin: em(24, 14) 0 em(6, 14);
        font-size: em(14);
    }

    .search-filter .header .help {
        padding-left: em(35, 14);
    }

    .search-filter .header .help .icon-alert {
        font-size: em(25, 14);
    }

    #shell .search-filter .tip-box {
        margin-left: em(9);
    }

    .search-filter .Actions {
        padding-bottom: em(50);
    }
}

@media only screen and (min-width: $desktop_min) {
    .search-filter .field input.text {
        width: em(264, 14);
    }

    .search-filter .field .select2 {
        width: em(264) !important;
    }
}


/* BCA A to Z */

.filter-az {
    overflow: hidden;
}

.filter-az li {
    @extend .gustan-black;

    float: left;
    width: 16.6%;
    font-size: em(20);
    line-height: 1;
}

.filter-az li a,
.filter-az li span {
    display: block;
    padding: em(14, 20) 0;
    border: 1px solid $mbiebuilding_blue_1;
    margin: 0 em(3, 20) em(5, 20) 0;
    background-color: #fff;
    text-align: center;
}

.filter-az li span {
    border-color: $mbiebuilding_gray_7;
    color: $mbiebuilding_gray_7;
}

.filter-az li a:hover,
.filter-az li a:active,
.filter-az li a:focus {
    background-color: $mbiebuilding_blue_4;
    color: #fff;
    text-decoration: none;
}

.filter-az .active a {
    background-color: $mbiebuilding_blue_1;
    color: #fff;
}

.bca-search-filter .Actions .action {
    margin-top: 0;
}

@media only screen and (min-width: $mobile_min) {
    .filter-az li {
        width: em(42, 20);
    }

    .filter-az li:first-child {
        width: em(48, 20);
    }

    .filter-az .js-scroll-trigger.active {
        opacity: 1;
    }

    .filter-az .js-scroll-trigger .scroll-button {
        width: em(42);
        height: em(42);
        border: 1px solid $mbiebuilding_blue_1;
    }

    .filter-az .js-scroll-trigger .icon {
        margin-top: 0;
    }
}

@media only screen and (min-width: $tablet_min) {
    .bca-search-filter .Actions .action {
        margin-top: em(20);
    }
}

@media only screen and (min-width: $mobile_min) and (max-width: 1200px) {
    .filter-az .overflow-liner {
        height: em(52);
    }

    .filter-az ul {
        position: absolute;
        width: em(1150);
        height: em(52);
    }

    /* pos:abs is required for chrome overflow bug */
}

@media print, screen and (min-width: $mobile_min) {
    .filter-az.field {
        padding-top: em(15);
    }

    .filter-az li a,
    .filter-az li span {
        padding: em(11, 20) 0;
    }
}

/* Determinations register search filter */

@media print, screen and (min-width: $mobile_min) {
    .news-updates-search-filter .field,
    .determinations-search-filter .field {
        float: left;
        width: 47%;
        margin-right: em(30);
    }

    .news-updates-search-filter .field:nth-child(2n+1),
    .determinations-search-filter .field:nth-child(2n+1) {
        margin-right: 0;
    }

    .news-updates-search-filter .field.nth-child-2-1,
    .determinations-search-filter .field.nth-child-2-1 {
        margin-right: 0;
    }
}

@media only screen and (min-width: 900px) {
    .news-updates-search-filter .field,
    .determinations-search-filter .field {
        width: 30.9%;
    }

    .news-updates-search-filter .field:nth-child(2n+1),
    .determinations-search-filter .field:nth-child(2n+1) {
        margin-right: em(30);
    }

    .news-updates-search-filter .field.nth-child-2-1,
    .determinations-search-filter .field.nth-child-2-1 {
        margin-right: em(30);
    }

    .news-updates-search-filter .field:last-child,
    .determinations-search-filter .field:last-child {
        margin-right: 0;
    }
}

@media only screen and (min-width: $desktop_min) {
    .news-updates-search-filter .field,
    .determinations-search-filter .field {
        width: 23%;
        margin-right: em(30);
    }

    .news-updates-search-filter .Actions,
    .determinations-search-filter .Actions {
        margin-top: em(10);
    }
}

/* Multiproof/Product register/Consumer complaint */
@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .multiproof-search-filter .field,
    .product-register-search-filter .field,
    .consumer-complaint-search-filter .field {
        float: left;
        width: 47.7%;
        margin-right: 4.4%;
    }

    .multiproof-search-filter .field:nth-child(2n+1),
    .product-register-search-filter .field:nth-child(2n+1),
    .consumer-complaint-search-filter .field:nth-child(2n+1) {
        margin-right: 0;
    }

    .multiproof-search-filter .field.nth-child-2-1,
    .product-register-search-filter .field.nth-child-2-1,
    .consumer-complaint-search-filter .field.nth-child-2-1 {
        margin-right: 0;
    }

    .multiproof-search-filter .field:last-child,
    .product-register-search-filter .field:last-child,
    .consumer-complaint-search-filter .field:last-child,
    .multiproof-search-filter .field.last,
    .product-register-search-filter .field.last,
    .consumer-complaint-search-filter .field.last {
        width: auto;
        padding: em(4);
    }
}

@media only screen and (min-width: $tablet_min) {
    .multiproof-search-filter .field,
    .product-register-search-filter .field,
    .consumer-complaint-search-filter .field {
        float: left;
        width: 23%;
        margin-right: em(30);
    }

    .multiproof-search-filter .field:last-child,
    .product-register-search-filter .field:last-child,
    .consumer-complaint-search-filter .field:last-child,
    .multiproof-search-filter .field.last,
    .product-register-search-filter .field.last,
    .consumer-complaint-search-filter .field.last {
        width: 45.5%;
        margin-right: 0;
    }
}

/* Site search */

.search.search-filter {
    position: relative;
    border-top: none;
    margin-top: em(-6);
}

.search.search-filter::before {
    @include gradient-left-right($mbiebuilding_blue_2, $mbiebuilding_blue_4);

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: '';
    height: 4px;
}

.site-search-filter .optionset {
    overflow: hidden;
}

.site-search-filter .optionset .js-radio {
    @include transition(all, .5s, ease-in-out);

    float: left;
    width: 50%;
    font-size: em(14);
}

.site-search-filter .optionset .js-radio label {
    padding: em(12, 14) 0;
    border: 1px solid $mbiebuilding_blue_1;
    margin: 0;
    background-color: #fff;
    color: $mbiebuilding_blue_1;
    text-align: center;
}

.site-search-filter .optionset .js-radio.selected label {
    background-color: $mbiebuilding_blue_1;
    color: #fff;
}

.site-search-filter .optionset .js-radio label::after {
    display: none;
}

.site-search-filter .optionset .js-radio.selected input:focus + label {
    outline: 1px dotted #fff;
    outline-offset: -2px;
}


/* stylelint-disable */
// IE11 focus state fix as outline-offset not supported

.site-search-filter .optionset {
    .no-objectfit & {
        overflow: visible;
    }
}

.site-search-filter .optionset .js-radio.selected input:focus + label {
    .no-objectfit & {
        @include box-shadow(0, 0, 4px, 1px, $mbiebuilding_blue_4);

        outline: 0;
    }
}
/* stylelint-enable */

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    .search.search-filter {
        margin-top: em(-4);
    }

    .site-search-filter .field {
        float: left;
        width: 47.7%;
        margin-right: 4.4%;
    }

    .site-search-filter .field:nth-child(2n+1) {
        margin-right: 0;
    }

    .site-search-filter .field.nth-child-2-1 {
        margin-right: 0;
    }
}

@media only screen and (min-width: $tablet_min) {
    .search.search-filter {
        margin-top: em(-8);
    }

    .site-search-filter .field {
        float: left;
        width: 23%;
        margin-right: em(30);
    }

    .site-search-filter .field:last-child,
    .site-search-filter .field.last {
        margin-right: 0;
    }

    .site-search-filter .fieldset-1 {
        padding-bottom: em(9);
    }
}

@media screen and (min-width: $tablet_min) and (max-width: $desktop_max) {
    .site-search-filter .field:last-child,
    .site-search-filter .field.last {
        width: 18%;
    }

    .site-search-filter .optionset .js-radio {
        float: none;
        width: 100%;
    }
}


/* Search filter variation (submit and tip box swap) */

#shell .search-filter-variation-1 .tip-box {
    padding-bottom: em(26);
}

/* New */

@media only screen and (max-width: $mobile_max) {
    #shell .search-filter-variation-1 .Actions {
        padding-bottom: 0;
    }

    /* New */

    #shell .search-filter-variation-1 .Actions input {
        margin-top: em(17);
    }

    /* New */

    #shell .search-filter-variation-1 .tip-box {
        padding-top: em(4);
    }

    /* New */
}

@media print, screen and (min-width: $mobile_min) and (max-width: $tablet_max) {
    #shell .search-filter-variation-1 .Actions {
        clear: both;
        padding-bottom: em(23);
    }

    /* New */
}

@media only screen and (min-width: $tablet_min) {
    #shell .search-filter-variation-1 .Actions {
        float: left;
        width: 45.5%;
        padding: em(25) 0;
        text-align: left;
    }

    /* New */

    #shell .search-filter-variation-1 .Actions .filter-reset {
        top: em(31, 15);
    }

    /* New */

    #shell .search-filter-variation-1 .tip-box {
        margin-left: em(-21);
    }

    /* New */
}

@media only screen and (min-width: $desktop_min) {
    #shell .search-filter-variation-1 .Actions input {
        margin-left: 18.5%;
    }

    /* New */
}


/*
 ---------------------------------------
 MODAL FORMS
 ---------------------------------------
*/

.mbie-modal .form {
    text-align: left;
}

/*
 ---------------------------------------
 ADMIN LOGIN FORM
 ---------------------------------------
*/

.login-form {
    margin: 2em 0 0;
}

.login-form .checkbox label.checkbox {
    padding-right: em(30);
    margin: 0;
}

.login-form .Actions {
    margin: 1em 0;
}

.login-form .Actions p {
    margin: 2em 0 0;
}
