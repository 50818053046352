.featured-tiles {
    $self: &;

    margin-top: rem(40);

    &__tile {
        display: block;
        background-color: $mbiebuilding_blue_2;
        color: $mbiebuilding_white_1;
        transition: color .2s ease-in-out;

        &:not(:first-child) {
            margin-top: rem(20);
        }
    }

    a#{$self}__tile {
        &:hover,
        &:focus {
            text-decoration: none;

            #{$self}__heading {
                border-color: $mbiebuilding_blue_4;
                color: $mbiebuilding_blue_4;
            }
        }

        #{$self}__icon {
            margin-left: rem(10);

            &::before {
                display: inline-block;
                content: '';
                width: .5em;
                height: .7em;
                background: url($img-path + 'icons/icon-chevron-right-blue.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }

        &--external {
            #{$self}__icon {
                display: inline-flex;
                align-items: center;

                &::before {
                    width: .7em;
                    height: .7em;
                    background: url($img-path + 'icons/icon-external.svg');
                }
            }
        }
    }

    &__content {
        @include box-sizing();

        justify-content: center;
        padding: rem(22) rem(30) rem(28) rem(25);
    }

    &__heading-wrap {
        @include box-sizing();
    }

    &__heading {
        display: inline;
        border-bottom: rem(1) solid transparent;
        margin: 0;
        font-size: rem(25);
        color: $mbiebuilding_white_1;
        transition: color .2s ease-in-out, border-color .2s ease-in-out;
    }

    &__icon-wrap {
        display: inline-flex;
    }

    &__description {
        @include box-sizing();
        @extend .fira-sans-light;
    }

    &__image-wrap {
        height: 100%;
    }

    &__image {
        object-fit: cover;
        width: 100%;
    }

    // Override (.page-maincontent) styles
    p {
        margin-top: rem(13);
        margin-bottom: 0;
        font-size: rem(16);
        line-height: lh(20, 16);
    }

    img {
        height: 100%;
    }

    @media screen and (min-width: $mobile_min) {
        &__tile {
            // Fallback layout
            &:not([data-state]) {
                #{$self}__content {
                    // Fallback layout must come first
                    display: flex;
                    flex-wrap: wrap;

                    /* stylelint-disable-next-line order/order */
                    @include grid-l(230px, 0, 'inherit', 'center');
                }

                #{$self}__heading-wrap,
                #{$self}__description {
                    width: 50%;
                }

                p {
                    margin-top: 0;
                }
            }

            &[data-state='image-left'],
            &[data-state='image-right'] {
                // Fallback layout must come first
                display: flex;
                flex-wrap: wrap;

                /* stylelint-disable-next-line order/order */
                @include grid-l(250px, 0, 'inherit', 'center');

                #{$self}__content,
                #{$self}__image-wrap {
                    width: 50%;
                }

                #{$self}__heading-wrap {
                    padding-right: 0;
                }

                #{$self}__description {
                    padding-left: 0;
                }
            }

            // Image position
            &[data-state='image-right'] {
                #{$self}__image-wrap {
                    order: 1;
                }
            }
        }

        &__heading-wrap {
            padding-right: rem(40);
        }

        &__description {
            padding-left: rem(25);
        }
    }

    @media screen and (min-width: $tablet_min) {
        &__content {
            padding: rem(35);
        }
    }

    @media screen and (min-width: $desktop_min) {
        &__tile {
            &:not(:first-child) {
                margin-top: rem(40);
            }

            &:not([data-state]) {
                #{$self}__description {
                    padding-right: rem(40);
                    padding-left: rem(60);
                }
            }

            &[data-state='image-left'],
            &[data-state='image-right'] {
                #{$self}__description {
                    margin-top: rem(20);
                }
            }
        }

        a#{$self}__tile {
            #{$self}__icon {
                margin-left: rem(15);
            }
        }

        &__content {
            padding-right: rem(60);
            padding-left: rem(60);
        }

        &__heading {
            font-size: rem(40);
            line-height: lh(40, 40);
        }

        p {
            font-size: rem(18);
        }
    }

    @media print {
        &__tile {
            overflow: hidden;
            padding-top: 20px;
            border-top: 1px solid $mbiebuilding_gray_2;
            background-color: transparent;
            color: $mbiebuilding_blue_1;
            page-break-inside: avoid;

            &:first-child {
                padding-top: 0;
                border-top: 0;
            }

            &[data-state='image-left'],
            &[data-state='image-right'] {
                #{$self}__image-wrap,
                #{$self}__content {
                    float: left;
                    width: 60%;
                }

                #{$self}__image-wrap {
                    width: 35%;
                    margin-right: 4.5%;
                }
            }
        }

        &__content {
            padding: 0;
            margin-bottom: rem(16);
        }

        &__heading {
            color: $mbiebuilding_blue_1;
        }
    }
}
