
@import '../tools';


/*
 ---------------------------------------
 BLOCK - alert (version 03)
 ---------------------------------------
*/

.block-alert03 {
    position: relative;
    overflow: hidden;
    padding: em(12, 16) em(14, 16) em(1, 16) em(49, 16);
    background-color: transparent;

    @media print, screen and (min-width: $mobile_min) {
        padding: em(14, 16) em(14, 16) em(9, 16) em(74, 16);
    }
}

.block-alert03 .icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: em(25);
    font-weight: bold;
    color: $mbiebuilding_orange_2;

    @media print, screen and (min-width: $mobile_min) {
        top: em(8, 25);
        left: 0;
        font-size: em(25);
    }
}

.block-alert03 .description {
    margin-top: 0;
    font-size: em(16);
    line-height: lh(21, 16);
    color: $mbiebuilding_gray_5;
}

.block-alert03 .description p,
.block-alert03 .description li {
    font-size: inherit !important;
    line-height: inherit !important;
}
