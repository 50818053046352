// ############################################
// List of classes used in the HTML editor
// ############################################

.text {
    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }

    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }
}
