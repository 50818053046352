.search-result-metadata {
    margin-top: rem(23);

    &__date {
        @extend .gustan-black;

        font-size: rem(12);
        text-transform: uppercase;
    }

    .tag-list {
        margin-top: rem(22);
    }

    @media (min-width: $mobile_min) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0;

        &__date {
            order: 1;
        }

        .tag-list {
            margin-top: 0;
        }
    }
}
