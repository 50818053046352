
@import '../tools';

/*
 ---------------------------------------
 BLOCK - alert (version 02)
 ---------------------------------------
*/

.building-highlight-note-blue,
.building-highlight-note-white,
.building-highlight-note-gray {
    overflow: hidden;
    padding: em(20, 15);
    margin: 1.5em 0;
    background-color: $mbiebuilding_blue_6;
    font-size: em(15);
    line-height: 1.43;
    color: $mbiebuilding_gray_5;
}

.building-highlight-note-white {
    background-color: #fff;
}

.building-highlight-note-gray {
    background-color: tint($mbiebuilding_gray_2, 50%);
}

div.building-highlight-note-blue,
div.building-highlight-note-white,
div.building-highlight-note-gray {
    padding-bottom: 0;
}

.building-highlight-note-blue p,
.building-highlight-note-blue li,
.building-highlight-note-white p,
.building-highlight-note-white li,
.building-highlight-note-gray p,
.building-highlight-note-gray li {
    font-size: inherit !important;
    line-height: inherit !important;
}

.building-highlight-note-blue p,
.building-highlight-note-blue ol,
.building-highlight-note-blue ul,
.building-highlight-note-white p,
.building-highlight-note-white ol,
.building-highlight-note-white ul,
.building-highlight-note-gray p,
.building-highlight-note-gray ol,
.building-highlight-note-gray ul {
    margin-bottom: em(20, 15);
}
