.popular-links {
    @include sidebar-l(null, rem(20), left, 80%);

    margin-top: rem(30);

    &__heading {
        padding-right: rem(30);
        margin-top: 0;
        margin-bottom: 0;
    }

    &__container {
        @include cluster-l(rem(10));
    }

    @media screen and (min-width: $mobile_min) {
        &__heading {
            margin-top: rem(8);
        }
    }

    @media screen and (min-width: $tablet_min) {
        padding-right: rem(100);
    }

    @media screen and (min-width: $desktop_min) {
        padding-right: rem(200);
        margin-top: rem(50);

        // Override cluster margin for desktop
        &__item {
            margin: rem(10);
        }
    }

    @media print {
        > * {
            display: block;
        }
    }
}
