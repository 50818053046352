.nav-landing-list {
    margin: -#{rem(15)} 0 rem(4);

    li {
        @extend .gustan-bold;

        line-height: lh(21);
        color: $mbiebuilding_gray_5;

        a {
            position: relative;
            display: block;
            padding: rem(10) rem(20) rem(10) rem(39);
            color: $mbiebuilding_gray_5;

            &:hover,
            &:active,
            &:focus {
                color: $mbiebuilding_blue_1;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: rem(20);
            font-size: rem(22);
            color: $mbiebuilding_blue_1;
            transform: translateY(-50%);
        }

        .icon-external {
            top: rem(29);
            left: rem(18);
            font-size: rem(11);
        }
    }

    @media only screen and (min-width: $tablet_min) {
        margin-top: rem(23);
    }

    .homepage & {
        margin: rem(14) 0 0;

        li {
            a {
                padding: rem(10) rem(18);
            }

            .icon {
                left: rem(2);
            }
        }

        @media only screen and (min-width: $desktop_min) {
            margin: 0 0 0 rem(13);

            ul {
                display: flex;
                flex-wrap: wrap;
            }

            li {
                flex: 1 1 50%;

                &:nth-child(odd) {
                    a {
                        margin-right: rem(28);
                    }
                }
            }
        }
    }

    @media print {
        li a {
            padding-left: 0;
            color: $mbiebuilding_blue_1;
        }

        .homepage & {
            padding-left: 0;
        }
    }
}

