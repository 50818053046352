/**
 * Create a intrinsically responsive horizontal layout using flexbox.
 * Used for laying out items horizontally that can also wrap.
 *
 * @param {unit} $space - unit of measurement for the gap between items (can be zero for no gap)
 * @param {string} $justify - justification of the content (horizontal positioning)
 * @param {string} $align - alignment of the content (vertical positioning)
 */
@mixin cluster-l($space: rem(20), $justify: 'flex-start', $align: 'center') {
    > * {
        @if ($space != 0) {
            margin: calc(#{$space} / 2 * -1);
        }

        display: flex;
        align-items: #{$align};
        flex-wrap: wrap;
        justify-content: #{$justify};
    }

    /* stylelint-disable-next-line selector-max-universal */
    > * > * {
        @if ($space != 0) {
            margin: calc(#{$space} / 2);
        }
    }
}
