.hero {
    $self: &;

    margin-top: -#{rem(10)};

    &__inner {
        padding-right: rem(20);
        padding-left: rem(20);
    }

    &__image {
        display: block;
        width: calc(100% + #{rem(40)});
        height: auto;
        object-fit: cover;
        object-position: center;
        max-height: rem(250);
        margin-right: -#{rem(20)};
        margin-bottom: rem(25);
        margin-left: -#{rem(20)};
    }

    @media screen and (min-width: $mobile_min) {
        &__inner {
            padding-right: rem(30);
            padding-left: rem(30);
        }

        &__image {
            width: calc(100% + #{rem(60)});
            max-height: rem(467);
            margin-right: -#{rem(30)};
            margin-left: -#{rem(30)};
        }
    }

    @media screen and (min-width: $tablet_min) {
        &__image {
            margin-bottom: rem(40);
        }
    }

    @media screen and (min-width: $desktop_min) {
        margin-top: -#{rem(8)};

        &__image {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }

    &--full {
        @media screen and (min-width: $desktop_min) {
            #{$self}__image {
                height: auto;
                max-width: 100%;
                max-height: rem(550);
            }

            #{$self}__inner {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    @media print {
        margin-top: 0;

        &__inner {
            padding-left: 0;
        }

        &__image {
            width: 100%;
            margin: 0;
        }
    }
}
