.news-item {
    $self: &;

    display: flex;
    flex-direction: column;
    margin-top: rem(20);
    background-color: $mbiebuilding_white_1;

    &:first-child {
        margin-top: 0;
    }

    &__content {
        padding: rem(25) rem(30) rem(11);
    }

    &__link {
        display: block;

        &:hover,
        &:focus {
            outline: none;
            text-decoration: none;

            #{$self}__title,
            #{$self}__description,
            #{$self}__date {
                color: $mbiebuilding_blue_1;
            }

            #{$self}__title {
                border-color: $mbiebuilding_blue_1;
            }
        }
    }

    &__image-wrap {
        order: -1;
    }

    &__image {
        width: 100%;
        object-fit: cover;
    }

    &__title-wrap {
        margin-bottom: rem(25);
    }

    &__title {
        display: inline;
        border-bottom: rem(1) solid transparent;
        margin: 0;
        font-size: rem(23);
        color: $mbiebuilding_blue_2;
        transition: border-color .1s ease-in-out, color .1s ease-in-out;
    }

    &__description {
        @extend .fira-sans-light;

        font-size: rem(14);
        color: $mbiebuilding_gray_5;
        transition: color .1s ease-in-out;
    }

    &__date {
        @extend .gustan-black;

        display: block;
        margin-top: rem(22);
        margin-bottom: 0;
        font-size: rem(12);
        color: $mbiebuilding_gray_7;
        text-transform: uppercase;
        transition: color .1s ease-in-out;
    }

    .tag-list {
        padding: rem(11) rem(30) rem(30);
    }

    @media print, screen and (min-width: $tablet_min) {
        flex-direction: row;
        flex-wrap: nowrap;
        padding: rem(25) rem(30) rem(30);
        margin-top: rem(32);

        &:first-child {
            margin-top: 0;
        }

        &__inner {
            max-width: rem(550);
        }

        &__content {
            padding-top: 0;
            padding-right: 0;
            padding-left: 0;
        }

        &__title {
            font-size: rem(25);
            line-height: lh(32, 25);
        }

        &__description {
            padding-right: rem(40);
        }

        p#{$self}__description {
            font-size: rem(16);
        }

        &__image-wrap {
            flex-basis: rem(360);
            flex-shrink: 0;
            order: inherit;
        }

        .tag-list {
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
        }
    }

    &--has-image {
        @media print, screen and (min-width: $tablet_min) {
            #{$self}__inner {
                flex-grow: 1;
                max-width: initial;
                padding-right: rem(80);
            }

            #{$self}__description {
                padding-right: 0;
            }
        }
    }

    @media print {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
        border-bottom: rem(1) solid black;

        &:last-child {
            border-bottom: 0;
        }

        &__inner {
            max-width: initial;
            page-break-inside: avoid;
        }

        &__title {
            color: $mbiebuilding_blue_1;
        }

        .tag {
            background-color: transparent;
            color: $mbiebuilding_blue_1;
        }

        .tag-list {
            padding-top: rem(4);
        }
    }
}
